import {gql} from '@apollo/client'

export const typeDefs = gql`
  directive @client on FIELD

  type Breadcrumb {
    """
    Breadcrumb href
    """
    href: String!

    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Creadcrumb href
    """
    title: String!
  }

  type EcoCert {
    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Cert name
    """
    name: String!

    """
    Cert slug
    """
    slug: String!

    """
    Cert thumbnail
    """
    thumbnail: Image
  }

  type Image {
    """
    Unique identifier for the resource
    """
    id: ID!
    type: String!

    """
    Image URL
    """
    url: String!
  }

  """
  Media
  """
  union Media = Image | Video

  type MiscTag {
    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Misc tag name
    """
    name: String!

    """
    Misc tag slug
    """
    slug: String!

    """
    Misc tag thumbnail
    """
    thumbnail: Image
  }

  """
  Extra discount
  """
  type ProductExtraDiscount {
    discount: Float
    discountFormatted: String
    gross: Float!
    grossFormatted: String!
    grossUnitPrice: Float!
    grossUnitPriceFormatted: String!

    """
    Unique identifier for the resource
    """
    id: ID!
    minQuantity: Int!
    net: Float!
    netFormatted: String!
    netUnitPrice: Float!
    netUnitPriceFormatted: String!
  }

  """
  Price of product
  """
  type ProductPricing {
    discount: Float
    discountFormatted: String
    displayNamePlural: String!
    displayNameSingular: String!
    extraDiscounts: [ProductExtraDiscount]!
    gross: Float!
    grossFormatted: String!
    grossOriginalPrice: Float
    grossOriginalPriceFormatted: String
    grossUnitPrice: Float!
    grossUnitPriceFormatted: String!

    """
    Unique identifier for the resource
    """
    id: ID!
    minQuantity: Int!
    net: Float!
    netFormatted: String!
    netOriginalPrice: Float
    netOriginalPriceFormatted: String
    netUnitPrice: Float!
    netUnitPriceFormatted: String!
    originalDiscount: Float
    quantityPerUnit: Int!

    """
    Unittype
    """
    unitType: String!
  }

  type Query {
    productBySlug(
      """
      Unique readable identifier for the resource
      """
      slug: String!
    ): RVProduct
  }

  type RVLabel {
    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Slug
    """
    slug: String!
    name: String!
    thumbnail: Image
  }

  """
  Rekstrarvorur Product
  """
  type RVProduct implements RVProductNode {
    relatedProducts: [RelatedProduct!]!
    hidePrice: Boolean
    breadcrumbs: [Breadcrumb!]!
    labels: [RVLabel!]!

    """
    Category Name
    """
    categoryName: String
    defaultUnitType: UnitType

    """
    Short product description
    """
    description: String!

    """
    Full product description
    """
    fullDescription: String!
    ecoCerts: [EcoCert!]!

    """
    Unique identifier for the resource
    """
    id: ID!
    infoDocs: [SecurityDoc!]!

    """
    Whether the product is in stock and visible or not
    """
    isAvailable: Boolean!

    """
    Products media
    """
    media: [Media!]!
    miscTags: [MiscTag!]!

    """
    Product name
    """
    name: String!
    offerString: String

    """
    Product Price
    """
    pricing: [ProductPricing!]
    securityDocs: [SecurityDoc!]!

    """
    Stock Price Unit
    """
    sku: String

    """
    Unique readable identifier for the resource
    """
    slug: String!
    thumbnail: Image
    unitTypes: [UnitType!]!

    """
    Variant Id
    """
    variantId: String
  }

  interface RVProductNode {
    breadcrumbs: [Breadcrumb!]!

    """
    Category Name
    """
    categoryName: String
    defaultUnitType: UnitType

    """
    Product description
    """
    description: String!
    ecoCerts: [EcoCert!]!

    """
    Unique identifier for the resource
    """
    id: ID!
    infoDocs: [SecurityDoc!]!

    """
    Whether the product is in stock and visible or not
    """
    isAvailable: Boolean!

    """
    Products media
    """
    media: [Media!]!
    miscTags: [MiscTag!]!

    """
    Product name
    """
    name: String!
    offerString: String

    """
    Product Price
    """
    pricing: [ProductPricing!]
    securityDocs: [SecurityDoc!]!

    """
    Stock Price Unit
    """
    sku: String

    """
    Unique readable identifier for the resource
    """
    slug: String!
    thumbnail: Image
    unitTypes: [UnitType!]!

    """
    Variant Id
    """
    variantId: String
  }

  type RelatedProduct {
    """
    Unique identifier for the resource
    """
    id: ID!
    productId: String!
  }

  type SecurityDoc {
    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Security doc name
    """
    name: String!

    """
    Security doc slug
    """
    slug: String!

    """
    Security doc thumbnail
    """
    thumbnail: Image

    """
    Security doc url
    """
    url: String!
  }

  type UnitType {
    """
    Display Name Plural
    """
    displayNamePlural: String!

    """
    Unit Display Name singular
    """
    displayNameSingular: String!

    """
    Variant id
    """
    id: ID!

    """
    Minimum quantity
    """
    minQuantity: Float!

    """
    Unit type name
    """
    name: String!

    """
    Quantity Per Unit
    """
    quantityPerUnit: Float!
  }

  type Video {
    """
    Youtube Embed url
    """
    embedURL: String!

    """
    Unique identifier for the resource
    """
    id: ID!

    """
    Video thumbnail
    """
    thumbnail: Image!
    type: String!

    """
    Youtube Video ID
    """
    youtubeID: String!
  }
`
