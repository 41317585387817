import {ProductContext} from '../utils'
import capitalize from 'lodash/capitalize'

export const resolveCategoryName = (context: ProductContext): string | null => {
  const categoryName = context.product.category?.name
  if (categoryName) {
    return capitalize(categoryName)
  }
  return null
}
