import {motion} from 'framer-motion'
import React from 'react'
import type {RemixiconReactIconProps} from 'remixicon-react'
import Loader2LineIcon from 'remixicon-react/Loader2LineIcon'
import styled from 'styled-components'

const LoaderWrapper = styled(motion.div)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  display: flex;

  svg {
    animation: spin 2s linear infinite;
  }
`
export default function Loader(
  props: RemixiconReactIconProps & {loaderKey?: string},
): JSX.Element {
  return (
    <LoaderWrapper
      key={props?.loaderKey ?? 'loader'}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <Loader2LineIcon {...props} />
    </LoaderWrapper>
  )
}
