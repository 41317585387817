import type {ApolloClient} from '@apollo/client'
import type {DataLayerArgs} from 'react-gtm-module'
import type {
  CollectionQuery,
  CollectionQueryVariables,
} from 'src/api/saleor/generated'
import {CollectionDocument} from 'src/api/saleor/generated'

interface Props {
  id?: string
  event?: string
  category?: string
  client: ApolloClient<unknown>
  handle: (args: DataLayerArgs) => void
}

const EVENT = 'product-collection'
const CATEGORY = 'Product collection'

export const handleProductCollectionTag = async ({
  id,
  client,
  handle,
  event = EVENT,
  category = CATEGORY,
}: Props): Promise<void> => {
  if (!id) {
    return
  }
  const {data} = await client.query<CollectionQuery, CollectionQueryVariables>({
    query: CollectionDocument,
    variables: {
      id,
      first: 24,
      after: null,
    },
  })
  if (!data || !data.collection) {
    return
  }
  const {
    collection: {name: collectionName},
  } = data
  try {
    handle({
      dataLayer: {
        event,
        category,
        collectionName,
      },
    })
  } catch (error) {
    // Empty on purpose
  }
}
