import {ProductTypePiece} from 'src/components/products/ProductCartPicker/interfaces'
import {UnitType} from '..'
import {ProductContext} from '../utils'

export const resolveDefaultUnitType = ({
  product,
  variants: unitTypes,
  defaultVariantType: defaultUnitType,
}: ProductContext): UnitType | null => {
  const item = unitTypes[defaultUnitType]
  if (!item) {
    return null
  }

  return {
    id: `${product.slug}-unitType-${item.id ?? ProductTypePiece}`,
    name: item.id ?? ProductTypePiece,
    displayNameSingular: item.name_singular,
    displayNamePlural: item.name_plural ?? '',
    minQuantity: parseFloat(item.min_qty),
    quantityPerUnit: parseFloat(
      ((item as unknown) as {qty_per_unit: string}).qty_per_unit ?? '1',
    ),
  }
}
