import {useCallback, useEffect, useMemo} from 'react'
import type {GraphQLError} from 'graphql'
import {toPairs} from 'lodash'
import {useSetRecoilState} from 'recoil'
import {v4 as uuidv4} from 'uuid'
import {toast} from 'react-toastify'

import type {
  CheckoutFragment,
  CheckoutQuery,
  Exact,
  MyCheckoutQuery,
} from 'src/api/saleor/generated'
import {
  useCheckoutCreateMutation,
  useCheckoutLinesUpdateMutation,
} from 'src/api/saleor/generated'
import {getCartQuantityMap} from 'src/utils/checkout'
import {checkoutErrorCodeMap} from 'src/constants'
import type {ApolloQueryResult} from '@apollo/client'
import type {Maybe} from 'src/utils/Maybe'
import {useAnonymousToken} from '.'
import {useUser} from '../UserContext'
import {Store} from './atom'
import {useSetCheckoutStateValue} from './useCheckoutState'
import {CheckoutEventError} from './checkout-event'

interface UpdateStore {
  update: (stateUpdate: Record<string, number>) => Promise<void>
}

const typeIsError = (
  value: Maybe<CheckoutFragment | Readonly<GraphQLError[]>>,
): value is Readonly<GraphQLError[]> => value != null && Array.isArray(value)

let id: string | null = null

export const useUpdateStore = ({
  refetch,
  refetchMyCheckout,
  refetchCheckout,
}: {
  refetch: () => void
  refetchMyCheckout: (
    variables?: Partial<
      Exact<{
        [key: string]: never
      }>
    >,
  ) => Promise<ApolloQueryResult<MyCheckoutQuery>>
  refetchCheckout: (
    variables?: Partial<
      Exact<{
        token: Maybe<string>
      }>
    >,
  ) => Promise<ApolloQueryResult<CheckoutQuery>>
}): UpdateStore => {
  const {user} = useUser()
  const {token: anonymousToken, set: setAnonymousToken} = useAnonymousToken()
  const [checkoutLinesUpdate] = useCheckoutLinesUpdateMutation({})
  const setState = useSetCheckoutStateValue()
  const setLoading = useSetRecoilState(Store.loadingCartState)

  const [checkoutCreate] = useCheckoutCreateMutation({
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err)
    },
  })

  useEffect(() => {
    const fn = (): void => {
      id = null
    }
    document.body.addEventListener('CheckoutChange', fn)
    return () => {
      document.body.removeEventListener('CheckoutChange', fn)
    }
  }, [])

  const handleCreateCheckout = useCallback(
    async (
      stateUpdate: Record<string, number>,
    ): Promise<Maybe<CheckoutFragment>> => {
      let nextCheckout: Maybe<CheckoutFragment>

      if (user) {
        // Fetching existing or 'authenticated' checkout
        const refetchRes = await refetchMyCheckout()
        if (refetchRes) {
          nextCheckout = refetchRes.data.me?.checkout ?? undefined
        }
        // Creating new 'authenticated' checkout
        if (!nextCheckout) {
          nextCheckout = (
            await checkoutCreate({
              variables: {
                email: user.email,
                lines: toPairs(stateUpdate).map(([variantId, quantity]) => ({
                  quantity,
                  variantId,
                })),
              },
            })
          )?.data?.checkoutCreate?.checkout

          if (typeIsError(nextCheckout)) {
            throw new Error('Failed creating checkout')
          }
        }
      } else if (anonymousToken) {
        // Fetching existing 'anonymous' checkout
        nextCheckout = (await refetchCheckout())?.data?.checkout
      } else {
        // Creating new 'anonymous' checkout
        nextCheckout = (
          await checkoutCreate({
            variables: {
              email: `${uuidv4()}@anonymous.saleor`,
              lines: toPairs(stateUpdate).map(([variantId, quantity]) => ({
                quantity,
                variantId,
              })),
            },
          })
        )?.data?.checkoutCreate?.checkout
        setAnonymousToken(nextCheckout?.token)
        // refetchCheckout({token: nextCheckout?.token})
        if (typeIsError(nextCheckout)) {
          throw new Error('Failed creating store')
        }
      }
      return nextCheckout
    },
    [
      anonymousToken,
      checkoutCreate,
      refetchCheckout,
      refetchMyCheckout,
      setAnonymousToken,
      user,
    ],
  )

  const handleUpdateCheckout = useCallback(
    async ({
      toUpdate,
      checkout,
    }: {
      toUpdate: [string, number][]
      checkout: CheckoutFragment
    }) => {
      const currentId = uuidv4()
      id = currentId
      const checkoutUpdate = toUpdate.length
        ? (
            await checkoutLinesUpdate({
              variables: {
                token: checkout?.token,
                lines: toUpdate.map(([variantId, quantity]) => ({
                  quantity,
                  variantId,
                })),
              },
            })
          )?.data?.checkoutLinesUpdate
        : {errors: []}
      const error = checkoutUpdate?.errors?.[0]
      if (error) {
        let customErrorMsg: string | undefined
        if (error?.code === 'QUANTITY_GREATER_THAN_LIMIT') {
          const quantity = error?.message?.match(/\d+/)
          customErrorMsg = `Einungis er hægt að setja ${quantity} stk. í körfu`
        }
        toast.error(
          customErrorMsg || checkoutErrorCodeMap[error?.code] || 'Óþekkt villa',
        )
        if (CheckoutEventError) {
          document.body.dispatchEvent(CheckoutEventError)
        }

        setState('ERROR')
        return
      }
      if (id === currentId) {
        refetch()
        setLoading({})
      }
    },
    [checkoutLinesUpdate, refetch, setLoading, setState],
  )

  const getStore = useCallback(
    async (stateUpdate: Record<string, number>) => {
      setState('LOADING')
      const checkout = await handleCreateCheckout(stateUpdate)
      if (!checkout) {
        return
      }
      const currentState = getCartQuantityMap(checkout)
      const stateUpdatePairs = toPairs(stateUpdate)
      const toUpdate = stateUpdatePairs.filter(
        ([vId, qty]) => currentState[vId] !== qty,
      )
      setLoading(
        toUpdate.reduce(
          (a, b) => ({
            ...a,
            [b[0]]: b[1],
          }),
          {},
        ),
      )
      await handleUpdateCheckout({toUpdate, checkout})
      setState('READY')
    },
    [setState, handleCreateCheckout, setLoading, handleUpdateCheckout],
  )

  const handleUpdate = useCallback(
    async (stateUpdate: Record<string, number>) => {
      await getStore(stateUpdate)
    },
    [getStore],
  )

  return useMemo(
    () => ({
      update: handleUpdate,
    }),
    [handleUpdate],
  )
}
