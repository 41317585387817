import type {RvTheme} from 'src/themes/theme'
import type {FlattenInterpolation, ThemeProps} from 'styled-components'
import {css} from 'styled-components'
import {palette} from 'styled-tools'

interface Props {
  color: string
  outlined?: boolean
  length: number
  showOnHover?: boolean
  borderRadius?: number
}

interface HoverProps {
  length: number
}

export function hoverShadowStyles({
  length,
}: HoverProps): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    &:hover {
      transform: translate(${length}px, -${length}px);
      &::before {
        transform: translate(-${length}px, ${length}px);
      }
      &:disabled {
        transform: none;
        &:before {
          transform: none;
        }
      }
    }
  `
}

export function clickAnimation(): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    &:active&:not(:disabled) {
      font-size: 1rem;
      transform: translateY(-6px) translateX(6px);

      &::before {
        transform: translate3d(-6px, 6px, -2px);
      }
    }
  `
}

export default function shadowStyles({
  color,
  outlined,
  length,
  showOnHover = false,
  borderRadius,
}: Props): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    position: relative;
    transition: transform 0.5s;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: transform 0.5s;
      transform: ${!showOnHover && `translate3d(-${length}px, ${length}px, 0)`};
      z-index: -1;
      background-color: ${outlined ? palette('white') : palette(color)};
      border: 1px solid ${palette(color)};
      border-radius: ${borderRadius && `${borderRadius}px`};
    }

    ${showOnHover && hoverShadowStyles({length})};

    ${showOnHover && clickAnimation()};
  `
}
