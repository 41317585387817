import {ProductTypePiece} from 'src/components/products/ProductCartPicker/interfaces'
import {ProductContext} from '../utils'

const Lang: Record<string, string> = {
  STK: 'stykki',
  KS: 'kassa',
  BR: 'brúsa',
  LASTITEM: ' eða ',
  OTHERITEMS: ', ',
}

export const resolveOfferString = ({
  product,
  variants: unitTypes,
}: ProductContext): string | null => {
  const values = Object.keys(unitTypes)
    .map((key) => unitTypes[key])
    .map((item) => ({
      id: `${product.slug}-unitType-${item.id ?? ProductTypePiece}`,
      name: item.id,
      displayNameSingular: item.name_singular,
      displayNamePlural: item.name_plural ?? '',
      minQuantity: parseFloat(item.min_qty),
      quantityPerUnit: parseFloat(
        ((item as unknown) as {qty_per_unit: string}).qty_per_unit,
      ),
    }))

  if (
    values
      .map(({name}) => name)
      .find((name) => name && !Object.keys(Lang).includes(name))
  ) {
    // Unknow type
    return null
  }
  if (values.length < 2) {
    // Too few types.
    return null
  }
  return values
    .map((item, index, arr) => {
      const withOr = index === arr.length - 2
      const lastIndex = index === arr.length - 1
      const word = Lang[item.name ?? 'STK']

      if (withOr) {
        return `${word}${Lang.LASTITEM}`
      }
      if (lastIndex) {
        return word
      }
      return `${word}${Lang.OTHERITEMS}`
    })
    .join('')
}
