import React from 'react'
import styled from 'styled-components'
import {ifProp} from 'styled-tools'
import {
  buttonStyles,
  buttonTextStyles,
  outlinedButtonStyles,
} from './buttonStyles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: React.ReactElement
  loading?: boolean
  withGutter?: boolean
  fill?: boolean | number
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
}

interface TextProps {
  withIcon?: boolean
}

export const Anchor = styled.a<Props>`
  margin-bottom: ${ifProp({withGutter: true}, '24px', '0px')};
  ${(p) =>
    p.outlined
      ? outlinedButtonStyles({color: p.color})
      : buttonStyles({color: p.color})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

const AnchorButton = React.forwardRef<HTMLAnchorElement, Props>(
  ({children, icon, color = 'secondary', ...props}: Props, ref) => (
    <Anchor {...props} color={color} ref={ref}>
      <BtnText withIcon={!!icon}>{children}</BtnText>
      {!!icon && icon}
    </Anchor>
  ),
)

export default AnchorButton
