import React from 'react'
import styled from 'styled-components'
import {ifProp} from 'styled-tools'
import Loader from '../common/Loader'
import shadowStyles from '../../styles/shadowStyles'
import {
  buttonStyles,
  buttonTextStyles,
  outlinedButtonStyles,
} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactElement
  loading?: boolean
  withGutter?: boolean
  fill?: boolean | number
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
}

interface TextProps {
  withIcon?: boolean
}

interface BtnWrapperProps {
  withGutter: boolean
}

interface BtnContainerProps {
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
}

const Btn = styled.button<Props>`
  ${(p) =>
    p.outlined
      ? outlinedButtonStyles({color: p.color})
      : buttonStyles({color: p.color})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`
const BtnContainer = styled.div<BtnContainerProps>`
  height: fit-content;
  width: fit-content;
  ${(p) =>
    shadowStyles({
      color: p.color ?? 'inherit',
      outlined: !p.outlined,
      length: 8,
      showOnHover: true,
    })}
`
export const BtnWrapper = styled.div<BtnWrapperProps>`
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0px')};
`

export default function Button({
  children,
  icon,
  loading = false,
  disabled,
  withGutter = false,
  fill = false,
  color = 'secondary',
  outlined = false,
  ...props
}: Props): JSX.Element {
  return (
    <BtnWrapper withGutter={withGutter}>
      <BtnContainer color={color} outlined={outlined}>
        <Btn
          disabled={disabled}
          fill={+fill}
          color={color}
          outlined={outlined}
          {...props}
        >
          {loading ? <Loader /> : !!icon && icon}
          <BtnText withIcon={!!icon}>
            {loading ? 'Augnablik ...' : children}
          </BtnText>
        </Btn>
      </BtnContainer>
    </BtnWrapper>
  )
}
