import {toast} from 'react-toastify'
import type {CheckoutError} from 'src/api/saleor/generated'
import {checkoutErrorCodeMap} from 'src/constants'
import type {Maybe} from 'src/utils/Maybe'

export function handleCheckoutError(err: Maybe<CheckoutError[]>): void {
  const error = err?.[0]
  if (error) {
    toast.error(checkoutErrorCodeMap[error?.code] || 'Óþekkt villa')
  }
}
