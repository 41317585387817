/* eslint-disable no-console */
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react'
import {useRouter} from 'next/router'
import {useLocalStorage} from 'react-use'
import useSWR from 'swr'

import type {
  Address,
  AddressInput,
  UserAddressesQuery,
} from 'src/api/saleor/generated'
import {
  useTokenCreateMutation,
  useTokenDeactivateAllMutation,
  useAccountAddressCreateMutation,
  useUserAddressesQuery,
  useAccountAddressUpdateMutation,
  useAccountAddressDeleteMutation,
} from 'src/api/saleor/generated'

import type {
  User,
  Company,
  PaginatedCompanyList,
  VirtualCard,
  EmailChangeRequest,
} from 'src/api/rest'
import {restApi, handleRestResponse} from 'src/api/rest'

import {mapCompanyAddresses} from 'src/utils/checkout'
import {paths} from 'src/utils/paths'
import {atom, useRecoilValue, useSetRecoilState} from 'recoil'
import {makeVar} from '@apollo/client'
import {emailLoginErrorCodeStrings} from 'src/constants'
import type {Maybe} from 'src/utils/Maybe'
import {getSessionStorageItem} from 'src/utils/getLocalStorageItem'
import {TOKEN_LOCAL_STORAGE} from 'src/const'
import {isNotNull} from 'src/utils/isNotNull'
import {trackUser} from 'src/utils/klaviyo'
import {useOverlay} from './OverlayContext'

interface UserProps {
  children: React.ReactNode
}

interface UserData {
  signOut: () => void
  signIn: (phoneNumber: string) => void
  signInWithEmail: (email: string, password: string) => void
  isVerifyingLogin: boolean
  userLoading: boolean
  loginSuccess: boolean
  user?: User | null
  changeUserEmail: (id: number, data: EmailChangeRequest) => Promise<void>
  missingEmail: boolean
  userCompanies: Company[]
  userCompaniesLoading: boolean
  companyId: string | null
  setCompanyId: (value: string | null) => void
  removeCompanyId: () => void
  currentCompany: Maybe<Company>
  currCustomerAddresses: Maybe<Address[]>
  showChooseCustomer: boolean
  valitorCards: VirtualCard[]
  removeValitorCard: (id: string) => Promise<void>
  createAddress: (
    address: AddressInput,
  ) => Promise<void | Address | null | undefined>
  updateAddress: (id: string, address: AddressInput) => Promise<void>
  deleteAddress: (id: string) => Promise<void>
  addressData?: UserAddressesQuery
  addressLoading: boolean
  addressesLoading?: boolean
  errorMsg: Maybe<string>
  setErrorMsg: React.Dispatch<React.SetStateAction<Maybe<string>>>
  showLogin: boolean
  showRegistrationMsg: boolean
  setShowRegistrationMsg: React.Dispatch<React.SetStateAction<boolean>>
  resetUser: boolean
  stopResetUser: () => void
  selectAccount: boolean
  resetSelectAccount: () => void
}

export const UserContext = createContext<UserData | null>(null)

/** *
 * For caching local policy in Apollo
 */
export const userIDGQLVar = makeVar<number | null>(null)
export const companyGQLVar = makeVar<string | null>(null)

export const userIDStore = atom<number | null>({
  key: 'user-id',
  default: null,
})

export const currentCompanyStore = atom<string | null>({
  key: 'user-company',
  default: null,
})

export const Store = {
  userID: userIDStore,
  currentCompany: currentCompanyStore,
}
export const useUserID = (): number | null | string =>
  useRecoilValue(userIDStore)

const cardFetcher = (): Promise<VirtualCard[]> =>
  handleRestResponse(restApi.paymentsValitorPayCardsList())

const userCompaniesFetcher = (): Promise<PaginatedCompanyList> =>
  handleRestResponse(restApi.companiesList())

export function UserProvider({children}: UserProps): JSX.Element {
  const router = useRouter()
  const [token, setToken] = useLocalStorage<string | null>(TOKEN_LOCAL_STORAGE)

  const {setSideDrawerIsOpen} = useOverlay()

  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [resetUser, setResetUser] = useState<boolean>(false)
  const [_companyId, _setCompanyId, _removeCompanyId] = useLocalStorage<string>(
    'companyId',
  )
  // Do we force pick company?
  const [shouldSelectCompany, setShouldSelectCompany] = useState<boolean>()

  const setCompanyId = useCallback(
    (value: string | null) => {
      if (value == null) {
        // To force rerender if user is not selected
        setShouldSelectCompany(false)
        _removeCompanyId()
        return
      }
      _setCompanyId(value)
      setShouldSelectCompany(true)
    },
    [_removeCompanyId, _setCompanyId],
  )

  const removeCompanyId = useCallback(() => {
    setShouldSelectCompany(false)
    _removeCompanyId()
  }, [_removeCompanyId])
  
  const [showRegistrationMsg, setShowRegistrationMsg] = useState<boolean>(false)
  const [addressLoading, setAddressLoading] = useState<boolean>(false)
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [isVerifyingLogin, setIsVerifyingLogin] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [errorMsg, setErrorMsg] = useState<Maybe<string>>(null)
  const [expirationDate, setExpirationDate] = useState<Date>()
  const [selectAccount, setSelectAccount] = useState<boolean>(false)

  useEffect(() => {
    const expiresIn = 60 * 60 * 2 // 2hours
    const currentDate = new Date()
    currentDate.setSeconds(currentDate.getSeconds() + expiresIn)
    setExpirationDate(currentDate)
  }, [user, token])

  const setUserIDValue = useSetRecoilState(Store.userID)
  const setCompanyValue = useSetRecoilState(Store.currentCompany)

  const [createToken] = useTokenCreateMutation()

  const [deactivateTokens] = useTokenDeactivateAllMutation({
    onCompleted: () => {
      restApi.setSecurityData(null)
      setLoginSuccess(false)
      setUser(null)
      removeCompanyId()
      setResetUser(false)
    },
    onError: (err) => {
      console.error(err)
    },
  })

  const {
    data: companyData,
    isValidating: userCompaniesValidating,
    error: userCompaniesError,
  } = useSWR(user ? ['userCompanies', user] : null, userCompaniesFetcher, {
    revalidateOnFocus: false,
    onError: () => {
      removeCompanyId()
    },
    errorRetryCount: 3,
  })

  const userCompanies = companyData as Company[]
  const userCompaniesLoading = userCompaniesValidating && !userCompaniesError
  const {
    data: addressData,
    loading: addressesLoading,
    refetch: refetchAddresses,
  } = useUserAddressesQuery({
    skip: !user,
  })

  const [addressCreate] = useAccountAddressCreateMutation()
  const [addressUpdate] = useAccountAddressUpdateMutation()
  const [addressDelete] = useAccountAddressDeleteMutation()

  const {data: valitorCards, mutate: revalidateValitorCards} = useSWR(
    user ? 'valitorCards' : null,
    cardFetcher,
    {
      revalidateOnFocus: false,
    },
  )
  const firstCompany = (userCompanies || []).find(Boolean)

  const companyId =
    _companyId ||
    (shouldSelectCompany && firstCompany != null
      ? firstCompany.id.toString()
      : null)

  const signOut = useCallback(() => {
    deactivateTokens()
    setSelectAccount(false)
    localStorage.removeItem(TOKEN_LOCAL_STORAGE)
    localStorage.removeItem('tokenExp')
    setToken(null)
    setResetUser(false)
    router.push(paths.home)
  }, [deactivateTokens, router, setToken])

  useEffect(() => {
    const expDate = localStorage.getItem('tokenExp')
    const currentDate = new Date()

    if (expDate && currentDate > new Date(expDate as string)) {
      // deactivateTokens()
      signOut()
    }
  }, [signOut])

  useEffect(() => {
    if (!companyId) {
      _removeCompanyId()
      return
    }
    _setCompanyId(companyId)
  }, [_removeCompanyId, _setCompanyId, companyId])

  useEffect(() => {
    userIDGQLVar(user?.id ?? null)
    companyGQLVar(companyId ?? null)
    setUserIDValue(user?.id ?? null)
    setCompanyValue(companyId ?? null)
  }, [companyId, setCompanyValue, setUserIDValue, user?.id, token])

  useEffect(() => {
    const checkTokenExpiration = () => {
      const intervalCheck = setInterval(() => {
        if (user && resetUser === false) {
          const expDate = localStorage.getItem('tokenExp')
          const currentDate = new Date()
          if (expDate) {
            const expCheckDate = new Date(expDate)
            const timeDifference: number =
              expCheckDate.getTime() - currentDate.getTime()
            const dif = Math.round(timeDifference / 1000 / 60)
            if (dif >= 1 && dif <= 2) {
              setSelectAccount(false)
              setSideDrawerIsOpen(true)
              setResetUser(true)
            }
          }
        }
      }, 60000) // Check every minute
      return intervalCheck
    }
    const intervalVerify = checkTokenExpiration()
    return () => {
      clearInterval(intervalVerify)
    }
  }, [user, setResetUser, setSideDrawerIsOpen, token, resetUser])

  const stopResetUser = useCallback(() => {
    setResetUser(false)
  }, [])

  const signIn = useCallback(
    (phoneNumber: string) => {
      setErrorMsg(null)
      if (companyId) {
        removeCompanyId()
      }
      setIsVerifyingLogin(true)

      restApi
        .usersLoginCreate({phoneNumber})
        .then(({data}) => {
          if (data !== null && expirationDate) {
            setToken(`${data.saleor.accessToken}`)
            localStorage.setItem('tokenExp', expirationDate.toISOString())
            setShouldSelectCompany(true)
            setLoginSuccess(true)
            setIsVerifyingLogin(false)
            setResetUser(false)
          }
        })
        .then(() => {
          setSelectAccount(true)
        })
        .catch((err) => {
          setIsVerifyingLogin(false)
          if (err?.error?.detail) {
            setErrorMsg(err.error.detail)
          } else {
            setErrorMsg('Óþekkt villa, reynið aftur síðar')
          }
        })
    },
    [companyId, removeCompanyId, setToken, expirationDate],
  )

  const signInWithEmail = useCallback(
    async (email: string, password: string) => {
      if (companyId) {
        removeCompanyId()
      }
      setIsVerifyingLogin(true)
      await createToken({
        variables: {
          email,
          password,
        },
      })
        .then(({data}) => {
          const errors = data?.tokenCreate?.errors
          if (errors?.length) {
            const errorString = emailLoginErrorCodeStrings[errors[0]?.code]
            setErrorMsg(errorString || 'Óþekkt villa, reynið aftur síðar')
            setIsVerifyingLogin(false)
          }
          if (data != null && !errors?.length && expirationDate) {
            setToken(`${data.tokenCreate?.token}`)
            localStorage.setItem('tokenExp', expirationDate.toISOString())
            setLoginSuccess(true)
            router.push(paths.home)
            setIsVerifyingLogin(false)
            setShouldSelectCompany(true)
            setResetUser(false)
          }
        })
        .then(() => {
          setSideDrawerIsOpen(true)
          setSelectAccount(true)
        })
        .catch((err) => {
          setIsVerifyingLogin(false)
          throw err
        })
    },
    [
      companyId,
      createToken,
      expirationDate,
      removeCompanyId,
      router,
      setSideDrawerIsOpen,
      setToken,
    ],
  )

  const fetchUser = useCallback(async () => {
    if (!token) {
      setUser(null)
      setUserLoading(false)
      setInitialLoad(false)
      localStorage.removeItem(TOKEN_LOCAL_STORAGE)
      localStorage.removeItem('tokenExp')
      return
    }

    setUserLoading(true)

    restApi
      .usersMeRetrieve()
      .then(({data}) => {
        setUser(data)
        if (data?.email) {
          trackUser(data)
        }
      })
      .finally(() => {
        setUserLoading(false)
        setInitialLoad(false)
      })
      .catch((res) => {
        if (res.status === 401) {
          localStorage.removeItem(TOKEN_LOCAL_STORAGE)
          localStorage.removeItem('tokenExp')
        }
      })
  }, [setUserLoading, setUser, token])

  const changeUserEmail = useCallback(
    (id: number, data: EmailChangeRequest) =>
      restApi.usersChangeEmailCreate(id, data).then((res) => {
        if (res?.data) {
          setToken(`${res.data.saleor?.accessToken}`)
          setUser(res.data.user)
        }
      }),
    [setToken],
  )

  const currentCompany = companyId
    ? userCompanies?.find((c) => `${c.id}` === companyId)
    : null

  const currCustomerAddresses =
    (currentCompany
      ? mapCompanyAddresses(currentCompany)?.filter(isNotNull)
      : addressData?.me?.addresses?.filter(isNotNull)) ?? []

  const missingEmail = !!user?.email?.endsWith('@notendur.rv.is')

  const showChooseCustomer = !!(
    user &&
    !!userCompanies?.length &&
    !missingEmail
  )

  useEffect(() => {
    restApi.setSecurityData(getSessionStorageItem(TOKEN_LOCAL_STORAGE))
    fetchUser()
  }, [fetchUser, token, addressCreate, addressUpdate])

  useEffect(() => {
    refetchAddresses()
    revalidateValitorCards()
  }, [user, refetchAddresses, revalidateValitorCards])

  const createAddress = useCallback(
    (address: AddressInput) =>
      addressCreate({
        variables: {
          address,
        },
      }).then((res) => {
        refetchAddresses()
        return res.data?.accountAddressCreate?.address
      }),
    // TODO: handle error
    [addressCreate, refetchAddresses],
  )

  const updateAddress = useCallback(
    async (id: string, address: AddressInput) => {
      setAddressLoading(true)
      await addressUpdate({
        variables: {
          id,
          address,
        },
      }).then(() => {
        setAddressLoading(false)
        refetchAddresses()
      })
      // TODO: handle error
    },
    [addressUpdate, refetchAddresses],
  )

  const deleteAddress = useCallback(
    async (id: string) => {
      await addressDelete({
        variables: {
          id,
        },
      }).then(() => {
        refetchAddresses()
      })
      // TODO: handle error
    },
    [addressDelete, refetchAddresses],
  )

  const removeValitorCard = useCallback(
    (id: string) =>
      restApi.paymentsValitorPayCardsDestroy(id).then(() => {
        revalidateValitorCards()
      }),
    // TODO: Handle error
    [revalidateValitorCards],
  )
  const resetSelectAccount = useCallback(() => {
    setSelectAccount(false)
  }, [])

  return (
    <UserContext.Provider
      value={{
        showLogin: !initialLoad && !userCompaniesLoading && !userLoading,
        signIn,
        signInWithEmail,
        signOut,
        isVerifyingLogin,
        userLoading,
        loginSuccess,
        user,
        changeUserEmail,
        missingEmail,
        userCompanies,
        userCompaniesLoading,
        companyId,
        setCompanyId,
        removeCompanyId,
        currentCompany,
        currCustomerAddresses,
        showChooseCustomer,
        valitorCards: valitorCards ?? [],
        removeValitorCard,
        createAddress,
        updateAddress,
        deleteAddress,
        addressData,
        addressLoading,
        addressesLoading,
        errorMsg,
        setErrorMsg,
        showRegistrationMsg,
        setShowRegistrationMsg,
        resetUser,
        stopResetUser,
        selectAccount,
        resetSelectAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser(): UserData {
  const data = useContext(UserContext)
  if (data == null) {
    throw new Error('User is not in context.')
  }
  return data
}
