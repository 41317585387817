import type {Maybe} from 'src/utils/Maybe'
import {media} from 'styled-bootstrap-grid'
import type {FlattenInterpolation, ThemeProps} from 'styled-components'
import {css} from 'styled-components'
import {ifProp, palette} from 'styled-tools'
import {fontSize} from '../../utils/styles'

interface Props {
  color: Maybe<string>
}

const commonButtonStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 45px;
  width: ${ifProp('fill', '100%', 'inherit')};
  min-width: 135px;
  border-radius: 0;
  padding: 10px 24px;
  box-sizing: border-box;
  transition: color 0.5s, border-color 0.5s, background-color 0.5s;
  cursor: pointer;
  font-size: 1.125rem;

  svg {
    margin-right: 16px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid ${palette('sky')};
  }

  &:disabled {
    cursor: not-allowed;
    svg {
      color: ${palette('black40')};
    }
  }

  ${media.sm`
    min-width: 220px;
  `}
`

export function buttonStyles<T>({
  color,
}: Props): FlattenInterpolation<ThemeProps<T>> {
  return css`
    ${commonButtonStyles}
    border: 1px solid ${color ? palette(color) : 'inherit'};
    background-color: ${color ? palette(color) : 'inherit'};
    color: ${palette('white')};

    &:active&:not(:disabled) {
      background-color: ${palette('black70')};
    }

    &:disabled {
      background-color: ${palette('black20')};
      border: 1px solid ${palette('black40')};
      color: ${palette('black40')};
    }
  `
}

export function buttonTextStyles<T>(): FlattenInterpolation<ThemeProps<T>> {
  return css`
    font-weight: 600;
    font-size: ${fontSize(1, 1.125)};
    font-family: 'Inter', sans-serif;
    line-height: 1.2;
    color: inherit;
    width: 100%;
    text-align: center;
    padding-right: ${ifProp({withIcon: true}, '24px', '0')};
  `
}

export function outlinedButtonStyles<T>({
  color,
}: Props): FlattenInterpolation<ThemeProps<T>> {
  return css`
    ${commonButtonStyles}
    border: 1px solid ${color ? palette(color) : 'inherit'};
    background-color: ${color ? palette('white') : 'inherit'};
    color: ${palette('black')};

    svg {
      color: ${color ? palette(color) : 'inherit'};
    }

    &:active&:not(:disabled) {
      background-color: ${palette(`${color}10`)};
    }

    &:disabled {
      background-color: ${palette('black20')};
      border: 1px solid ${palette('black40')};
      color: ${palette('black40')};
    }
  `
}

export function cartButtonStyles<T>({
  color,
}: Props): FlattenInterpolation<ThemeProps<T>> {
  return css`
    ${commonButtonStyles}
    border: 1px solid ${color ? palette(color) : 'inherit'};
    background-color: ${palette('white')};
    color: ${palette('black')};
    padding: 0;
    height: 100%;

    svg {
      color: ${palette('white')};
      margin-left: 16px;
      z-index: 1;
    }

    &:active&:not(:disabled) {
      background-color: ${palette(`${color}10`)};
    }

    &:disabled {
      background-color: ${palette('background')};
      border: 1px solid ${palette('secondary')};
      color: ${palette('black40')};

      svg {
        color: ${palette('white')};
      }

      &:hover {
        background-color: ${palette('background')};
      }
    }

    &:hover {
      background-color: ${palette('black10')};
    }
  `
}
