import {resolveEcoCerts, resolveMiscTags} from '.'
import {RvLabel} from '..'
import {ProductContext} from '../utils'

const MAX_ITEMS = 3

export const resolveLabels = (context: ProductContext): RvLabel[] => {
  const {product} = context
  const {attributes} = product
  if (!attributes || attributes.length === 0) {
    return []
  }
  const ecoCerts = resolveEcoCerts(context)
  const miscTags = resolveMiscTags(context)
  let labels = [...ecoCerts, ...miscTags]
  labels?.filter((l, i) => {
    if (l?.thumbnail === null) {
      labels?.unshift(labels?.splice(i, 1)[0])
    }
  });

  return [...labels]
    .map((value) => ({
      id: `${product.slug}-labels-${value?.id}`,
      name: value?.name,
      slug: value?.slug,
      thumbnail: value?.thumbnail,
    }))
    .slice(0, MAX_ITEMS)
}
