/* eslint-disable no-empty */
import {isSSR} from './isSSR'

export const getLocalStorageItem = (
  key: string,
  parse = true,
): string | null => {
  if (isSSR) {
    return null
  }
  const item = localStorage.getItem(key)
  if (!item) {
    return null
  }
  if (!parse) {
    return item
  }
  try {
    return JSON.parse(item)
  } catch (e) {}
  return ''
}

export const getSessionStorageItem = (
  key: string,
  parse = true,
): string | null => {
  if (isSSR) {
    return null
  }
  // const item = sessionStorage.getItem(key)
  const item = localStorage.getItem(key)
  if (!item) {
    return null
  }
  if (!parse) {
    return item
  }
  try {
    return JSON.parse(item)
  } catch (e) {}
  return ''
}
