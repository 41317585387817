import {createContext, useContext} from 'react'
import type {Maybe} from 'src/utils/Maybe'

type DataContext = Record<string, unknown>

const DataContext = createContext<DataContext>({})

export const useDataContext = <T extends unknown>(
  key: Maybe<string>,
): T | null => {
  const data = useContext(DataContext)
  if (key == null) {
    return null
  }
  return (data[key] as T) ?? null
}

export const DataContextProvider = ({
  children,
  value = {},
}: {
  children: JSX.Element
  value: Record<string, unknown> | null | undefined
}): JSX.Element => (
  <DataContext.Provider value={value || {}}>{children}</DataContext.Provider>
)
