import {isSearchTerm} from 'src/utils/isSearchTerm'
import {ProductContext} from '../utils'
import EditorJSHTML from 'editorjs-html'

export const resolveFullDescription = (context: ProductContext): string => {
  const editorHTML = EditorJSHTML()
  const {product} = context
  const descriptionData = JSON.parse(product.description ?? '{}')
  const blocks: {data?: {text?: string}}[] = descriptionData?.blocks ?? []
  const text = blocks.filter(
    (e) => e.data && e.data.text && !isSearchTerm(e.data.text),
  )
  if (text.length === 0) {
    return ''
  }
  const value = (editorHTML.parse({blocks: text}) ?? ['']).join('')
  return value ?? ''
}
