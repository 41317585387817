import React from 'react'
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'
import theme from '../../themes/theme'
import Loader from '../common/Loader'
import {BtnWrapper} from './Button'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  withGutter?: boolean
  arrow?: 'left' | 'right'
}

const textButtonStyles = css`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${palette('secondary')};
  cursor: pointer;
  background: none;
  border: none;
  transition: font-weight 0.15s, transform 0.15s;

  &:hover&:not(:disabled) {
    transform: translateX(4px) translateY(-4px);
  }

  &:active&:not(:disabled) {
    font-weight: 700;
  }

  &:focus {
    outline: 3px solid ${palette('sky')};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${palette('black40')};
  }

  svg {
    color: ${palette('red')};
  }
`

const Btn = styled.button`
  ${textButtonStyles}
`

const TextButton = ({
  loading = false,
  withGutter = false,
  arrow,
  children,
  ...props
}: Props): JSX.Element => (
  <BtnWrapper withGutter={withGutter}>
    <Btn {...props}>
      {arrow === 'left' && <ArrowLeftLineIcon style={{marginRight: '10px'}} />}
      {arrow !== 'left' && loading && (
        <Loader style={{color: theme.palette.secondary, marginRight: '10px'}} />
      )}
      {children}
      {arrow === 'left' && loading && (
        <Loader style={{color: theme.palette.secondary, marginLeft: '10px'}} />
      )}
      {arrow === 'right' && <ArrowRightLineIcon style={{marginLeft: '10px'}} />}
    </Btn>
  </BtnWrapper>
)

export default TextButton
