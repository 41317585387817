import React from 'react'
import NextLink from 'next/link'

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  href: string
  children: React.ReactNode
  shallow?: boolean
  prefetch?: boolean
  locale?: string | false
}
export default function Link({
  href,
  children,
  shallow,
  ...props
}: Props): JSX.Element {
  return (
    <NextLink href={href} shallow={shallow}>
      <a {...props}>{children}</a>
    </NextLink>
  )
}
