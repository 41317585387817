/* eslint-disable no-console */

import React, {useContext, createContext, useState, useCallback} from 'react'

import useSWR from 'swr'
import type {KeyedMutator} from 'swr/dist/types'
import type {
  ShoppingList,
  ShoppingListItemWriteRequest,
  ShoppingListRequest,
  ShoppingListItemWrite,
} from '../api/rest'
import {restApi, handleRestResponse} from '../api/rest'
import {useUser} from './UserContext'

interface Props {
  children: React.ReactNode
}

interface ShoppingListContextProps {
  shoppingLists: ShoppingList[]
  shoppingListsLoading: boolean
  useSingleShoppingList: (
    listId: string,
  ) => {
    singleShoppingList: ShoppingList | null
    revalidateSingleShoppingList: KeyedMutator<ShoppingList>
    singleShoppingListLoading: boolean
  }
  updateShoppingList: (id: string, shoppingList: ShoppingListRequest) => void
  deleteShoppingLists: (id: string) => void
  addItemToShoppingList: (data: ShoppingListItemWriteRequest) => void
  createShoppingList: (data: ShoppingListRequest) => void
  shoppingListItems?: ShoppingListItemWrite[]
  deleteModalIsOpen?: boolean
  setDeleteModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  createModalIsOpen: boolean
  setCreateModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  deleteItemFromShoppingList: (id: string) => void
}

export const ShoppingListContext = createContext<ShoppingListContextProps | null>(
  null,
)

const singleShoppingListFetcher = (id: string): Promise<ShoppingList> =>
  handleRestResponse(restApi.shoppingListsRetrieve(id))

export function ShoppingListProvider({children}: Props): JSX.Element {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false)
  const {companyId, user} = useUser()
  const companyIdInt = companyId ? JSON.parse(companyId) : undefined

  const shoppingListsFetcher = (): Promise<ShoppingList[]> =>
    handleRestResponse(
      restApi.shoppingListsList({
        company: companyIdInt,
        company__isnull: !companyId,
      }),
    )

  // TODO: handle swr error?
  const {
    data: shoppingLists,
    mutate: revalidateShoppingLists,
    isValidating: shoppingListsLoading,
  } = useSWR(
    user || (user && companyId) ? ['shoppingLists', user, companyId] : null,
    shoppingListsFetcher,
    {
      revalidateOnFocus: false,
    },
  )

  const useSingleShoppingList = (
    listId: string,
  ): {
    singleShoppingList: ShoppingList | null
    revalidateSingleShoppingList: KeyedMutator<ShoppingList>
    singleShoppingListLoading: boolean
  } => {
    // TODO: handle swr error?
    const {data, mutate, isValidating} = useSWR(
      user && listId ? [listId] : null,
      singleShoppingListFetcher,
      {
        revalidateOnFocus: false,
      },
    )

    const singleShoppingList = data ?? null
    const revalidateSingleShoppingList = mutate
    const singleShoppingListLoading = isValidating

    return {
      singleShoppingList,
      revalidateSingleShoppingList,
      singleShoppingListLoading,
    }
  }

  const createShoppingList = useCallback(
    (shoppingList: ShoppingListRequest) => {
      restApi
        .shoppingListsCreate({
          name: shoppingList.name,
          company: companyIdInt,
        })
        .then(() => {
          revalidateShoppingLists()
        })
      // TODO: Handle error
    },
    [companyIdInt, revalidateShoppingLists],
  )

  const updateShoppingList = useCallback(
    (id: string, shoppingList: ShoppingListRequest) => {
      restApi.shoppingListsUpdate(id, shoppingList).then(() => {
        revalidateShoppingLists()
      })
      // TODO: Handle error
    },
    [revalidateShoppingLists],
  )

  const deleteShoppingLists = useCallback(
    (id: string) => {
      restApi
        .shoppingListsDestroy(id)
        .then(() => revalidateShoppingLists())
        .catch((err) => {
          // TODO: Handle error
          console.error(err)
        })
    },
    [revalidateShoppingLists],
  )

  const addItemToShoppingList = useCallback(
    (shoppingListItem: ShoppingListItemWriteRequest) => {
      restApi.shoppingListItemsCreate(shoppingListItem).then(() => {
        revalidateShoppingLists()
      })
      // TODO: Handle error
    },
    [revalidateShoppingLists],
  )

  const deleteItemFromShoppingList = useCallback(
    (id: string) => {
      restApi
        .shoppingListItemsDestroy(id)
        .then(() => {
          revalidateShoppingLists()
        })
        .catch((err) => {
          // TODO: Handle error
          console.error(err)
        })
    },
    [revalidateShoppingLists],
  )

  return (
    <ShoppingListContext.Provider
      value={{
        shoppingLists: shoppingLists || [],
        shoppingListsLoading,
        useSingleShoppingList,
        updateShoppingList,
        deleteShoppingLists,
        addItemToShoppingList,
        createShoppingList,
        deleteModalIsOpen,
        setDeleteModalIsOpen,
        createModalIsOpen,
        setCreateModalIsOpen,
        deleteItemFromShoppingList,
      }}
    >
      {children}
    </ShoppingListContext.Provider>
  )
}

export function useShoppingList(): ShoppingListContextProps {
  const data = useContext(ShoppingListContext)
  if (data == null) {
    throw new Error('Only to be used inside Context')
  }
  return data
}
