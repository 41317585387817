import React from 'react'
import {Row, Col, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {PSmall, H4} from '../typography'
import {Button} from '../buttons'

const TitleWrapper = styled.div`
  padding-top: 42px;
  padding-bottom: 8px;

  ${media.md`
    padding-top: 25px;
  `}
`

const TextWrapper = styled.div`
  padding-bottom: 25px;
`
interface Props {
  onCancel: () => void
  onRemove: () => void
  title: string
  text: string
  cancelText: string
  submitText: string
}

export default function DeleteConfirmation({
  onCancel,
  onRemove,
  title,
  text,
  cancelText,
  submitText,
}: Props): JSX.Element {
  return (
    <Row>
      <Col>
        <TitleWrapper>
          <H4>{title}</H4>
        </TitleWrapper>
      </Col>
      <Col>
        <TextWrapper>
          <PSmall> {text} </PSmall>
        </TextWrapper>
      </Col>
      <Col>
        <Row>
          <Col md={6} xs={6}>
            <Button withGutter onClick={onCancel}>
              {cancelText}
            </Button>
          </Col>
          <Col md={6} xs={6}>
            <Button withGutter outlined onClick={onRemove}>
              {submitText}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
