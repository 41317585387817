import {makeVar} from '@apollo/client'
import {StorageType} from '@apollo/client/cache/inmemory/policies'
import {restApi} from 'src/api/rest'
import {priceApiBatchGet} from 'src/api/rest/batch'
import {isSSR} from 'src/utils/isSSR'
import {ProductPricing} from '..'
import {ApiResult} from '../productPricingInterfaces'
import {computePricingJSON} from './computePricingJSON'

interface Props {
  slug: string
  sku: string | null
  storage: StorageType
}

const loadingTokens: Record<string, boolean> = {}

export const resolvePricing = ({
  slug,
  sku,
  storage,
}: Props): ProductPricing[] | undefined | null => {
  if (isSSR) {
    // Do not try to fetch price from SSR
    return []
  }
  const key = `RVProduct:${slug}:pricing`
  let token = null
  try {
    token = JSON.parse(sessionStorage.getItem('jwt') ?? '')
  } catch (e) {}
  const tokenCacheKey = `${slug}:${token ?? 'anonymous'}`
  if (!storage[key]) {
    storage[key] = makeVar(undefined)
  }
  if (!storage[key]) {
    storage[key] = makeVar(undefined)
  }
  const value = storage[key]()
  if (!loadingTokens[tokenCacheKey] && value === undefined && sku) {
    loadingTokens[tokenCacheKey] = true
    priceApiBatchGet({sku})
      .then((results) => {
        if (results == null) {
          storage[key](null)
          return
        }
        const json = results
        const value = computePricingJSON({
          json,
          slug,
        }).sort((a, b) => b.quantityPerUnit - a.quantityPerUnit)
        storage[key](value)
        loadingTokens[tokenCacheKey] = false
      })
      .catch(() => {
        storage[key](null)
        loadingTokens[tokenCacheKey] = false
      })
  }
  if (Array.isArray(value) && value.length === 0) {
    return null
  }
  return value
}
