import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const Button = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.8;
  color: ${palette('black60')};
  cursor: pointer;
`

const Underline = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 8px;
  margin-left: 4px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    border-bottom: 1px solid ${palette('black60')};
  }
`

interface Props {
  text: React.ReactNode
  icon?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export default function UnderlineButton({
  text,
  icon,
  onClick,
}: Props): JSX.Element {
  return (
    <Button color="black60" onClick={onClick}>
      {icon}
      <Underline>{text}</Underline>
    </Button>
  )
}
