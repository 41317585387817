import {ProductTypePiece} from 'src/components/products/ProductCartPicker/interfaces'
import {UnitType} from '..'
import {ProductContext} from '../utils'

export const resolveUnitTypes = (context: ProductContext): UnitType[] => {
  const unitTypes = context.variants
  const values = Object.keys(unitTypes)
    .map((key) => unitTypes[key])
    .map((item) => ({
      __typename: 'UnitType' as const,
      id: `${context.product.slug}-unitType-${item.id ?? ProductTypePiece}`,
      name: item.id ?? ProductTypePiece,
      displayNameSingular: item.name_singular ?? '',
      displayNamePlural: item.name_plural ?? '',
      minQuantity: parseFloat(item.min_qty),
      quantityPerUnit: parseFloat(
        ((item as unknown) as {qty_per_unit: string}).qty_per_unit ?? '1',
      ),
    }))
    .sort((a, b) => b.quantityPerUnit - a.quantityPerUnit)
  return values
}
