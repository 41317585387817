import type {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import {css} from 'styled-components'
import {fluidRange as polishedRange} from 'polished'
import type {ThemeProps as GridThemeProps} from 'styled-bootstrap-grid'
import {media} from 'styled-bootstrap-grid'
import type {RvTheme} from 'src/themes/theme'
import gridTheme from '../themes/gridTheme'

type ThemeSizes = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
export function fluidRange(
  property: string,
  from: string,
  to: string,
  minScreen: ThemeSizes = 'sm',
  maxScreen: ThemeSizes = 'lg',
  theme: GridThemeProps['gridTheme'] = gridTheme,
): FlattenSimpleInterpolation {
  const breakpoints = theme?.breakpoints || {
    [minScreen]: 600,
    [maxScreen]: 1440,
  }
  return css`
    ${polishedRange(
      {
        prop: property,
        fromSize: from,
        toSize: to,
      },
      `${breakpoints[minScreen]}px`,
      `${breakpoints[maxScreen]}px`,
    )}
  `
}

export function fontSize(
  from: number,
  to: number,
): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    font-size: ${from}rem;

    ${fluidRange('font-size', `${from * 16}px`, `${to * 16}px`)}

    ${media.lg`
      font-size: ${to}rem;
    `}
  `
}

export function spacing(
  from: number,
  to: number,
): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    padding: ${from}px 0;

    ${fluidRange('padding-top', `${from}px`, `${to}px`)}
    ${fluidRange('padding-bottom', `${from}px`, `${to}px`)}

    ${media.lg`
      padding: ${to}px 0;
    `}
  `
}

export function spacingBottom(
  from: number,
  to: number,
): FlattenInterpolation<ThemeProps<RvTheme>> {
  return css`
    padding-bottom: ${from}px;

    ${fluidRange('padding-bottom', `${from}px`, `${to}px`)}

    ${media.lg`
      padding-bottom: ${to}px;
    `}
  `
}
