import {removeNonAsciiCharacters} from 'src/utils/removeNonAsciiCharacters'
import {restApi} from '.'
import {ApiResult} from '../local/productPricingInterfaces'

const TIME_BETWEEN_FETCH = 100
const MAX_ITEMS_IN_BATCH = 24

interface SlugRequest {
  sku: string
  resolve: (value: ApiResult) => void
  reject: () => void
}

const FETCH_STACK: SlugRequest[] = []
let timer: number | null = null

const _fetchNow = async () => {
  timer = null
  const elements = FETCH_STACK.splice(0, MAX_ITEMS_IN_BATCH)

  if (elements.length === 0) {
    return
  }

  const ObjectSKUResolver = elements.reduce((a, b) => {
    a[b.sku] = {
      resolve: b.resolve,
      reject: b.reject,
    }
    return a
  }, {} as Record<string, Omit<SlugRequest, 'sku'>>)

  const rejectSKU = (sku: keyof typeof ObjectSKUResolver) => {
    return ObjectSKUResolver[sku].reject
  }
  const rejectAll = () =>
    Object.keys(ObjectSKUResolver).forEach((sku) => rejectSKU(sku)())
  const resolveSKU = (sku: keyof typeof ObjectSKUResolver) => {
    return ObjectSKUResolver[sku].resolve
  }

  const clean = () => {
    if (FETCH_STACK.length && !timer) {
      timer = window.setTimeout(_fetchNow, TIME_BETWEEN_FETCH)
    }
  }

  const skus = elements.map(({sku}) => sku)

  const skustring = skus
    .map(removeNonAsciiCharacters)
    .map((value) => value.replace(' ', ''))
    .join(',')

  const data = await restApi.request({
    path: `/api/products/prices/?sku=${skustring}`,
    method: 'GET',
    secure: true,
    format: 'json',
  })

  if (!data.ok) {
    rejectAll()
    clean()
    return
  }

  const results = data.data.results
  const found = Object.keys(results)
  const notFound = skus.filter((sku) => !found.includes(sku))

  notFound.forEach((sku) => rejectSKU(sku)())

  found.forEach((sku) => {
    const value = {
      [sku]: results[sku],
    }
    resolveSKU(sku)(value)
  })

  clean()
}

/**
 * Batch request for price api
 */
export const priceApiBatchGet = async ({
  sku,
}: {
  sku: string
}): Promise<ApiResult> => {
  return new Promise((resolve, reject) => {
    const obj: SlugRequest = {
      sku,
      resolve,
      reject,
    }
    FETCH_STACK.push(obj)
    if (timer == null) {
      timer = window.setTimeout(_fetchNow, TIME_BETWEEN_FETCH)
    }
  })
}
