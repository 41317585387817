import {ProductContext} from '../utils'
import {Image} from '..'

export const resolveThumbnail = ({product}: ProductContext): Image | null => {
  const thumbnail = product.media?.find((e) => e.type === 'IMAGE')
  if (!thumbnail || !thumbnail.id || !thumbnail.url) {
    return null
  }
  const {id, url} = thumbnail
  return {
    __typename: 'Image',
    type: 'image',
    id,
    url,
  }
}
