import type {SetterOrUpdater} from 'recoil'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {Store} from './atom'
import type {StateType} from './interfaces'

export const useCheckoutStateValue = (): StateType =>
  useRecoilValue(Store.state)

export const useSetCheckoutStateValue = (): SetterOrUpdater<StateType> =>
  useSetRecoilState(Store.state)

export const useIsCheckoutLoading = (): boolean => {
  const state = useRecoilValue(Store.state)
  return state === 'LOADING'
}

export const useIsInitialLoad = (): boolean => {
  const state = useRecoilValue(Store.state)
  return state === 'INITIAL_LOAD'
}
