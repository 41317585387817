export const emailChangeErrorCodeStrings: Record<string, string> = {
  unique: 'Notandi með þetta netfang er nú þegar til.',
}

export const emailLoginErrorCodeStrings: Record<string, string> = {
  INVALID_CREDENTIALS: 'Ógilt netfang eða lykilorð',
  ACCOUNT_NOT_CONFIRMED:
    'Netfang hefur ekki verið staðfest, athugaðu tölvupóstinn þinn',
}

export const checkoutErrorCodeMap: Record<string, string> = {
  INSUFFICIENT_STOCK: 'Ekki nægilegt magn af þessari vöru til á lager',
  INVALID: 'Vitlaus kóði'
}

export const valitorActionCode: Record<string, string> = {}
