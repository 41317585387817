// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
if (SENTRY_DSN) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_VERCEL_ENVIRONMENT_NAME
      ? process.env.NEXT_PUBLIC_VERCEL_ENVIRONMENT_NAME
      : 'unknown',
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.2,
  })
}
