import { COMPANY_ID_LOCAL_STORAGE } from "src/const"
import { getLocalStorageItem } from "src/utils/getLocalStorageItem"

function securityWorker(token?: string | null | undefined) {
  const companyId = getLocalStorageItem(COMPANY_ID_LOCAL_STORAGE)
  
  const conf = {
    headers: {
      'saleor-company-id': companyId || '',
    },
  }
  if (token && typeof window !== 'undefined') {
    Object.assign(conf.headers, {
      Authorization: `JWT ${token}`,
    })
  }
  return Promise.resolve(conf)
}

export const restApiConf = {
  baseUrl: process.env.NEXT_PUBLIC_SALEOR_URL!.replace(/\/$/, ''),
  securityWorker,
}
