export const DEFAULT_REVALIDATE_TIME = 180
export const POLL_CHECKOUT = 1000 * 60 * 2
export const COMPANY_ID_LOCAL_STORAGE = 'companyId'
export const TOKEN_LOCAL_STORAGE = 'jwt'
export const monthNames = [
  'jan',
  'feb',
  'mar',
  'Apr',
  'maí',
  'jún',
  'júl',
  'águ',
  'sep',
  'okt',
  'nóv',
  'des',
]
export const monthNamesIcelandic = [
  'Janúar',
  'Febrúar',
  'Mars',
  'Apríl',
  'Maí',
  'Júní',
  'Júlí',
  'Ágúst',
  'September',
  'Október',
  'Nóvember',
  'Desember'
]