import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import OverlayModal from 'react-overlays/Modal'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'

import {CornerButton} from '../buttons'

import theme from '../../themes/theme'

interface ModalBorderProps {
  width?: '600px' | '800px'
}

const StyledModal = styled(OverlayModal)`
  position: fixed;
  top: calc(50vh - 300px);
  right: 10px;
  z-index: ${theme.zIndex.modal};
  overflow: auto;
  max-height: calc(100vh - (50vh - 300px));

  &:focus-visible {
    outline: none;
  }

  ${media.md`
    right: calc(50vw - 300px);
    max-height: unset;
    overflow: visible;
  `}
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: ${theme.zIndex.modal};
`

const ModalBorder = styled.div<ModalBorderProps>`
  position: relative;
  margin-bottom: 24px;
  width: ${(props) => props.width};
  max-width: calc(100vw - 20px);

  border: 1px solid ${palette('black')};
  background-color: ${palette('white')};
`

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
  border-bottom: 3px solid ${palette('red')};

  &:focus-visible {
    outline: none;
  }

  ${media.sm`
    padding: 40px 70px;
  `}
`

interface Props {
  show: boolean
  children: React.ReactNode
  setShow:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((value: boolean) => void)
  width?: '600px' | '800px'
}

export default function Modal({
  show,
  children,
  setShow,
  width = '600px',
}: Props): JSX.Element {
  return (
    <StyledModal
      restoreFocusOptions={{
        preventScroll: false,
      }}
      show={show}
      onBackdropClick={() => setShow(false)}
      renderBackdrop={(props: React.HTMLAttributes<HTMLDivElement>) => (
        <Backdrop {...props} />
      )}
    >
      <ModalBorder width={width}>
        <ChildContainer>{children}</ChildContainer>

        <CornerButton
          icon={<CloseLineIcon size={16} />}
          onClick={() => setShow(false)}
        />
      </ModalBorder>
    </StyledModal>
  )
}
