import type {ApolloClient} from '@apollo/client'
import type {DataLayerArgs} from 'react-gtm-module'
import type {
  ProductQuery,
  ProductQueryVariables,
} from 'src/api/saleor/generated'
import {ProductDocument} from 'src/api/saleor/generated'

interface Props {
  slug?: string
  event?: string
  category?: string
  client: ApolloClient<unknown>
  handle: (args: DataLayerArgs) => void
}

const EVENT = 'product-page'
const CATEGORY = 'Product'

export const handleProductTag = async ({
  slug,
  client,
  handle,
  event = EVENT,
  category = CATEGORY,
}: Props): Promise<void> => {
  if (!slug) {
    return
  }
  const {data} = await client.query<ProductQuery, ProductQueryVariables>({
    query: ProductDocument,
    variables: {
      slug,
    },
  })

  if (!data || !data.product) {
    return
  }
  const {
    product: {id: productId, name: productName},
  } = data
  try {
    handle({
      dataLayer: {
        event,
        category,
        productId,
        productName,
      },
    })
  } catch (_e) {
    // Empty on purpos
  }
}
