/* eslint-disable no-console */
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react'
import {usePageSubscription} from 'src/api/datocms/utils'
import type {QueryListenerOptions} from 'react-datocms'
import type {
  Webpage,
  Webpage_allPages as PageType,
  Webpage_footer as Footer,
  Webpage_header as Header,
  Webpage_navigationInfo as NavigationInfo,
} from 'src/api/datocms/types/Webpage'
import {useRouter} from 'next/router'
import type {Maybe} from 'src/utils/Maybe'

interface Props {
  subscription: QueryListenerOptions<Webpage, never>
  children: React.ReactNode
}

interface PageLink {
  path: string
  heading: string
  category: string
}

interface PageContextProps {
  footerData: Footer
  headerData: Header
  navigationInfo: NavigationInfo | undefined
  getPagesByCategory: (categoryId: string) => Maybe<PageType[]>
  getLinksBySlug: (slug: string) => PageLink[]
  currentOpenDropdown: Maybe<string>
  setOpenDropdown: (id: string) => void
  closeDropdown: () => void
}

export const PageContext = createContext<PageContextProps | null>(null)

const links = [
  {path: '/umsoknir/starf', heading: 'Starfsumsókn', category: 'umsoknir'},
  {
    path: '/umsoknir/reikningsvidskipti',
    heading: 'Umsókn um reikningsviðskipti',
    category: 'umsoknir',
  },
]
export function PageProvider({
  children,
  subscription,
}: Props): JSX.Element | null {
  const {data: pageData} = usePageSubscription(subscription)
  const footerData = pageData?.footer
  const headerData = pageData?.header
  const pages = pageData?.allPages
  const navigationInfo = pageData?.navigationInfo
  const router = useRouter()

  const [currentOpenDropdown, setCurrentOpenDropdown] = useState<string>()

  const setOpenDropdown = useCallback(
    (id: string) => {
      // To make sure there is an animation delay
      // when two dropdowns are opening / closing
      if (currentOpenDropdown) {
        setCurrentOpenDropdown(undefined)
        setTimeout(() => {
          setCurrentOpenDropdown(id)
        }, 240)
      } else setCurrentOpenDropdown(id)
    },
    [currentOpenDropdown, setCurrentOpenDropdown],
  )

  const closeDropdown = useCallback(() => {
    setCurrentOpenDropdown(undefined)
  }, [setCurrentOpenDropdown])

  const getPagesByCategory = useCallback(
    (categoryId: string) =>
      pages?.filter((page) => page.category?.id === categoryId),
    [pages],
  )

  const getLinksBySlug = useCallback(
    (slug: string) => links?.filter((page) => page.category === slug),
    [],
  )

  useEffect(() => {
    closeDropdown()
  }, [closeDropdown, router.asPath])
  if (footerData == null || headerData == null || getPagesByCategory == null) {
    return null
  }
  return (
    <PageContext.Provider
      value={{
        footerData,
        headerData,
        navigationInfo,
        getPagesByCategory,
        getLinksBySlug,
        currentOpenDropdown,
        closeDropdown,
        setOpenDropdown,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export function usePageContext(): PageContextProps {
  const data = useContext(PageContext)
  if (data == null) {
    throw new Error('Only to be used inside Context')
  }
  return data
}
