import {useCallback} from 'react'
import {atom, DefaultValue, selector, useRecoilState} from 'recoil'
import {isSSR} from 'src/utils/isSSR'

interface AnonymousToken {
  set: (value: string) => void
  remove: () => void
  token: string | undefined
}

const LOCAL_KEY = 'myanonymousCartToken'

const tokenValue = atom<string | null | undefined>({
  key: 'anonymous-token-value',
  default: isSSR ? null : localStorage.getItem(LOCAL_KEY) ?? null,
})

const tokenStore = selector<string | null | undefined>({
  key: 'anonymous-token-store',
  get: ({get}) => {
    const value = get(tokenValue)
    return value
  },
  set: ({set}, newValue) => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(LOCAL_KEY)
      set(tokenValue, null)
      return
    }
    if (newValue == null) {
      localStorage.removeItem(LOCAL_KEY)
    } else {
      localStorage.setItem(LOCAL_KEY, newValue)
    }
    set(tokenValue, newValue)
  },
})

export const useAnonymousToken = (): AnonymousToken => {
  const [token, set] = useRecoilState(tokenStore)
  const remove = useCallback(() => {
    set(null)
  }, [set])
  return {
    set,
    remove,
    token: token ?? undefined,
  }
}
