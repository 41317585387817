/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {HttpResponse} from './http-client'

export async function handleRestResponse<D, E>(
  promise: Promise<HttpResponse<D, E>>,
) {
  const res = await promise
  if (res.error) {
    throw res.error
  }
  return res.data
}
