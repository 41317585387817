import type {RvProduct} from 'src/api/local'
import type {User} from 'src/api/rest'
import {paths, generatePath} from 'src/utils/paths';

export {};

declare const klaviyo: any;

export function trackUser(user: User) {
  // klaviyo onsite tracking code
  if (typeof klaviyo !== 'undefined') {
    klaviyo.push(['identify', {
      '$email' : user?.email
    }]);
  }
}

export function trackProductPage(item: RvProduct) {
  if (typeof klaviyo !== 'undefined') {
    klaviyo.track('Viewed Product',
    {
      Title: item.name,
      ItemId: item.id,
      ProductID: item.variantId,
      Tags: item.miscTags,
      ImageUrl: item.thumbnail?.url,
      Url: generatePath(paths.product, item.slug),
    });
  }
}

export function trackStartedCheckout(user: User, lines: any, totalPrice: number | undefined) {
  if (typeof klaviyo !== 'undefined') {
    const items = lines?.map((line: any) => {
      return {
        "ProductID": line.variant?.id,
        "ProductName": line.variant?.name,
        "Quantity": line.quantity,
        "ItemPrice": line.variant?.price,
        "RowTotal": line.variant?.price ? (line.variant?.price * line.quantity) : 0,
      }
    });
    const itemNames = lines?.map((line: any) => line?.variant?.name);
    const dateStr = new Date().toDateString().replaceAll(' ','-')
    klaviyo.track(
      "Started Checkout", {
        "$event_id": `${user?.id}-${dateStr}`,
        "$value": totalPrice,
        "ItemNames": itemNames,
        "Items": items
      }
    );     
  }
}

export async function postToKlaviyo(email: string, eventName: string, properties: any) {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      revision: '2024-10-15',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          properties: properties,
          metric: {
            data: {
              type: 'metric',
              attributes: {
                name: eventName
              }
            }
          },
          profile: {
            data: {
              type: 'profile',
              attributes: {
                properties: {
                  "$email": email
                }
              }
            }
          }
        }
      }
    })
  };
  const result = await fetch(
      `https://a.klaviyo.com/client/events/?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_API_KEY}`,
      options,
    )
  return result;
};
