const theme = {
  palette: {
    primary: '#EF3F57',
    secondary: '#19191B',
    background: '#FAFAFC',
    white: '#FEFEFE',
    red: '#EF3F57',
    black: '#19191B',
    yellow: '#FFDF5A',
    orange: '#FF8A00',
    green: '#38CA72',
    sky: '#26B7D7',
    ocean: '#0B86B9',
    plum: '#D869FF',
    deepBlue: '#080450',
    error: '#EF3F57',
    warning: '#FDB717',
    success: '#38A957',
    red10: '#FDECEE',
    red20: '#FCD9DD',
    red30: '#FAC5CD',
    red40: '#F9B2BC',
    red60: '#F58C9A',
    black2: '#FAFAFA',
    black5: '#F4F4F4',
    black10: '#E8E8E8',
    black20: '#D1D1D1',
    black30: '#BABABB',
    black40: '#A3A3A4',
    black50: '#8C8C8D',
    black60: '#757576',
    black70: '#5E5E5F',
    black80: '#474749',
    black90: '#303032',
    blue: '#3F99EF',
  },
  zIndex: {
    root: 0,
    modal: 900,
    sideDrawer: 500,
    header: 600,
    pageLoader: 800,
    select: 400,
  },
  navBarHeight: {
    small: '56px',
    large: '64px',
  },
  screenSize: {
    sizeXS: '360px',
    sizeSM: '600px',
    sizeMD: '1024px',
    sizeLG: '1440px',
    sizeXL: '1920px',
  },
  headerAndFooterSize: {
    mobile: '338px',
    tablet: '749px',
    desktop: '1384px',
    xLargeDesktop: '1845px',
  },
}
export type RvTheme = typeof theme
export default theme
