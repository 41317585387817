import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import type {
  CheckoutFragment,
  CheckoutLineDeleteMutation,
  CheckoutLineDeleteMutationVariables,
} from 'src/api/saleor/generated'
import {CheckoutLineDeleteDocument} from 'src/api/saleor/generated'
import {isNotNull} from 'src/utils/isNotNull'
import type {Maybe} from 'src/utils/Maybe'

interface UseClearCheckoutProps {
  token: string
  refetch: () => void
  checkout: Maybe<CheckoutFragment>
}

export const useClearCheckout = ({
  checkout,
  token,
  refetch,
}: UseClearCheckoutProps): (() => void) => {
  const [handleDelete] = useMutation<
    CheckoutLineDeleteMutation,
    CheckoutLineDeleteMutationVariables
  >(CheckoutLineDeleteDocument)
  return useCallback(async () => {
    const skus = (checkout?.lines ?? []).filter(isNotNull).map(({id}) => id)
    await Promise.allSettled(
      skus.map((sku) =>
        handleDelete({
          variables: {
            token,
            lineId: sku,
          },
        }),
      ),
    )
    refetch()
  }, [checkout?.lines, handleDelete, refetch, token])
}
