import React from 'react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {ifProp} from 'styled-tools'
import {buttonStyles, outlinedButtonStyles} from './buttonStyles'
import shadowStyles from '../../styles/shadowStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactElement
  loading?: boolean
  withGutter?: boolean
  fill?: boolean
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
}

interface BtnWrapperProps {
  withGutter: boolean
}

interface BtnContainerProps {
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
}

const Btn = styled.button<Props>`
  ${(p) =>
    p.outlined
      ? outlinedButtonStyles({color: p.color})
      : buttonStyles({color: p.color})}
  width: inherit;
  min-width: 50px;
  justify-content: center;
  padding: 0 10px;

  svg {
    margin: 0;
  }

  ${media.sm`
    min-width: 50px;
  `}
`

const BtnWrapper = styled.div<BtnWrapperProps>`
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0px')};
`

const BtnContainer = styled.div<BtnContainerProps>`
  height: fit-content;
  width: fit-content;
  ${(p) =>
    shadowStyles({
      color: p.color ?? 'inherit',
      outlined: !p.outlined,
      length: 8,
      showOnHover: true,
    })}
`

export default function IconButton({
  icon,
  disabled,
  withGutter = false,
  fill = false,
  color = 'secondary',
  outlined = false,
  ...props
}: Props): JSX.Element {
  return (
    <BtnWrapper withGutter={withGutter}>
      <BtnContainer color={color} outlined={outlined}>
        <Btn
          disabled={disabled}
          fill={fill ? true : undefined}
          color={color}
          outlined={outlined}
          {...props}
        >
          {icon}
        </Btn>
      </BtnContainer>
    </BtnWrapper>
  )
}
