import {atom} from 'recoil'
import type {CheckoutFragment} from 'src/api/saleor/generated'
import type {Maybe} from 'src/utils/Maybe'
import type {StateType} from './interfaces'

export const Key = <T extends string>(value: T): `checkout:${T}` =>
  `checkout:${value}`
const checkout = atom<Maybe<CheckoutFragment>>({
  key: Key('Checkout'),
  default: null,
})

const loadingCartState = atom<Record<string, null | number>>({
  key: Key('LoadingCartState'),
  default: {},
})

const state = atom<StateType>({
  key: Key('State'),
  default: 'INITIAL_LOAD',
})

export const Store = {
  loadingCartState,
  checkout,
  state,
}
