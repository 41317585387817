import styled, {css} from 'styled-components'
import {ifProp, palette} from 'styled-tools'

interface Props {
  color?: string
  withGutter?: boolean
  bold?: boolean
  top?: number
  bottom?: number
  left?: number
  right?: number
  textAlign?: 'center' | 'right' | 'left'
}

export const paragraphStyles = css<Props>`
  line-height: 1.8;
  padding-bottom: ${ifProp('withGutter', '20px', 0)};
  font-weight: ${ifProp('bold', '700', '400')};
  color: ${({color}) => {
    if (!color || !palette(color)) {
      return 'inherit'
    }
    return palette(color)
  }};
  text-align: ${ifProp('textAlign', (p) => p.textAlign, 'inherit')};
  margin-top: ${(p) => (p.top ? `${p.top}px` : '0px')};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : '0px')};
  margin-left: ${(p) => (p.left ? `${p.left}px` : '0px')};
  margin-right: ${(p) => (p.right ? `${p.right}px` : '0px')};

  ul > li {
    margin-left: 20px;
    list-style-type: disc;
  }
`

export const PSmall = styled.p`
  font-size: 0.875rem;
  ${paragraphStyles}
`

export const P = styled.p`
  font-size: 1rem;
  ${paragraphStyles}
`

export const PLarge = styled.p`
  font-size: 1.125rem;
  ${paragraphStyles}
`
