// Apollo typing convention to use any here.
/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ApolloClient,
  DocumentNode,
  FetchPolicy,
  OperationVariables,
  QueryHookOptions,
  QueryOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'
import {useQuery} from '@apollo/client'
import {useRef} from 'react'
import {initializeApollo} from 'src/api/saleor'
import {isSSR} from '../isSSR'

type QueryMode = 'CLIENT' | 'SSR_INITIAL' | 'SSR_RENDER'

interface GqlQueryProps<T = any, TVariables = OperationVariables>
  extends QueryOptions<TVariables, T> {
  client?: ApolloClient<unknown>
  queryMode?: QueryMode
}

export type HookQuery<T = any, TVariables = OperationVariables> = Omit<
  GqlQueryProps<T, TVariables>,
  'client'
> &
  Partial<Pick<GqlQueryProps<T, TVariables>, 'client'>>

export type HookQueryInitialRender<
  T = any,
  TVariables = OperationVariables
> = Omit<HookQuery<T, TVariables>, 'queryMode'> & {
  queryMode: 'SSR_INITIAL'
}

export type HookQueryRender<T = any, TVariables = OperationVariables> = Omit<
  HookQuery<T, TVariables>,
  'queryMode'
> & {
  queryMode?: 'CLIENT' | 'SSR_RENDER' | undefined
}

const DefaultFetchPolicy: Record<QueryMode, FetchPolicy> = {
  CLIENT: 'cache-first',
  SSR_INITIAL: 'network-only',
  SSR_RENDER: 'cache-only',
}

export const gqlQuery = async <T = any, TVariables = OperationVariables>({
  client = initializeApollo(),
  queryMode = isSSR ? 'SSR_INITIAL' : 'CLIENT',
  ...options
}: GqlQueryProps<T, TVariables>): Promise<T> => {
  const fetchPolicy = DefaultFetchPolicy[queryMode]
  const {data} = await client.query<T, TVariables>({
    fetchPolicy,
    ...options,
  })
  return data
}

export function useGqlQueryData<T = any, TVariables = OperationVariables>(
  props: HookQueryInitialRender<T, TVariables>,
): Promise<{data: T; error: false; loading: false}>
export function useGqlQueryData<T = any, TVariables = OperationVariables>(
  props: HookQueryRender<T, TVariables>,
): QueryResult<T, TVariables>
export function useGqlQueryData<T = any, TVariables = OperationVariables>({
  query,
  queryMode = isSSR ? 'SSR_RENDER' : 'CLIENT',
  client: _client,
  ...options
}: HookQueryRender<T, TVariables> | HookQueryInitialRender<T, TVariables>):
  | Promise<{
      data: T
      error: false
      loading: false
    }>
  | QueryResult<T, TVariables> {
  const fetchPolicy = DefaultFetchPolicy[queryMode]
  // This is the first inital call, we cannot use hook
  if (queryMode === 'SSR_INITIAL') {
    const client = _client || initializeApollo()
    return (async () => {
      const data = await gqlQuery({query, queryMode, client, ...options})
      return {
        data,
        error: false,
        loading: false,
      }
    })()
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery<T, TVariables>(query, {
    fetchPolicy,
    ...options,
  })
}

export const useQueryWithCache = <
  TData = any,
  TVariables = OperationVariables,
  X = Record<string, unknown>
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    keepKey?: X | null
  },
): QueryResult<TData, TVariables> & {keepKey?: X | null} => {
  const ref = useRef<null | TData>(null)
  const keyRef = useRef<X | null>(null)
  const {data, error, loading, ...rest} = useQuery<TData, TVariables>(
    query,
    options,
  )
  if (
    loading &&
    JSON.stringify(options?.keepKey) !== JSON.stringify(keyRef.current)
  ) {
    return {
      data: undefined,
      error,
      loading,
      keepKey: options?.keepKey,
      ...rest,
    }
  }
  if (data) {
    ref.current = data
    keyRef.current = options?.keepKey ?? null
  }
  if (options?.skip) {
    return {
      ...rest,
      data: undefined,
      error: undefined,
      loading: false,
    }
  }
  return {
    data: ref.current ?? undefined,
    error: ref.current ? undefined : error,
    loading: ref.current ? false : loading,
    keepKey: keyRef.current,
    ...rest,
  }
}

// Skip hook check
export const getQueryData = useGqlQueryData
