import {MetadataItem, Product, ProductVariant} from 'src/api/saleor/generated'
import {getVariant} from 'src/components/products/ProductCard/utils'
import {ProductTypeBox, ProductTypePiece} from 'src/components/products/ProductCartPicker/interfaces'

const DEFAULT_UNIT_TYPE = '_sales_uom_id'
const UNIT_TYPES = 'unit_types'

export interface UnitTypes {
  [key: string]: {
    id: string | null
    price: {
      net: string
      gross: string
      discount: number
    }
    min_qty: string
    name_singular: string
    name_plural: string
  }
}

export interface ProductContext {
  product: Product
  id: string
  isAvailable: boolean
  name: string
  productVariant: ProductVariant | undefined
  defaultVariantType: string
  variants: UnitTypes
}

export const getObjectFromProduct = (
  productDocument: Product,
): ProductContext => {
  const {id, isAvailable, name} = productDocument
  const productVariant = getVariant(
    (productDocument.variants ?? []).filter(
      (value): value is ProductVariant => value != null,
    ),
  )()
  const metadata =
    productVariant?.metadata.filter(
      (value): value is MetadataItem => value != null,
    ) ?? []
    const variantsValue =
    (metadata?.find((item) => item.key === UNIT_TYPES) ?? {}).value ?? '{}'
    const variants = JSON.parse(variantsValue) as UnitTypes
    const defaultVariantType = ProductTypeBox ?? Object.keys(variants)[0]

  return {
    product: productDocument,
    id,
    isAvailable: !!isAvailable,
    name,
    productVariant,
    defaultVariantType,
    variants,
  }
}
