import React from 'react'
import Image2LineIcon from 'remixicon-react/Image2LineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'

import {theme} from 'src/themes'

interface TextProps {
  textSize: string
}

interface WrapperProps {
  withPadding: boolean
}

const Container = styled.div`
  background-color: ${palette('black5')};
  display: flex;
  justify-content: center;
  height: 100%;
`

const ContentWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.withPadding ? '15% 6px' : '6px')};

  @media (min-width: 760px) {
    padding: ${(p) => (p.withPadding ? '35% 6px' : '12px')};
  }
`
const Text = styled.div<TextProps>`
  color: ${palette('black20')};
  font-size: ${(p) => p.textSize};
  font-weight: 600;
  margin-top: 6px;
  text-align: center;

  ${media.md`
    margin-top: 12px;
  `}
`
interface Props {
  size: number
  textSize?: string
  text?: string
  withPadding?: boolean
}

export default function NoImage({
  size,
  textSize = '1.125rem',
  withPadding = false,
  text,
}: Props): JSX.Element {
  return (
    <Container>
      <ContentWrapper withPadding={withPadding}>
        <Image2LineIcon color={theme.palette.black20} size={size} />
        <Text textSize={textSize}>{text || 'Mynd vantar'}</Text>
      </ContentWrapper>
    </Container>
  )
}
