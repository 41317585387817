import {FieldReadFunction, InMemoryCache, makeVar} from '@apollo/client'
import {StorageType} from '@apollo/client/cache/inmemory/policies'
import {companyGQLVar, userIDGQLVar} from 'src/context/UserContext'
import {
  Product,
  ProductDocument,
  ProductQuery,
  ProductQueryVariables,
} from 'src/api/saleor/generated'

import {StrictTypedTypePolicies} from './generated/typePolicy'
import {getObjectFromProduct} from './utils'
import {
  resolveBreadcrumbs,
  resolveDescription,
  resolveSKU,
  resolveCategoryName,
  resolveMedia,
  resolveUnitTypes,
  resolveInfoDocs,
  resolvePricing,
  resolveSecurityDocs,
  resolveEcoCerts,
  resolveMiscTags,
  resolveDefaultUnitType,
  resolveOfferString,
  resolveThumbnail,
  resolveFullDescription,
  resolveHidePrice,
  resolveRelatedProducts,
  resolveLabels,
} from './resolvers'
import {RvProduct} from '.'

const loadingSlug: Record<string, boolean> = {}

export const fetchFnServer = (slug: string | null | undefined, cache: InMemoryCache): RvProduct | null => {
  if (!slug) {
    throw new Error('Slug not defined')
  }
  const key = `product:${slug}`

  const product = cache.readQuery<ProductQuery, ProductQueryVariables>({
    query: ProductDocument,
    variables: {
      slug,
    },
  })
  
  if (!product || !product.product) {
    return null
  }

  const value = product.product as Product
  
  const context = getObjectFromProduct(value)
  const sku = resolveSKU(context)
  const content: RvProduct = {
    __typename: 'RVProduct' as const,
    id: context.id,
    offerString: resolveOfferString(context),
    breadcrumbs: resolveBreadcrumbs(context),
    defaultUnitType: resolveDefaultUnitType(context),
    thumbnail: resolveThumbnail(context),
    hidePrice: resolveHidePrice(context),
    unitTypes: resolveUnitTypes(context),
    media: resolveMedia(context),
    categoryName: resolveCategoryName(context),
    infoDocs: resolveInfoDocs(context),
    securityDocs: resolveSecurityDocs(context),
    ecoCerts: resolveEcoCerts(context),
    miscTags: resolveMiscTags(context),
    sku,
    variantId: context.productVariant?.id ?? null,
    slug,
    isAvailable: !!context.product.isAvailable,
    name: context.product.name ?? '',
    fullDescription: resolveFullDescription(context),
    description: resolveDescription(context),
    relatedProducts: resolveRelatedProducts(context),
    labels: resolveLabels(context),
  } as any
  return content
}

export const fetchFnLocal = (slug: string | null | undefined, storage: StorageType, cache: InMemoryCache, ssr: boolean  = false): RvProduct | null => {
  if (!slug) {
    throw new Error('Slug not defined')
  }
  const key = `product:${slug}`
  // Bind cache to token and company
  if (!ssr) {
    companyGQLVar()
    userIDGQLVar()
  }

  if (!storage[key]) {
    storage[key] = makeVar(undefined)
  }

  const product = cache.readQuery<ProductQuery, ProductQueryVariables>({
    query: ProductDocument,
    variables: {
      slug,
    },
  })
  if (!ssr && !loadingSlug[slug] && product) {
    loadingSlug[slug] = true
    storage[key](product)
  }
  const value = storage[key]()
  if (value === undefined) {
    // Loading
    return undefined as any
  }
  if (!value.product) {
    // Not found
    return null as any
  }
  const context = getObjectFromProduct(value.product)
  const sku = resolveSKU(context)
  const content: RvProduct = {
    __typename: 'RVProduct' as const,
    id: context.id,
    offerString: resolveOfferString(context),
    breadcrumbs: resolveBreadcrumbs(context),
    pricing: resolvePricing({sku, slug, storage}) as any,
    defaultUnitType: resolveDefaultUnitType(context),
    thumbnail: resolveThumbnail(context),
    hidePrice: resolveHidePrice(context),
    unitTypes: resolveUnitTypes(context),
    media: resolveMedia(context),
    categoryName: resolveCategoryName(context),
    infoDocs: resolveInfoDocs(context),
    securityDocs: resolveSecurityDocs(context),
    ecoCerts: resolveEcoCerts(context),
    miscTags: resolveMiscTags(context),
    sku,
    variantId: context.productVariant?.id ?? null,
    slug,
    isAvailable: !!context.product.isAvailable,
    name: context.product.name ?? '',
    fullDescription: resolveFullDescription(context),
    description: resolveDescription(context),
    relatedProducts: resolveRelatedProducts(context),
    labels: resolveLabels(context),
  }
  return content
}

export const typePolicy: StrictTypedTypePolicies = {
  Query: {
    fields: {
      productBySlug: {
        read(
          _,
          {args, storage, cache},
        ): FieldReadFunction<
          any,
          {args: {slug: string}; storage: StorageType}
        > {
          if (!args || !args.slug) {
            // Type is weird
            return null as any
          }
          // Type is weird
          return fetchFnLocal(args.slug, storage, cache) as any
        },
      },
    },
  },
}
