import {AttributeValue} from 'src/api/saleor/generated'
import {intFromGlobalId, toGlobalId} from 'src/utils/graphql'
import {isNotNull} from 'src/utils/isNotNull'
import {EcoCert, RelatedProduct} from '..'
import {ProductContext} from '../utils'

const ATTRIBUTE_SLUG = 'related-products'

export const resolveRelatedProducts = (
  context: ProductContext,
): RelatedProduct[] => {
  const {attributes} = context.product
  if (!attributes || attributes.length === 0) {
    return []
  }
  if (!attributes || attributes.length === 0) {
    return []
  }
  const value = Array.from(
    new Set(
      ...attributes
        .filter((item) => item.attribute.slug === ATTRIBUTE_SLUG)
        .map((item) => {
          return item.values
            .filter(isNotNull)
            .map(({slug}) => slug)
            .filter(isNotNull)
            .map((slug) => toGlobalId('Product', slug.split('_')[1]))
        }),
    ),
  )
    .flat()
    .map((id) => ({
      id: `${context.product.slug}-productRelated-${id}`,
      productId: id,
    }))
  return value
}
