import React from 'react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import shadowStyles from '../../styles/shadowStyles'

const CardButton = styled.button`
  display: inline-block;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid ${palette('black')};
  border-bottom: 1px solid ${palette('black')};
  z-index: 222;
`

const ButtonShadow = styled.div`
  position: relative;
  padding: 15px 16px;
  cursor: pointer;

  &:hover {
    color: ${palette('white')};
    background-color: ${palette('black')};
  }
`
const CardButtonContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  ${shadowStyles({
    color: 'white',
    outlined: true,
    length: 4,
    showOnHover: true,
  })};
`
const CardButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
`

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactElement
}

export default function CornerButton({icon, ...props}: Props): JSX.Element {
  return (
    <CardButtonWrapper>
      <CardButtonContainer>
        <CardButton {...props}>
          <ButtonShadow>{icon}</ButtonShadow>
        </CardButton>
      </CardButtonContainer>
    </CardButtonWrapper>
  )
}
