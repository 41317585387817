import { round } from 'lodash'

// TODO: Test
// Multiply product price with quantity
export const calcTotalProductPrice = (
  priceInt: number,
  quantity: number,
): number => priceInt * quantity

// TODO: Test
// Format number to display price with separators
export function formatNumber(num: number, precision = 0): string {
  return round(num, precision).toLocaleString('de-DE')
}

// Calculates appropriate column sizes for small screens
// from given number of columns per row on large screens
export const smColSize = (colPerRow: number): number => {
  if (colPerRow > 6) {
    return 3
  }
  if (colPerRow > 4) {
    return 4
  }
  return 6
}

// Calculates appropriate column sizes for medium screens
// from given number of columns per row on large screens
export const mdColSize = (colPerRow: number): number => {
  if (colPerRow > 6) {
    return 2
  }
  if (colPerRow > 4) {
    return 3
  }
  return 4
}

// Calculates appropriate column sizes for large screens
// from given number of columns per row on large screens
export const lgColSize = (colPerRow: number): number =>
  colPerRow ? 12 / colPerRow : 3
