/* eslint-disable no-console */
import {useRouter} from 'next/router'
import React, {useContext, createContext, useState, useEffect} from 'react'

interface Props {
  children: React.ReactNode
}

interface OverlayContextProps {
  sideDrawerIsOpen: boolean
  setSideDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  showShoppingListModal: boolean
  setShowShoppingListModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const OverlayContext = createContext<OverlayContextProps | null>(null)

export function OverlayProvider({children}: Props): JSX.Element {
  const router = useRouter()
  const [sideDrawerIsOpen, setSideDrawerIsOpen] = useState<boolean>(false)
  const [showShoppingListModal, setShowShoppingListModal] = useState<boolean>(
    false,
  )

  useEffect(() => {
    setSideDrawerIsOpen(false)
  }, [router?.route])

  return (
    <OverlayContext.Provider
      value={{
        sideDrawerIsOpen,
        setSideDrawerIsOpen,
        showShoppingListModal,
        setShowShoppingListModal,
      }}
    >
      {children}
    </OverlayContext.Provider>
  )
}

export function useOverlay(): OverlayContextProps {
  const data = useContext(OverlayContext)
  if (data == null) {
    throw new Error('Only to be used inside Context')
  }
  return data
}
