import React from 'react'

import {useRouter} from 'next/router'
import cn from 'classnames'
import {range} from 'lodash'
import type {LinkProps} from 'next/link'
import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'

import {ArrowButton} from 'src/components/buttons'

interface Props {
  currentPage?: number
  pageSize?: number
  siblingCount?: number
  totalProdCount?: number
}

const Wrapper = styled.div`
  padding: 12px 0;
  margin-bottom: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${media.sm`
    flex-direction: row;
  `}
`
const PageMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  ${media.sm`
    margin-bottom: 0;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FlipLink = styled.a`
  margin-left: 20px;
`

const PageLink = styled.a`
  cursor: pointer;
  min-width: 36px;
  height: 36px;
  padding: 0 12px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: ${palette('secondary')};
  font-weight: 400;
  transition: transform 0.5s;

  &.selected {
    color: ${palette('primary')};
    font-weight: 600;
  }

  &:hover {
    transform: translateY(-4px) translateX(4px);

    &:before {
      transform: translateY(4px) translateX(-4px) translateZ(-1em);
    }
  }
`
const Dots = styled.div`
  margin: 4px;
  font-weight: 600;
  text-align: center;
  height: 24px;
`

export default function PaginationMenu({
  pageSize,
  totalProdCount,
  siblingCount = 2,
  currentPage,
}: Props): JSX.Element | null {
  const router = useRouter()

  const totalPages =
    totalProdCount && pageSize ? Math.ceil(totalProdCount / pageSize) : 0
  const hasLess = currentPage ? currentPage > 1 : false
  const hasMore = currentPage ? currentPage < totalPages : false

  const showMinEdge = currentPage ? currentPage > siblingCount + 1 : false
  const showMaxEdge = currentPage
    ? currentPage < totalPages - siblingCount
    : false

  const min = currentPage ? Math.max(currentPage - siblingCount, 1) : 0
  const max = currentPage ? Math.min(currentPage + siblingCount, totalPages) : 0

  const linkProps = (p: number): LinkProps => ({
    href: {
      pathname: router.pathname,
      query: {...router.query, page: p},
    },
    shallow: true,
  })

  if (!currentPage) {
    return null
  }

  return (
    <Wrapper>
      <PageMenuWrapper>
        {showMinEdge && (
          <>
            <Link {...linkProps(1)}>
              <PageLink>1</PageLink>
            </Link>
            <Dots>&#8230;</Dots>
          </>
        )}
        {range(min, max + 1).map((p: number) => (
          <Link key={p} {...linkProps(p)}>
            <PageLink className={cn({selected: p === currentPage})}>
              {p}
            </PageLink>
          </Link>
        ))}
        {showMaxEdge && (
          <>
            <Dots>&#8230;</Dots>
            <Link {...linkProps(totalPages)}>
              <PageLink>{totalPages}</PageLink>
            </Link>
          </>
        )}
      </PageMenuWrapper>
      <ButtonWrapper>
        <Link {...linkProps(currentPage - 1)}>
          <FlipLink>
            <ArrowButton
              disabled={!hasLess}
              outlined
              color="primary"
              direction="left"
            />
          </FlipLink>
        </Link>
        <Link {...linkProps(currentPage + 1)}>
          <FlipLink>
            <ArrowButton disabled={!hasMore} color="primary" />
          </FlipLink>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  )
}
