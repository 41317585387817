import {Loader} from '../common'

export const LOADER_ACTIVE_CLASS = 'is-active'
export const LOADER_ELEMENT_ID = 'rekstrarvorur-loading-indicator'

export const LoaderForPages = (): JSX.Element => (
  <div id={LOADER_ELEMENT_ID}>
    <Loader />
  </div>
)
