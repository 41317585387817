import {ProductTypePiece} from 'src/components/products/ProductCartPicker/interfaces'
import {isNotNull} from 'src/utils/isNotNull'
import {formatNumber} from 'src/utils/numbers'
import {ProductPricing} from '..'
import {PriceStoreItemDoesNotExist} from '../productPricingErrors'
import {ApiResult} from '../productPricingInterfaces'

export const computePricingJSON = ({
  json,
  slug,
}: {
  json: ApiResult
  slug: string
}): ProductPricing[] => {
  if (typeof json !== 'object' || json == null) {
    throw new PriceStoreItemDoesNotExist(slug)
  }
  if ('detail' in json) {
    throw new PriceStoreItemDoesNotExist(slug)
  }
  const rootItem = Object.keys(json)
    .filter(isNotNull)
    .map((key) => json[key])
    .find(Boolean)
  if (rootItem == null) {
    return []
  }

  const keys = Object.keys(rootItem) as Array<keyof typeof rootItem>
  const value = keys
    .map((key) => {
      const item = rootItem[key]
      if (item == null) {
        return null
      }
      return {
        id: `${slug}:pricing:${item.id ?? ProductTypePiece}`,
        extraDiscounts: [],
        unitType: item.id ?? ProductTypePiece,
        minQuantity: parseInt(item.min_qty, 10),
        quantityPerUnit: parseInt(item.qty_per_unit, 10),
        displayNameSingular: item.name_singular,
        displayNamePlural: item.name_plural,
        gross: parseFloat(item.price.gross),
        grossUnitPrice:
          parseFloat(item.price.gross) / parseInt(item.qty_per_unit, 10),
        net: parseFloat(item.price.net),
        netUnitPrice:
          parseFloat(item.price.net) / parseInt(item.qty_per_unit, 10),
        discount: item.price.discount,
        originalDiscount: item.price.discount,
      }
    })
    .filter(isNotNull)
    .sort(
      (prev, item) =>
        (item?.quantityPerUnit ?? 0) - (prev?.quantityPerUnit ?? 0),
    )

  return value.map((root) => {
    const rootDiscount = Math.round(root.discount);
    const grossOriginalPrice = !rootDiscount
      ? null
      : root.gross / (1 - root.discount / 100)
    const netOriginalPrice = !rootDiscount
      ? null
      : root.net / (1 - root.discount / 100)
    return {
      ...root,
      __typename: 'ProductPricing' as const,
      grossOriginalPrice,
      grossOriginalPriceFormatted: !grossOriginalPrice
        ? null
        : `${formatNumber(grossOriginalPrice)} kr.`,
      grossFormatted: `${formatNumber(root.gross)} kr.`,
      grossUnitPriceFormatted: `${formatNumber(root.grossUnitPrice)} kr.`,
      netOriginalPrice: !netOriginalPrice ? null : netOriginalPrice,
      netOriginalPriceFormatted: !rootDiscount || !netOriginalPrice
        ? null
        : `${formatNumber(netOriginalPrice)} kr.`,
      netUnitPriceFormatted: `${formatNumber(root.netUnitPrice)} kr.`,
      netFormatted: `${formatNumber(root.net)} kr.`,
      // discountFormatted: !root.discount
      //   ? null
      //   : `${formatNumber(root.discount)}%`,
      discountFormatted: !rootDiscount
        ? null
        : `${formatNumber(rootDiscount)}%`,
    }
  })
}
