import {isSearchTerm} from 'src/utils/isSearchTerm'
import {ProductContext} from '../utils'

export const resolveDescription = (context: ProductContext): string => {
  const {product} = context
  const descriptionData = JSON.parse(product.description ?? '{}')
  const description = descriptionData?.blocks?.find(Boolean).data?.text ?? ''
  if (!description || typeof description !== 'string') {
    return ''
  }

  return isSearchTerm(description) ? '' : description
}
