import {useEffect, useRef} from 'react'
import {useRouter} from 'next/router'
import {usePrevious} from 'react-use'
import {useApolloClient} from '@apollo/client'
import TagManager from 'react-gtm-module'
import {paths} from 'src/utils/paths'
import {handleCategoryTag} from './handle-category'
import {handleProductTag} from './handle-product'
import {handleProductCollectionTag} from './handle-product-collection'

const Pages = {
  Category: `${paths.categories}/[slug]`,
  ProductCollection: `${paths.productCollection}/[id]`,
  Product: `${paths.product}/[slug]`,
}

const convertProps = (
  props: string | string[] | undefined | null,
): string | null => {
  if (!props) {
    return null
  }
  if (Array.isArray(props)) {
    return props[0]
  }
  return props
}

export const TagManagerWatcher = (): null => {
  const router = useRouter()
  const initRef = useRef(false)
  const client = useApolloClient()
  const path = router.pathname
  const previousPath = usePrevious(path)
  const params = router.query
  const previousParams = usePrevious(params)

  useEffect(() => {
    if (
      path === previousPath &&
      JSON.stringify(params) === JSON.stringify(previousParams)
    ) {
      return
    }
    if (!initRef.current) {
      initRef.current = true
      return
    }
    const slug = convertProps(params.slug)
    const id = convertProps(params.id)
    const handle = TagManager.dataLayer.bind(TagManager)
    switch (path) {
      case Pages.ProductCollection: {
        if (!id) {
          return
        }
        handleProductCollectionTag({
          id,
          client,
          handle,
        })
        break
      }
      case Pages.Category:
        if (!slug) {
          return
        }
        handleCategoryTag({
          slug,
          client,
          handle,
        })
        break
      case Pages.Product:
        if (!slug) {
          return
        }
        handleProductTag({
          slug,
          client,
          handle,
        })
        break
      default:
    }
  }, [client, params, path, previousParams, previousPath])
  return null
}
