import styled, {css} from 'styled-components'
import {ifProp} from 'styled-tools'
import {fontSize} from '../../utils/styles'

export interface HeaderProps {
  uppercase?: boolean
  withGutter?: boolean
  center?: boolean
  top?: number
  bottom?: number
  left?: number
  right?: number
  textAlign?: 'center' | 'right' | 'left'
}

const common = css<HeaderProps>`
  width: 100%;
  font-family: 'Inter', sans-serif;
  text-transform: ${ifProp('uppercase', 'uppercase', 'none')};
  text-align: ${ifProp('textAlign', (p) => p.textAlign, 'inherit')};
  margin-top: ${(p) => (p.top ? `${p.top}px` : '0px')};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : '0px')};
  margin-left: ${(p) => (p.left ? `${p.left}px` : '0px')};
  margin-right: ${(p) => (p.right ? `${p.right}px` : '0px')};
  u {
    text-decoration: inherit;
  }
  i {
    font-style: inherit;
  }
  b,
  strong {
    font-weight: inherit;
  }
`

export const Title1 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '32px', 0)};
  ${fontSize(2.5, 3.5)}
  ${common}
`

export const Title2 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '32px', 0)};
  ${fontSize(2, 3)}
  ${common}
`

export const Title3 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '32px', 0)};
  ${fontSize(1.5, 2.25)}
  ${common}
`

export const Title4 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '24px', 0)};
  ${fontSize(1.125, 1.75)}
  ${common}
`

export const Title5 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '24px', 0)};
  ${fontSize(1, 1.5)}
  ${common}
`

export const Title6 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '16px', 0)};
  ${fontSize(1, 1.125)}
  ${common}
`

export const Title7 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  font-size: 1rem;
  padding-bottom: ${ifProp('withGutter', '8px', 0)};
  ${common}
`

export const Title8 = styled.div<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  font-size: 0.875rem;
  padding-bottom: ${ifProp('withGutter', '8px', 0)};
  ${common}
`
