import type {ApolloClient} from '@apollo/client'
import type {DataLayerArgs} from 'react-gtm-module'
import type {
  CategoryQuery,
  CategoryQueryVariables,
} from 'src/api/saleor/generated'
import {CategoryDocument} from 'src/api/saleor/generated'

interface Props {
  slug?: string
  event?: string
  category?: string
  client: ApolloClient<unknown>
  handle: (args: DataLayerArgs) => void
}

const EVENT = 'product-category'
const CATEGORY = 'Product category'

export const handleCategoryTag = async ({
  slug,
  client,
  handle,
  event = EVENT,
  category = CATEGORY,
}: Props): Promise<void> => {
  if (!slug) {
    return
  }
  const {data} = await client.query<CategoryQuery, CategoryQueryVariables>({
    query: CategoryDocument,
    variables: {
      slug,
    },
  })
  if (!data || !data.category) {
    return
  }
  const {
    category: {name: productCategoryName},
  } = data
  try {
    handle({
      dataLayer: {
        event,
        category,
        productCategoryName,
      },
    })
  } catch (error) {
    // Empty on purpose
  }
}
