import {AttributeValue} from 'src/api/saleor/generated'
import {MiscTag} from '..'
import {ProductContext} from '../utils'

const ATTRIBUTE_SLUG = 'misc-info-tags'

const IMAGES: Record<string, string> = {
  'astma-og-ofnæmisvottað': '/assets/astma.jpg',
  'endurunnið-plast': '/assets/plast.jpg',
}

export const resolveMiscTags = (context: ProductContext): MiscTag[] => {
  const {attributes} = context.product
  if (!attributes || attributes.length === 0) {
    return []
  }
  return attributes
    .filter((item) => item.attribute.slug === ATTRIBUTE_SLUG)
    .map((item) =>
      item.values
        .filter((value): value is AttributeValue => value != null)
        .map(({name, slug}) => ({
          id: `${context.product.slug}:${slug}`,
          name,
          slug,
          thumbnail:
            slug && IMAGES[slug]
              ? {
                  id: `${context.product.slug}:miscTags:thumbnail:${slug}`,
                  url: IMAGES[slug],
                }
              : null,
        }))
        .filter(
          (value): value is MiscTag => value.name != null && value.slug != null,
        ),
    )
    .flat()
}
