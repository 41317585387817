import type {ProductVariant} from 'src/api/saleor/generated'

export const getVariant = (variants: ProductVariant[]) => (
  selectedVariant?: string | null | undefined,
): ProductVariant | undefined =>
  variants.find((currentVariant) => {
    if (selectedVariant) {
      return currentVariant.sku === selectedVariant
    }
    // Always select first one if no specific is selected
    return true
  })
