/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
  fullName: string;
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  postalCode?: string;
  phone?: string;
}

export interface Category {
  id: number;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
  description?: Record<string, any>;
  descriptionPlaintext?: string;
  parent?: number | null;
  backgroundImage: { headerImage?: string };
  backgroundImageAlt?: string;
  productCount: number;
}

export interface Checkout {
  /** @format uuid */
  token: string;
}

export interface CloneShoppingListRequest {
  name: string;
}

export interface Company {
  id: number;
  navId: string;
  ssn: string;
  name: string;
  about?: string;
  invoicingAllowed: boolean;
  members: CompanyUser[];
  addresses: CompanyAddress[];
  defaultShippingAddress?: number | null;
  defaultBillingAddress?: number | null;
}

export interface CompanyAddress {
  id: number;
  companies: number[];
  name?: string;
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  cityArea?: string;
  postalCode?: string;
  country: CountryEnum;
  countryArea?: string;
  phone?: string;
}

export interface CompanyAddressRequest {
  companies: number[];
  name?: string;
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  cityArea?: string;
  postalCode?: string;
  country: CountryEnum;
  countryArea?: string;
  phone?: string;
}

export interface CompanyRequest {
  name: string;
  about?: string;
  defaultShippingAddress?: number | null;
  defaultBillingAddress?: number | null;
}

export interface CompanyUser {
  id: number;
  company: number;
  user: number;
  role?: RoleEnum;
}

export interface CompanyUserRequest {
  company: number;
  user: number;
  role?: RoleEnum;
}

export interface CompanyUserRequestRequest {
  companyName: string;
  companySsn: string;
  message?: string;
}

export enum CountryEnum {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  CV = "CV",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  SZ = "SZ",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  KP = "KP",
  MK = "MK",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  KR = "KR",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
}

export interface DefaultVariant {
  id: number;
  sku: string;
  name?: string;
  trackInventory?: boolean;
}

export interface EmailChangeRequest {
  /** @format email */
  email: string;
}

export interface Login {
  user: User;
  saleor: LoginSaleor;
}

export interface LoginRequest {
  phoneNumber: string;
}

export interface LoginSaleor {
  csrfToken: string;
  accessToken: string;
  refreshToken: string;
}

export interface Order {
  id: number;

  /** @format date-time */
  created: string;
  status?: StatusEnum;
  token?: string;
  checkoutToken?: string;
  shippingPrice: string;
  total: string;
  undiscountedTotal: string;
  customerNote?: string;
  weight?: string;
  lines: OrderLine[];
  billingAddress: Address;
  shippingAddress: Address;
}

export interface OrderLine {
  id: number;
  variant: VariantList;
  isShippingRequired: boolean;

  /**
   * @min 1
   * @max 2147483647
   */
  quantity: number;

  /**
   * @min 0
   * @max 2147483647
   */
  quantityFulfilled?: number;
  unitPrice: string;
  undiscountedUnitPrice: string;
  totalPrice: string;
  undiscountedTotalPrice: string;
}

export interface PaginatedCheckoutList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Checkout[];
}

export interface PaginatedCompanyList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Company[];
}

export interface PaginatedCompanyUserList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: CompanyUser[];
}

export interface PaginatedCompanyUserRequestList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: CompanyUserRequest[];
}

export interface PaginatedOrderList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Order[];
}

export interface PaginatedProductListList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null;
  results?: ProductList[];
}

export interface PatchedCompanyAddressRequest {
  companies?: number[];
  name?: string;
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  cityArea?: string;
  postalCode?: string;
  country?: CountryEnum;
  countryArea?: string;
  phone?: string;
}

export interface PatchedCompanyRequest {
  name?: string;
  about?: string;
  defaultShippingAddress?: number | null;
  defaultBillingAddress?: number | null;
}

export interface PatchedCompanyUserRequest {
  company?: number;
  user?: number;
  role?: RoleEnum;
}

export interface PatchedCompanyUserRequestRequest {
  companyName?: string;
  companySsn?: string;
  message?: string;
}

export interface PatchedShoppingListItemRequest {
  /**
   * @min 0
   * @max 2147483647
   */
  quantity?: number;
  comment?: string;
}

export interface PatchedShoppingListRequest {
  name?: string;
  description?: string;
  company?: number;
}

export interface PatchedUserRequest {
  /** @format email */
  email?: string;
}

export interface PrepareOrder {
  company: number;
  note?: string;
  method?: string;
  locationInfo?: any;
}

export interface PrepareOrderRequest {
  note?: string;
  method?: string;
  locationInfo?: any;
  location?: string;
}

export interface ProductDetail {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  type: string;
  name: string;
  firstImage: ProductMedia;
  category?: number | null;
  price: number;

  /** @format date-time */
  updatedAt: string | null;
  chargeTaxes?: boolean;
  inventoryStatus: Record<string, any>;
  defaultVariant: DefaultVariant;
  description?: Record<string, any>;
  collectAtWarehouse: boolean;
  metadata?: Record<string, any>;
  attributes: Record<string, any>;
  variants: ProductVariant[];
  media: ProductMedia[];
}

export interface ProductList {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  type: string;
  name: string;
  firstImage: ProductMedia;
  category?: number | null;
  price: number;

  /** @format date-time */
  updatedAt: string | null;
  chargeTaxes?: boolean;
  inventoryStatus: Record<string, any>;
  defaultVariant: DefaultVariant;
}

export interface ProductMedia {
  id: number;
  image: { productGallery2x?: string; productList?: string; productGallery?: string };
  type?: TypeEnum;
  alt?: string;
  externalUrl?: string | null;
  oembedData?: Record<string, any>;
  sortOrder: number | null;
}

export interface ProductMediaRequest {
  image: { productGallery2x?: File; productList?: File; productGallery?: File };
  type?: TypeEnum;
  alt?: string;
  externalUrl?: string | null;
  oembedData?: Record<string, any>;
}

export interface ProductType {
  id: number;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
  hasVariants?: boolean;
  isShippingRequired?: boolean;
  isDigital?: boolean;
  fields: ProductTypeField[];
}

export interface ProductTypeField {
  /** @pattern ^[-\w]+$ */
  slug: string;
  parameter: string;
  name: string;
  description: string;
  dataType: string;
  isMultiValue: boolean;
}

export interface ProductVariant {
  id: number;
  sku: string;
  name?: string;
  price: number;
  media: number[];
  trackInventory?: boolean;
  inventoryStatus: Record<string, any>;
  metadata?: Record<string, any>;
  sortOrder: number | null;
}

export interface RequestEmailChangeRequest {
  newEmail: string;

  /** @format uri */
  redirectUrl: string;
}

export enum RoleEnum {
  Staff = "staff",
  Admin = "admin",
}

export interface ShoppingList {
  /** @format uuid */
  id: string;
  name: string;
  description?: string;
  items: ShoppingListItem[];
  company?: number;
  createdBy: User;
  modifiedBy: User;

  /** @format date-time */
  dtCreated: string;

  /** @format date-time */
  dtModified: string;
}

export interface ShoppingListItem {
  /** @format uuid */
  id: string;

  /** @format uuid */
  list: string;
  productVariant: VariantList;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  comment?: string;
  createdBy: User;
  modifiedBy: User;

  /** @format date-time */
  dtCreated: string;

  /** @format date-time */
  dtModified: string;
}

export interface ShoppingListItemRequest {
  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  comment?: string;
}

export interface ShoppingListItemWrite {
  /** @format uuid */
  list: string;
  productVariant: number;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  comment?: string;
}

export interface ShoppingListItemWriteRequest {
  /** @format uuid */
  list: string;
  productVariant: number;

  /**
   * @min 0
   * @max 2147483647
   */
  quantity: number;
  comment?: string;
}

export interface ShoppingListRequest {
  name: string;
  description?: string;
  company?: number;
}

export interface SimpleCategory {
  id: number;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
}

export interface SimpleCategoryRequest {
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
}

export enum StatusEnum {
  Draft = "draft",
  Unconfirmed = "unconfirmed",
  Unfulfilled = "unfulfilled",
  PartiallyFulfilled = "partially fulfilled",
  PartiallyReturned = "partially_returned",
  Returned = "returned",
  Fulfilled = "fulfilled",
  Canceled = "canceled",
}

export interface TokenCreate {
  token: string;
}

export interface TokenCreateRequest {
  cardNumber?: string;
  expMonth?: number;
  expYear?: number;
  cvc?: string;

  /** @format uuid */
  virtualCardId?: string;
  store?: boolean;
}

export enum TypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export interface User {
  id: number;
  name: string;

  /** @format email */
  email: string;
  isStaff: boolean;
  avatar: { default: string };

  /** @format date-time */
  lastLogin: string | null;
  profile: number;
  verified: UserVerified;
}

export interface UserRequest {
  /** @format email */
  email: string;
}

export interface UserVerified {
  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  ssn?: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;
}

export interface UserVerifiedRequest {
  ssn?: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;
}

export interface VariantList {
  id: number;
  sku: string;
  name?: string;
  firstImage: ProductMedia;
  trackInventory?: boolean;
  price: number;
  inventoryStatus: Record<string, any>;
  product: VariantListProduct;
}

export interface VariantListProduct {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  type: string;
  category: SimpleCategory;
  name: string;
  description?: Record<string, any>;
  descriptionPlaintext?: string;
  chargeTaxes?: boolean;

  /** @format double */
  rating?: number | null;
}

export interface VariantListProductRequest {
  /** @pattern ^[-\w]+$ */
  slug: string;
  name: string;
  description?: Record<string, any>;
  descriptionPlaintext?: string;
  chargeTaxes?: boolean;

  /** @format double */
  rating?: number | null;
}

export interface VariantListRequest {
  sku: string;
  name?: string;
  firstImage: ProductMediaRequest;
  trackInventory?: boolean;
  product: VariantListProductRequest;
}

export interface VirtualCard {
  /** @format uuid */
  id: string;
  maskedCardNumber: string;
  expMonth: number;
  expYear: number;
  brand: string | null;
}

export interface VirtualCardRequest {
  cardNumber: string;
  expMonth: number;
  expYear: number;
  cvc: string;
}

export interface Warehouse {
  /** @format uuid */
  id?: string;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
  shippingZones?: number[];
  address: string[];
}
