import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client'
import {BatchHttpLink} from '@apollo/client/link/batch-http'
import {useEffect, useMemo, useRef} from 'react'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import urljoin from 'url-join'
import {setContext} from '@apollo/client/link/context'
import {toGlobalId} from 'src/utils/graphql'
import possibleTypes from './possibleTypes.json'
import {removeNonAsciiCharacters} from 'src/utils/removeNonAsciiCharacters'
import {relayStylePagination, StoreObject} from '@apollo/client/utilities'
import {typeDefs, typePolicy} from '../local'
import {ProductCountableConnection} from './generated'
import { usePrevious } from 'react-use'
import { COMPANY_ID_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from 'src/const'
import { getLocalStorageItem, getSessionStorageItem } from 'src/utils/getLocalStorageItem'
import { isSSR } from 'src/utils/isSSR'
import { Maybe } from 'src/utils/Maybe'
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

export const httpLink = new BatchHttpLink({
  uri: urljoin(
    process.env.NEXT_PUBLIC_SALEOR_URL || process.env.SALEOR_URL || '',
    '/graphql/',
  ),
  batchMax: 5,
  batchInterval: 10,
})

export const authLink = setContext((_, context) => {
  if (!isSSR) {
    const token = getSessionStorageItem(TOKEN_LOCAL_STORAGE)
    const companyId = getLocalStorageItem(COMPANY_ID_LOCAL_STORAGE)
    if (context.nonAuthorized) {
      return context;
    }
    if (token) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `JWT ${token}`,
          'saleor-company-id': companyId || '',
        },
      }
    }
  }
  return context
})

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache({
      typePolicies: {
        ...typePolicy,
        VariantPricingInfo: {
          merge: false,
        },
        Collection: {
          fields: {
            products: relayStylePagination(['first']),
          },
        },
        ProductVariants: {
          merge: false,
        },
        Checkout: {
          fields: {
            lines: {
              merge: false,
            },
          },
        },
        Query: {
          fields: {
            ...typePolicy.Query?.fields,
            products: {
              keyArgs(vars, b) {
                // Products
                const search = vars?.filter?.search
                const category = vars?.filter?.categories?.join(':') ?? 'all'
                const sortBy = vars?.sortBy
                  ? `sort:${vars?.sortBy.field}:${vars?.sortBy.direction}`
                  : 'normal'
                if (search) {
                  const id = `search:${search}:${sortBy}`
                  return toGlobalId('ProductCountableConnection', id)
                }
                if (vars?.filter?.categories) {
                  const id = `category:${category}:${sortBy}`
                  const globalId = toGlobalId('ProductCountableConnection', id)
                  return globalId
                }
                return 'products'
              },
              merge(
                oldArr: Partial<ProductCountableConnection> = {},
                newArr: ProductCountableConnection,
              ) {
                const edges = oldArr?.edges
                  ? [...oldArr.edges, ...newArr?.edges]
                  : newArr?.edges
                const edgesFiltered = edges?.filter((item, index) => {
                  return (
                    edges.findIndex(
                      (compareItem) =>
                        (item.node as any).__ref ===
                        (compareItem.node as any).__ref,
                    ) === index
                  )
                })
                return {
                  ...newArr,
                  edges: edgesFiltered,
                }
              },
            },
          },
        },
      },
      possibleTypes,
      dataIdFromObject: (object, context) => {
        if ('slug' in object && typeof object.slug === 'string') {
          return toGlobalId(
            object.__typename ?? 'UnknownType',
            removeNonAsciiCharacters(object.slug as string),
          )
        }
        if (
          ('id' in object && typeof object.id === 'number') ||
          typeof object.id === 'string'
        ) {
          return toGlobalId(object.__typename ?? 'UnknownType', object.id)
        }
        return defaultDataIdFromObject(object)
      },
    }),
    link: authLink.concat(httpLink),
    typeDefs: [typeDefs],
    defaultOptions: {
      watchQuery: {
        // TODO: Either of these fixes the loading issue, but is this bad?
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  })
}

export function initializeApollo(
  initialState: Maybe<Record<string, StoreObject | undefined>> = null,
): ApolloClient<NormalizedCacheObject> {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

type PageProps = Maybe<{
  [APOLLO_STATE_PROP_NAME]: Record<string, StoreObject | undefined>
}>

type PagePropsContainer = Maybe<{
  props?: PageProps
}>



export function addApolloState<X extends PagePropsContainer>(client: ApolloClient<unknown>, pageProps: X) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract() as Record<string, StoreObject | undefined> ?? {}
  }

  return pageProps
}

export function useApollo(pageProps: PageProps): ApolloClient<NormalizedCacheObject> {
  const initRef = useRef<boolean>(true)
  const cache = (pageProps || {})[APOLLO_STATE_PROP_NAME]
  const previousCache = usePrevious(cache)
  const {client, initState} = useMemo(() => {
    const initState = (pageProps || {})[APOLLO_STATE_PROP_NAME] ?? undefined
    const client = initializeApollo(initState)
    return {
      initState: initState,
      client
    }
  }, [])
  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      return
    }
    if (cache && JSON.stringify(cache) !== JSON.stringify(initState) && JSON.stringify(previousCache) !== JSON.stringify(cache)) {
      client.cache.restore(cache)
    }
  }, [])

  return client
}
