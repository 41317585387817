// ANYFORNOW!
/* eslint-disable @typescript-eslint/no-explicit-any */
import type {ApolloClient} from '@apollo/client'
import type {GetServerSidePropsContext} from 'next'
import {APOLLO_STATE_PROP_NAME} from 'src/api/saleor'
import {isFirstServerCall} from '.'
import type {HookQuery} from '../gqlQuery/gqlQuery'
import {getQueryData} from '../gqlQuery/gqlQuery'
import {getProp} from './getProp'
import {initGQLClient} from './initGQLClient'
import {createThrowNotFound} from './throwNotFound'

export const DATA_OBJECT_KEY = '__DATA_OBJECT'

export type PageQueryFn<T> = (props: QueryProps) => Promise<T> | T
export type QueryFn<T> = (
  props: Omit<HookQuery, 'client'>,
) => Promise<{data: T; error: false; loading: false}>
export interface QueryProps {
  context: GetServerSidePropsContext
  getProp: typeof getProp
  client: ApolloClient<unknown>
  throwNotFound: () => void
  props: Record<string, unknown>
  query: QueryFn<any>
}

interface Props {
  query?: PageQueryFn<any>
  data?: ({
    context,
    client,
    query,
  }: {
    query: QueryFn<any>
    client: ApolloClient<unknown>
    context: GetServerSidePropsContext
  }) => Promise<Record<string, any>>
  generateInitProps?: ({
    context,
    client,
  }: {
    client: ApolloClient<unknown> | null
    context: GetServerSidePropsContext
  }) => Promise<any> | any
  generateProps?: ({
    context,
    client,
  }: {
    client: ApolloClient<unknown> | null
    context: GetServerSidePropsContext
  }) => Promise<any> | any
  cacheKey?: string
}

type ServerProps<T> = {notFound: true} | {props: T}

const MAXAGE = 60 * 60 * 24 * 31
const REVALIDATE = 60 * 60 * 24

export const serverPropsRender = <T>({
  query,
  data,
  generateProps,
  generateInitProps,
  cacheKey = APOLLO_STATE_PROP_NAME,
}: Props) => async (
  context: GetServerSidePropsContext,
): Promise<ServerProps<T>> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const client = initGQLClient(context)
    const throwNotFound = createThrowNotFound(resolve)

    const props: Record<string, unknown> = {}

    if (client) {
      const queryFn = <Value extends any>(
        _props: Omit<HookQuery, 'client'>,
      ): Promise<{data: Value; error: false; loading: false}> =>
        getQueryData<Value>({
          ..._props,
          queryMode: 'SSR_INITIAL',
          client,
        })
      try {
        if (query) {
          await query({
            client,
            context,
            getProp,
            throwNotFound,
            props,
            query: queryFn,
          })
        }
        const initData =
          isFirstServerCall({context}) && generateInitProps
            ? await generateInitProps({context, client})
            : []
        resolve({
          props: {
            [DATA_OBJECT_KEY]: data
              ? await data({context, client, query: queryFn})
              : {},
            ...(generateProps ? await generateProps({context, client}) : {}),
            ...initData,
            ...props,
            [cacheKey]: client.cache.extract(),
          },
        })

        return
      } catch (e) {
        reject(e)
      }
    }
    context.res.setHeader(
      'Cache-Control',
      `public, s-maxage=${MAXAGE}, stale-while-revalidate=${REVALIDATE}`,
    )
    Promise.all([generateProps ? generateProps({context, client}) : {}]).then(
      (value) => {
        resolve({props: {...value[0], ...props, [cacheKey]: {}}})
      },
    )
  })
