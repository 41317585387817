import {OperationVariables} from '@apollo/client'
import {Options} from 'datocms-listen'
import {DocumentNode} from 'graphql'
import {useQuerySubscription} from 'react-datocms'

import {datocms} from './client'
import {FrontPage} from './types/FrontPage'
import {Page} from './types/Page'
import {Webpage} from './types/Webpage'

declare type SubscribeToQueryOptions<QueryResult, QueryVariables> = Omit<
  Options<QueryResult, QueryVariables>,
  'onStatusChange' | 'onUpdate' | 'onChannelError'
>
declare type EnabledQueryListenerOptions<QueryResult, QueryVariables> = {
  /** Whether the subscription has to be performed or not */
  enabled?: true
  /** The initial data to use while the initial request is being performed */
  initialData?: QueryResult
} & SubscribeToQueryOptions<QueryResult, QueryVariables>
declare type DisabledQueryListenerOptions<QueryResult, QueryVariables> = {
  /** Whether the subscription has to be performed or not */
  enabled: false
  /** The initial data to use while the initial request is being performed */
  initialData?: QueryResult
} & Partial<SubscribeToQueryOptions<QueryResult, QueryVariables>>
declare type QueryListenerOptions<QueryResult, QueryVariables> =
  | EnabledQueryListenerOptions<QueryResult, QueryVariables>
  | DisabledQueryListenerOptions<QueryResult, QueryVariables>

export async function makeSubscriptionProps<T, TVariables = OperationVariables>(
  preview: boolean,
  query: DocumentNode,
  variables?: TVariables,
  doNotCache?: boolean,
): Promise<QueryListenerOptions<T, TVariables>> {
  const {data} = await datocms(preview).query<T, TVariables>({
    query,
    variables,
    fetchPolicy: doNotCache ? 'network-only' : 'cache-first',
  })

  return preview && process.env.DATOCMS_API_TOKEN && query?.loc?.source?.body
    ? {
        enabled: true,
        initialData: data,
        query: query.loc.source.body,
        variables: variables || undefined,
        token: process.env.DATOCMS_API_TOKEN,
        preview,
      }
    : {
        enabled: false,
        initialData: data,
      }
}

export function usePageContentSubscription<
  QueryVariables = Record<string, any>
>(options: QueryListenerOptions<Page, QueryVariables>) {
  return useQuerySubscription<Page, QueryVariables>(options)
}

export function usePageSubscription<
  QueryVariables = Record<string, any>
>(options: QueryListenerOptions<Webpage, QueryVariables>) {
  return useQuerySubscription<Webpage, QueryVariables>(options)
}

export function useFrontPageContentSubscription<
  QueryVariables = Record<string, any>
>(options: QueryListenerOptions<FrontPage, QueryVariables>) {
  return useQuerySubscription<FrontPage, QueryVariables>(options)
}
