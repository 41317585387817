export {PSmall, P, PLarge, paragraphStyles} from './P'
export {H1, H2, H3, H4, H5, H6} from './Headings'
export {
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  Title7,
  Title8,
} from './Titles'
