import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import NoImage from './NoImage'

interface ContainerProps {
  width?: number
  height?: number
  objectFit?: string
}

const ImageContainer = styled.div<ContainerProps>`
  position: relative;
  object-position: center;
  height: ${(p) => (p.height ? `${p.height}px` : 'auto')};
  width: ${(p) => (p.width ? `${p.width}px` : '100%')};
  img {
    width: 100%;
    height: 100%;
    object-fit: ${(p) => (p.objectFit ? `${p.objectFit}` : 'contain')};
  }
`

interface Props extends ContainerProps {
  src: string
  alt: string
  objectFit?:
    | 'contain'
    | 'cover'
    | 'fill'
    | 'none'
    | 'scale-down'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
  layout?: 'fill' | 'responsive' | 'fixed' | 'intrinsic'
  useImg?: boolean
}

export default function Images({
  src,
  alt,
  height,
  width,
  objectFit,
  layout,
  useImg = false,
}: Props): JSX.Element {
  return (
    <ImageContainer objectFit={objectFit} height={height} width={width}>
      {src ? (
        <>
          {useImg ? (
            // use 'useImg' if you dont want to have a fixed height or width
            // eslint-disable-next-line @next/next/no-img-element
            <img src={src} alt={alt} />
          ) : (
            <Image
              src={src}
              alt={alt}
              height={height}
              width={width}
              layout={layout}
            />
          )}
        </>
      ) : (
        <NoImage size={50} />
      )}
    </ImageContainer>
  )
}
