/* eslint-disable @typescript-eslint/no-explicit-any */
import type {FetchResult} from '@apollo/client/link/core/types'
import {useCallback} from 'react'
import type {
  CheckoutShippingMethodUpdateMutation,
  MyCheckoutQuery,
} from 'src/api/saleor/generated'
import {useCheckoutShippingMethodUpdateMutation} from 'src/api/saleor/generated'
import type {Maybe} from 'src/utils/Maybe'

interface Props {
  refetch: () => void
  myCheckoutQuery: Maybe<MyCheckoutQuery>
}

export const useUpdateShippingMethod = ({
  refetch,
  myCheckoutQuery,
}: Props): ((
  id: string,
) => Promise<
  FetchResult<
    CheckoutShippingMethodUpdateMutation,
    Record<string, any>,
    Record<string, any>
  >
>) => {
  const [
    checkoutShippingMethodUpdate,
  ] = useCheckoutShippingMethodUpdateMutation({
    onCompleted: () => refetch(),
  })

  return useCallback(
    async (id: string) =>
      // if (!checkout) {
      //   return
      // }
      checkoutShippingMethodUpdate({
        variables: {
          token: myCheckoutQuery?.me?.checkout?.token,
          shippingMethodId: id,
        },
      }).then((res) => res),
    [checkoutShippingMethodUpdate, myCheckoutQuery?.me?.checkout?.token],
  )
}
