import {useRouter} from 'next/router'
import {useEffect, useMemo} from 'react'
import {atom, useSetRecoilState} from 'recoil'
import {
  LOADER_ACTIVE_CLASS,
  LOADER_ELEMENT_ID,
} from 'src/components/loader-for-pages'

interface PageID {
  pageID: string
}

export const PageIDAtom = atom<string | null>({
  key: 'page-ID',
  default: null,
})

export const PageID = ({pageID}: PageID): null => {
  const setPageID = useSetRecoilState(PageIDAtom)
  useEffect(() => {
    setPageID(pageID)
  }, [pageID, setPageID])
  return null
}

export const useGetPageID = (): PageID => {
  const router = useRouter()
  useEffect(() => {
    const el = document.getElementById(LOADER_ELEMENT_ID)
    const handleChangeStart = (): void => {
      if (!el) {
        return
      }
      el.classList.add(LOADER_ACTIVE_CLASS)
    }
    const handleChangeEnd = (): void => {
      if (!el) {
        return
      }
      el.classList.remove(LOADER_ACTIVE_CLASS)
    }
    router.events.on('routeChangeStart', handleChangeStart)
    router.events.on('routeChangeComplete', handleChangeEnd)
    router.events.on('routeChangeError', handleChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleChangeStart)
      router.events.off('routeChangeComplete', handleChangeEnd)
      router.events.off('routeChangeError', handleChangeEnd)
    }
  }, [router])

  const pageID = useMemo(
    () => `${router.pathname}-${JSON.stringify(router.query)}`,
    [router.pathname, router.query],
  )

  return {
    pageID,
  }
}
