import type {ApolloQueryResult} from '@apollo/client'
import {useApolloClient} from '@apollo/client'
import {useCallback, useEffect, useMemo} from 'react'
import type {
  CheckoutFragment,
  MyCheckoutQueryVariables,
  MyCheckoutQuery,
  CheckoutQuery,
  CheckoutQueryVariables,
  Exact,
  CheckoutLine,
} from 'src/api/saleor/generated'
import {MyCheckoutDocument, CheckoutDocument} from 'src/api/saleor/generated'
import {useQueryWithCache} from 'src/utils/gqlQuery/gqlQuery'
import {POLL_CHECKOUT} from 'src/const'
import type {Maybe} from 'src/utils/Maybe'
import {useAnonymousToken} from './useAnonymousToken'
import {useUser} from '../UserContext'
import {useSetCheckoutStateValue} from './useCheckoutState'
import {useSetCheckoutValue} from './useCheckout'

interface GetCheckout {
  checkoutReady: boolean
  checkoutLine: CheckoutLine[]
  update: () => Promise<CheckoutFragment | null>
  anonCheckoutQuery: CheckoutQuery | undefined
  myCheckoutQuery: MyCheckoutQuery | undefined
  myCheckoutLoading: boolean
  anonCheckoutLoading: boolean
  refetch: () => void
  refetchMyCheckout: (
    variables?: Partial<
      Exact<{
        [key: string]: never
      }>
    >,
  ) => Promise<ApolloQueryResult<MyCheckoutQuery>>
  refetchCheckout: (
    variables?: Partial<
      Exact<{
        token: Maybe<string>
      }>
    >,
  ) => Promise<ApolloQueryResult<CheckoutQuery>>
}

export const useGetCheckout = ({
  shouldForceReloadOnInit = true,
}: {shouldForceReloadOnInit?: boolean} = {}): GetCheckout => {
  const {user, showLogin} = useUser()
  const {token: anonymousToken} = useAnonymousToken()
  const client = useApolloClient()
  const setCheckoutValue = useSetCheckoutValue()
  const setState = useSetCheckoutStateValue()

  const {
    data: anonCheckoutQuery,
    loading: anonCheckoutLoading,
    refetch: refetchCheckout,
  } = useQueryWithCache<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    {
      skip: !anonymousToken,
      variables: {
        token: anonymousToken,
      },
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: POLL_CHECKOUT,
    },
  )

  const {
    data: myCheckoutQuery,
    loading: myCheckoutLoading,
    refetch: refetchMyCheckout,
  } = useQueryWithCache<MyCheckoutQuery, CheckoutQueryVariables>(
    MyCheckoutDocument,
    {
      skip: !user,
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
      pollInterval: POLL_CHECKOUT,
    },
  )

  const handleUpdate = useCallback(async (): Promise<CheckoutFragment | null> => {
    if (user) {
      const {data} = await client.query<
        MyCheckoutQuery,
        MyCheckoutQueryVariables
      >({
        query: MyCheckoutDocument,
        fetchPolicy: 'network-only',
        variables: {},
      })
      const myCheckout = data?.me?.checkout
      return myCheckout ?? null
    }
    if (anonymousToken) {
      const {data} = await client.query<CheckoutQuery, CheckoutQueryVariables>({
        query: CheckoutDocument,
        fetchPolicy: 'network-only',
        variables: {
          token: anonymousToken,
        },
      })
      const anonCheckout = data?.checkout
      return anonCheckout ?? null
    }
    return null
  }, [anonymousToken, client, user])

  const handleInitialLoad = useCallback(async () => {
    await handleUpdate()
      .then((value) => {
        setCheckoutValue(value)
      })
      .finally(() => {
        setState('READY')
      })
  }, [handleUpdate, setCheckoutValue, setState])

  useEffect(() => {
    if (!shouldForceReloadOnInit) {
      return
    }
    handleInitialLoad()
  }, [handleInitialLoad, shouldForceReloadOnInit])
  const checkoutReady = useMemo(() => {
    if (!showLogin) {
      return false
    }
    if (user) {
      return myCheckoutQuery?.me?.checkout != null
    }
    return anonCheckoutQuery?.checkout != null
  }, [
    anonCheckoutQuery?.checkout,
    myCheckoutQuery?.me?.checkout,
    showLogin,
    user,
  ])
  const refetch = useCallback(() => {
    if (user) {
      refetchMyCheckout()
      return
    }
    refetchCheckout()
  }, [refetchCheckout, refetchMyCheckout, user])
  const checkoutLine = useMemo(() => {
    if (showLogin) {
      return []
    }
    if (!user) {
      return anonCheckoutQuery?.checkout?.lines ?? []
    }
    return myCheckoutQuery?.me?.checkout?.lines ?? []
  }, [
    showLogin,
    user,
    myCheckoutQuery?.me?.checkout?.lines,
    anonCheckoutQuery?.checkout?.lines,
  ]) as CheckoutLine[]
  return {
    checkoutReady,
    checkoutLine,
    update: handleUpdate,
    refetch,
    refetchCheckout,
    refetchMyCheckout,
    anonCheckoutQuery,
    myCheckoutQuery,
    myCheckoutLoading,
    anonCheckoutLoading,
  }
}
