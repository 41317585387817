import {SecurityDoc} from '..'
import {ProductContext} from '../utils'

const ATTRIBUTE_SLUG = 'safety-files-'
type FullAttribute = {url: string; slug: string; name: string}

export const resolveSecurityDocs = (context: ProductContext): SecurityDoc[] => {
  const {attributes} = context.product
  if (!attributes || attributes.length === 0) {
    return []
  }
  return attributes
    .filter((item) => item?.attribute?.slug?.startsWith(ATTRIBUTE_SLUG))
    .map(({values}) =>
      values
        .filter((arr) => arr && arr.file && arr.file.url)
        .map((arr) => ({
          url: arr?.file?.url,
          slug: arr?.file?.url,
          name: '',
        }))
        .filter(
          (item): item is FullAttribute =>
            item.url != null && item.slug != null,
        ),
    )
    .filter((arr) => arr && arr.length > 0)
    .flat()
    .map((item, index, arr) => ({
      ...item,
      __typename: 'SecurityDoc' as const,
      id: `${context.product.slug}:securityDoc-${index}`,
      name: `Öryggisskjal ${index + 1} af ${arr.length}`,
      thumbnail: null,
    }))
    .flat()
}
