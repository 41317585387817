const youtubeHosts = ['youtu.be', 'youtube.com']
  .map((e) => [`https://${e}`, `https://www.${e}`])
  .flat()
export const getYoutubeId = (url: string): null | string => {
  const isYoutube = youtubeHosts.some((value) => url.startsWith(value))
  if (!isYoutube) {
    return null
  }
  const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]+).*/
  const match = url.match(regExp)
  return match ? match[1] ?? null : null
}
