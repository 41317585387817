import React from 'react'

import styled from 'styled-components'
import ArrowUpLineIcon from 'remixicon-react/ArrowUpSLineIcon'

import {Button, IconButton} from 'src/components/buttons'
import {theme} from 'src/themes'
import {media} from 'styled-bootstrap-grid'

interface Props {
  currentCount: number
  totalCount: number
  onClickMore?: () => void
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 30px;
`

const ContentContainer = styled.div`
  width: calc(100% - 32px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100% !important;
  }
`

const Label = styled.span`
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
`

const ScrollButtonWrapper = styled.div`
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 400;

  ${media.md`
    right: 40px;
    bottom: 40px;
  `}
`

const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export default function PaginationIndicator({
  currentCount,
  totalCount,
  onClickMore,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <ContentContainer>
        <Label>{`Þú hefur séð ${currentCount} af ${totalCount}`}</Label>
        <Button
          outlined
          disabled={currentCount === totalCount}
          onClick={onClickMore}
          withGutter
        >
          Sjá fleiri vörur
        </Button>
        <ScrollButtonWrapper>
          <IconButton
            outlined
            onClick={scrollToTop}
            icon={<ArrowUpLineIcon size={28} color={theme.palette.secondary} />}
          />
        </ScrollButtonWrapper>
      </ContentContainer>
    </Wrapper>
  )
}
