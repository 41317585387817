import React, {useMemo} from 'react'
import type {VideoBlockFields} from 'src/api/datocms/types/VideoBlockFields'
import type {VideoListFields_videos} from 'src/api/datocms/types/VideoListFields'

import styled from 'styled-components'
import {P, Title5} from '../typography'

const IFrameWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const VideoBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 40px;
`

const TitleBlock = styled.div`
  margin-bottom: 16px;
`

export default function Video({
  title,
  description,
  video,
}: VideoBlockFields | VideoListFields_videos): JSX.Element | null {
  const provider = video?.provider
  const providerUid = video?.providerUid
  const src = useMemo(() => {
    switch (provider) {
      case 'youtube':
        return `https://youtube.com/embed/${providerUid}`
      case 'vimeo':
        return `https://player.vimeo.com/video/${providerUid}`
      default:
        return ''
    }
  }, [provider, providerUid])

  if (!video) {
    return null
  }

  return (
    <VideoBlockContainer>
      <TitleBlock>
        {title && <Title5>{title}</Title5>}
        {description && <P>{description}</P>}
      </TitleBlock>
      <IFrameWrapper>
        <IFrame title={title ?? ''} src={src} frameBorder="0" allowFullScreen />
      </IFrameWrapper>
    </VideoBlockContainer>
  )
}
