import React from 'react'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon'
import styled from 'styled-components'
import {ifProp} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import {buttonStyles, outlinedButtonStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  withGutter?: boolean
  fill?: boolean
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red'
  outlined?: boolean
  direction?: 'right' | 'left'
}

interface BtnWrapperProps {
  withGutter: boolean
}

const Btn = styled.button<Props>`
  ${(p) =>
    p.outlined
      ? outlinedButtonStyles({color: p.color})
      : buttonStyles({color: p.color})}
  width: inherit;
  min-width: 90px;
  justify-content: center;

  svg {
    margin: 0;
  }

  ${media.sm`
    min-width: 90px;
  `}
`

const BtnWrapper = styled.div<BtnWrapperProps>`
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0px')};
`

export default function ArrowButton({
  disabled,
  withGutter = false,
  fill = false,
  color = 'secondary',
  outlined = false,
  direction = 'right',
  ...props
}: Props): JSX.Element {
  return (
    <BtnWrapper withGutter={withGutter}>
      <Btn
        disabled={disabled}
        fill={fill ? true : undefined}
        color={color}
        outlined={outlined}
        {...props}
      >
        {direction === 'right' ? <ArrowRightLineIcon /> : <ArrowLeftLineIcon />}
      </Btn>
    </BtnWrapper>
  )
}
