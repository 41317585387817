import React from 'react'
import type {
  RenderRule,
  TrasformFn,
} from 'react-datocms/node_modules/datocms-structured-text-utils'
import {
  isHeading,
  isList,
  isListItem,
  isParagraph,
} from 'react-datocms/node_modules/datocms-structured-text-utils'
import type {
  StructuredTextPropTypes,
  StructuredTextGraphQlResponseRecord,
} from 'react-datocms'
import {StructuredText, renderNodeRule} from 'react-datocms'
import Image from 'next/image'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'
import type {Maybe} from 'src/utils/Maybe'
import {H1, H2, H3, H4, H5, H6, P} from '../typography'

interface Props<
  R1 extends StructuredTextGraphQlResponseRecord,
  R2 extends StructuredTextGraphQlResponseRecord = R1
> {
  content: Maybe<StructuredTextPropTypes<R1, R2>['data']>
}

const ImageBlockContainer = styled.div`
  margin-bottom: 40px;
  display: inline-block;

  img {
    width: 100%;
  }
`

const LI = styled.li`
  ${P} {
    margin-bottom: 0;
  }
`

const UL = styled.ul`
  margin-bottom: 32px;

  ${LI} {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    &::before {
      content: '-';
      color: ${palette('red')};
      margin-right: 10px;
    }

    ${P} {
      padding-bottom: 0;
    }
  }
`

const OL = styled.ul`
  margin-bottom: 32px;

  ${LI} {
    list-style-type: decimal;
    margin-left: 15px;
    color: ${prop('color')};
  }
`

type Rule = RenderRule<TrasformFn, TrasformFn, TrasformFn>[]

const renderRules = ((): Rule => [
  renderNodeRule(isHeading, ({node, children, key}) => {
    const Heading = [H1, H2, H3, H4, H5, H6][node.level - 1]
    return (
      <Heading key={key} withGutter>
        {children}
      </Heading>
    )
  }),
  renderNodeRule(isParagraph, ({children, key}) => (
    <P withGutter key={key}>
      {children}
    </P>
  )),
  renderNodeRule(isList, ({node, children, key}) => {
    const List = node.style === 'bulleted' ? UL : OL
    return <List key={key}>{children}</List>
  }),
  renderNodeRule(isListItem, ({children, key}) => (
    <LI key={key}>{children}</LI>
  )),
])()

interface RenderBlockProps<X extends StructuredTextGraphQlResponseRecord> {
  record: X
}

interface ImageProp {
  __typename: 'ImageBlockRecord'
  [key: string]: unknown
  id: string
  image: {
    responsiveImage: Maybe<{
      src: Maybe<string>
      webSrcSet: Maybe<string>
    }>
  }
}

type RenderImageProps = RenderBlockProps<ImageProp>

const valueIsRenderImageProps = (value: unknown): value is RenderImageProps =>
  typeof value === 'object' &&
  value !== null &&
  'record' in value &&
  '__typename' in value &&
  (value as RenderImageProps).record.__typename === 'ImageBlockRecord'

export default function DatoStructuredText<
  R1 extends StructuredTextGraphQlResponseRecord,
  R2 extends StructuredTextGraphQlResponseRecord = R1
>({content}: Props<R1, R2>): JSX.Element | null {
  if (content == null) {
    return null
  }
  return (
    <StructuredText
      data={content}
      customNodeRules={renderRules}
      renderBlock={(
        value: RenderImageProps | {record: {__typename: string}},
      ) => {
        if (valueIsRenderImageProps(value)) {
          const image =
            (value.record.image?.responsiveImage?.webSrcSet ||
              value.record.image?.responsiveImage?.src) ??
            null
          return (
            <ImageBlockContainer>
              {image ? <Image src={image} /> : null}
            </ImageBlockContainer>
          )
        }
        return null
      }}
    />
  )
}
