import React, {useState} from 'react'

import type {RemixiconReactIconProps} from 'remixicon-react'
import type {Maybe} from 'src/utils/Maybe'
import styled from 'styled-components'

interface Props extends RemixiconReactIconProps {
  icon: Maybe<string>
}

// Set color of icon as 'red'
const SVGImage = styled.img`
  filter: invert(45%) sepia(75%) saturate(5668%) hue-rotate(332deg)
    brightness(100%) contrast(88%);
`

export default function DynamicIcon({icon, size}: Props): JSX.Element | null {
  const [src, setSrc] = useState<string | null>(`/icons/${icon}.svg`)
  if (src == null || icon == null) {
    return null
  }
  return (
    <SVGImage
      src={src}
      onError={() => setSrc(null)}
      height={size}
      width={size}
    />
  )
}
