import React, {useRef} from 'react'
import {Transition} from 'react-transition-group'
import {useId} from 'src/hooks/useId'

interface Props {
  expanded?: boolean
  children: React.ReactNode
}

const defaultStyle = {
  overflow: 'hidden',
  height: 0,
  transition: 'height 0.24s ease-in-out',
}

export default function Expandable({expanded, children}: Props): JSX.Element {
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null)
  const key = useId()
  return (
    <Transition
      nodeRef={contentRef}
      in={expanded}
      timeout={{enter: 240, exit: 0}}
    >
      {(state) => {
        const targetHeight = contentRef.current?.clientHeight
        const heightMap = {
          entering: targetHeight,
          entered: 'auto',
          exiting: targetHeight,
          exited: 0,
          unmounted: 0,
        }

        const overFlowMap = {
          entering: 'hidden',
          entered: 'initial',
          exiting: 'initial',
          exited: 'hidden',
          unmounted: 'hidden',
        }

        return (
          <div
            key={key}
            style={{
              ...defaultStyle,
              height: heightMap[state],
              overflow: overFlowMap[state],
            }}
          >
            <div ref={contentRef}>{children}</div>
          </div>
        )
      }}
    </Transition>
  )
}
