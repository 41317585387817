import styled, {css} from 'styled-components'
import {ifProp} from 'styled-tools'
import {fontSize} from '../../utils/styles'

export interface HeaderProps {
  uppercase?: boolean
  withGutter?: boolean
  top?: number
  bottom?: number
  left?: number
  right?: number
  textAlign?: 'center' | 'right' | 'left'
}

export const headingsCommonStyle = css<HeaderProps>`
  font-family: 'LetterboardLite';
  text-transform: ${ifProp('uppercase', 'uppercase', 'none')};
  letter-spacing: 0.03em;
  text-align: ${ifProp('textAlign', (p) => p.textAlign, 'inherit')};
  margin-top: ${(p) => (p.top ? `${p.top}px` : '0px')};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : '0px')};
  margin-left: ${(p) => (p.left ? `${p.left}px` : '0px')};
  margin-right: ${(p) => (p.right ? `${p.right}px` : '0px')};

  u {
    text-decoration: inherit;
  }
  i {
    font-style: inherit;
  }
  b,
  strong {
    font-weight: inherit;
  }
`

export const H1 = styled.h1<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp('withGutter', '32px', 0)};
  ${fontSize(2.5, 3.5)}
  ${headingsCommonStyle}
`

export const H2 = styled.h2<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp({withGutter: true}, '32px', '0')};
  ${fontSize(2, 3)}
  ${headingsCommonStyle}
`

export const H3 = styled.h3<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0')};
  ${fontSize(1.5, 2.5)}
  ${headingsCommonStyle}
`

export const H4 = styled.h4<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0')};
  ${fontSize(1.75, 2.25)}
  ${headingsCommonStyle}
`

export const H5 = styled.h5<HeaderProps>`
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${fontSize(1.5, 1.75)}
  ${headingsCommonStyle}
`

export const H6 = styled.h6<HeaderProps>`
  font-weight: 600;
  line-height: 1.25;
  padding-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  font-size: 1.25rem;
  ${headingsCommonStyle}
`
