const baseUrl = '/'
const checkoutBaseUrl = `${baseUrl}kaup/`
const accountBaseUrl = `${baseUrl}adgangur/`

export const paths = {
  home: baseUrl,
  cart: `${baseUrl}karfa`,
  product: `${baseUrl}vara`,
  categories: `${baseUrl}vefverslun`,
  productCollection: `${baseUrl}vorusafn`,
  about: `${baseUrl}um-rv`,
  search: `${baseUrl}leit?q=`,
  shoppingList: `${baseUrl}oskalistar`,
  /**
   * Checkout
   */
  checkout: `${checkoutBaseUrl}`,
  delivery: `${checkoutBaseUrl}afhending/`,
  payment: `${checkoutBaseUrl}greidsla/`,
  order: `${checkoutBaseUrl}pontun/`,
  /**
   * Account
   */
  account: `${accountBaseUrl}`,
  myInfo: `${accountBaseUrl}minar-upplysingar/`,
  myOrders: `${accountBaseUrl}reikningar/`,
  salesHistory: `${accountBaseUrl}solusaga/`,
  myTransactions: `${accountBaseUrl}hreyfingayfirlit/`,
  myShoppingList: `${accountBaseUrl}oskalistar/`,
  companyRequest: `${accountBaseUrl}fyrirtaekjabeidni/`,
  login: `${baseUrl}innskraning`,
}

export const generatePath = (
  basePath: string,
  slug?: string,
  params?: string,
): string => `${basePath}${slug ? `/${slug}` : ''}${params ? `?${params}` : ''}`
