/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react'
import type {FetchResult} from '@apollo/client/link/core/types'
import {getCartQuantityMap} from 'src/utils/checkout'
import type {PrepareOrder} from 'src/api/rest'
import {handleRestResponse, restApi} from 'src/api/rest'
import type {Maybe} from 'src/utils/Maybe'
import type {
  AddressInput,
  PaymentInput,
  CheckoutPaymentCreate,
  CheckoutComplete,
  CheckoutAddressesUpdateMutation,
  CheckoutShippingMethodUpdateMutation,
  CheckoutFragment,
  CheckoutLine,
} from '../../api/saleor/generated'
import {
  useCheckoutPaymentCreateMutation,
  useCheckoutCompleteMutation,
  useCheckoutEmailUpdateMutation,
  useCheckoutAddressesUpdateMutation,
  useCheckoutVoucherUpdateMutation,
  useCheckoutVoucherRemoveMutation,
} from '../../api/saleor/generated'

import {handleCheckoutError, useAnonymousToken} from '.'
import {useClearCheckout} from './useClearCheckout'
import {useUpdateShippingMethod} from './useUpdateShippingMethod'
import {useUpdateStore} from './useUpdateStore'
import {useUser} from '../UserContext'
import {useGetCheckout} from './useGetCheckout'

const droppMethodId = process?.env?.NEXT_PUBLIC_DROP_SHIPPING_METHOD_ID || ''
const droppDelMethodId = process?.env?.NEXT_PUBLIC_DROP_DELIVERY_METHOD_ID || ''

export const CheckoutContext = createContext<CheckoutContextProps | null>(null)

interface CheckoutProviderProps {
  children: React.ReactNode
}

export function CheckoutProvider({
  children,
}: CheckoutProviderProps): JSX.Element {
  const {
    checkoutReady,
    checkoutLine,
    anonCheckoutQuery,
    myCheckoutQuery,
    myCheckoutLoading,
    anonCheckoutLoading,
    refetchCheckout,
    refetchMyCheckout,
    refetch,
  } = useGetCheckout()

  const [locationId, setLocationId] = useState<string | null>('')

  const [extLocationId, setExtLocationId] = useState<string | null>('')

  const updateLocationId = useCallback((location:any): void => {
    setLocationId(location.id)
    setExtLocationId(location.externalLocationId)
  },
    [],
  )

  const {update} = useUpdateStore({refetch, refetchCheckout, refetchMyCheckout})
  const {user, showLogin} = useUser()
  const {
    token: anonymousToken,
    remove: removeAnonymousToken,
  } = useAnonymousToken()

  const handleUpdateShippingMethod = useUpdateShippingMethod({
    refetch,
    myCheckoutQuery,
  })

  const [checkoutAddressesUpdateMutation] = useCheckoutAddressesUpdateMutation({
    onCompleted: () => refetch(),
  })

  const [checkoutEmailUpdate] = useCheckoutEmailUpdateMutation()
  const [checkoutVoucherUpdate] = useCheckoutVoucherUpdateMutation()
  const [checkoutVoucherRemove] = useCheckoutVoucherRemoveMutation()
  const [checkoutPaymentCreate] = useCheckoutPaymentCreateMutation()
  const [checkoutComplete] = useCheckoutCompleteMutation()

  const checkoutToken =
    anonCheckoutQuery?.checkout?.token ||
    myCheckoutQuery?.me?.checkout?.token ||
    undefined

  const updateCheckoutAddresses = useCallback(
    (shippingAddress: AddressInput, billingAddress: AddressInput) =>
      checkoutAddressesUpdateMutation({
        variables: {
          token: checkoutToken,
          shippingAddress,
          billingAddress,
        },
      }).then((res) => res),
    [checkoutAddressesUpdateMutation, checkoutToken],
  )

  const updateCheckoutVoucher = useCallback(
    (voucherCode: string): Promise<void> =>
      checkoutVoucherUpdate({
        variables: {
          token: checkoutToken,
          voucher: voucherCode,
        },
      }).then((res) => {
        if (!res?.data?.checkoutAddPromoCode?.errors) {
          return Promise.resolve()
        }
        handleCheckoutError(res?.data?.checkoutAddPromoCode?.errors)
        return Promise.resolve()
      }),
    [checkoutToken, checkoutVoucherUpdate],
  )

  const removeCheckoutVoucher = useCallback(
    (voucherCode: string) =>
      checkoutVoucherRemove({
        variables: {
          token: checkoutToken,
          voucher: voucherCode,
        },
      }).then((res) => {
        handleCheckoutError(res?.data?.checkoutRemovePromoCode?.errors)
      }),
    [checkoutToken, checkoutVoucherRemove],
  )

  const updateCheckoutEmail = useCallback(
    (email: string) =>
      checkoutEmailUpdate({
        variables: {
          email,
          token: checkoutToken,
        },
      }).then((res) => {
        handleCheckoutError(res?.data?.checkoutEmailUpdate?.errors)
      }),

    [checkoutEmailUpdate, checkoutToken],
  )

  const prepareOrderCreate = useCallback(
    (checkoutNote: string): Promise<PrepareOrder> => {
      const customerNote = JSON.parse(checkoutNote)
      return handleRestResponse(
        restApi
          .checkoutsPrepareOrderCreate(checkoutToken, {
            note: customerNote.note,
            method: customerNote.method,
          })
          .then((res) => res),
      )},
    [checkoutToken],
  )


  const prepareDroppOrderCreate = useCallback(
    (location: string): Promise<PrepareOrder> => {
      const dropplocation = JSON.parse(location)
      return handleRestResponse(
        restApi
          .checkoutPrepareDroppOrderCreate(checkoutToken, {
            location: dropplocation.location || '',
          })
          .then((res) => res),
      )},
    [checkoutToken],
  )

  const createPayment = useCallback(
    (input: PaymentInput) =>
      checkoutPaymentCreate({
        variables: {
          token: checkoutToken,
          input,
        },
      }).then(
        (res) => res?.data?.checkoutPaymentCreate as CheckoutPaymentCreate,
      ),
    [checkoutPaymentCreate, checkoutToken],
  )

  const completeCheckout = useCallback(
    () =>
      checkoutComplete({
        variables: {
          token: checkoutToken,
        },
      })
        .then((res) => {
          const shippingMethod = res?.data?.checkoutComplete as CheckoutComplete
          const order = shippingMethod?.order

          const methodId = order?.shippingMethod?.id

          if (
            (methodId === droppDelMethodId || methodId === droppMethodId && locationId)
          ) {
            const payload = {
              locationId:
                methodId === droppDelMethodId
                  ? '9ec1f30c-2564-4b73-8954-25b7b3186ed3'
                  : locationId,
              products: order?.lines,
              value: order?.total.gross.amount,
              customer: {
                name:
                  order?.shippingAddress?.firstName ||
                  order?.shippingAddress?.companyName,
                address: order?.shippingAddress?.streetAddress1,
                phoneNumber: order?.shippingAddress?.phone,
                zipcode: order?.shippingAddress?.postalCode,
                town: order?.shippingAddress?.city,
              },
            }
            restApi.PostDroppOrder(
              shippingMethod?.order?.shippingAddress?.postalCode as string,
              payload,
            )
          }
          return res?.data?.checkoutComplete as CheckoutComplete
        })
        .catch((err) => {
          console.log('err', err)
        }),
    [checkoutComplete, checkoutToken, locationId],
  )

  // Moves the anonymous cart (if it exists) to a user cart and removes the anonymous cart
  const moveAnonCartToUserCart = useCallback(async () => {
    const anonCartState = getCartQuantityMap(
      (await refetchCheckout())?.data.checkout,
    )
    // Delete token
    removeAnonymousToken()
    await update(anonCartState)
  }, [refetchCheckout, removeAnonymousToken, update])

  useEffect(() => {
    if (!!user && anonymousToken) {
      moveAnonCartToUserCart()
    }
  }, [anonymousToken, moveAnonCartToUserCart, user])

  const getVariant = useCallback(
    (variantId: string): CheckoutLine | null => {
      const value = checkoutLine.find((item) => item.variant.id === variantId)
      return value ?? null
    },
    [checkoutLine],
  )
  const isCheckoutLoading = useMemo(() => {
    if (!showLogin) {
      return true
    }
    if (user) {
      return myCheckoutLoading
    }
    return anonCheckoutLoading
  }, [anonCheckoutLoading, myCheckoutLoading, showLogin, user])

  const checkout = useMemo(() => {
    if (showLogin && user) {
      return myCheckoutQuery?.me?.checkout ?? undefined
    }
    return anonCheckoutQuery?.checkout ?? undefined
  }, [
    anonCheckoutQuery?.checkout,
    myCheckoutQuery?.me?.checkout,
    showLogin,
    user,
  ])

  const token = useMemo(() => {
    if (showLogin && user) {
      return myCheckoutQuery?.me?.checkout?.token ?? undefined
    }
    return anonCheckoutQuery?.checkout?.token ?? undefined
  }, [
    anonCheckoutQuery?.checkout,
    myCheckoutQuery?.me?.checkout,
    showLogin,
    user,
  ])

  const handleClearCart = useClearCheckout({
    token,
    checkout,
    refetch,
  })

  const value = useMemo(
    () => ({
      checkoutReady,
      getVariant,
      checkoutLine,
      cartUpdate: update,
      clearCart: handleClearCart,
      updateShippingMethod: handleUpdateShippingMethod,
      updateCheckoutAddresses,
      updateCheckoutEmail,
      updateCheckoutVoucher,
      removeCheckoutVoucher,
      prepareOrderCreate,
      prepareDroppOrderCreate,
      createPayment,
      completeCheckout,
      checkout,
      checkoutLoading: isCheckoutLoading,
      locationId,
      extLocationId,
      updateLocationId,
    }),
    [
      checkoutReady,
      getVariant,
      checkoutLine,
      update,
      handleClearCart,
      handleUpdateShippingMethod,
      updateCheckoutAddresses,
      updateCheckoutEmail,
      updateCheckoutVoucher,
      removeCheckoutVoucher,
      prepareOrderCreate,
      prepareDroppOrderCreate,
      createPayment,
      completeCheckout,
      checkout,
      isCheckoutLoading,
      locationId,
      extLocationId,
      updateLocationId,
    ],
  )

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  )
}

interface CheckoutContextProps {
  checkoutReady: boolean
  getVariant: (id: string) => CheckoutLine | null
  checkoutLine: CheckoutLine[]
  cartUpdate: (state: Record<string, number>) => Promise<void>
  clearCart: () => void
  updateShippingMethod: (
    id: string,
  ) => Promise<
    FetchResult<
      CheckoutShippingMethodUpdateMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >
  updateCheckoutAddresses: (
    shippingAddress: AddressInput,
    billingAddress: AddressInput,
  ) => Promise<
    FetchResult<
      CheckoutAddressesUpdateMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >
  updateCheckoutEmail: (email: string) => Promise<void>
  updateCheckoutVoucher: (voucherCode: string) => Promise<void>
  removeCheckoutVoucher: (voucherCode: string) => Promise<void>
  prepareOrderCreate: (checkoutNote: string) => Promise<PrepareOrder>
  prepareDroppOrderCreate: (location: string) => Promise<PrepareOrder>
  createPayment: (input: PaymentInput) => Promise<CheckoutPaymentCreate>
  completeCheckout: () => Promise<CheckoutComplete> | any
  checkout: Maybe<CheckoutFragment>
  checkoutLoading: boolean
  locationId: string | null
  extLocationId: string | null
  updateLocationId: (newLocationId: string) => void
}

export function useCheckout(): CheckoutContextProps {
  const value = useContext(CheckoutContext)
  if (value == null) {
    throw new Error('Should be used inside provider')
  }
  return value
}
