import {getYoutubeId} from 'src/utils/getYoutubeId'
import {isNotNull} from 'src/utils/isNotNull'
import {Media, Video, Image} from '..'
import {ProductContext} from '../utils'

export const resolveMedia = (context: ProductContext): Media[] => {
  const media = context.product.media ?? []
  return media
    .filter(isNotNull)
    .map((item) => {
      if (item.type === 'VIDEO') {
        const youtubeId = getYoutubeId(item.url)
        if (youtubeId == null) {
          return null
        }
        return {
          __typename: 'Video' as const,
          id: item.id,
          type: 'video' as const,
          youtubeID: youtubeId,
          embedURL: `https://youtube.com/embed/${youtubeId}`,
          thumbnail: {
            __typename: 'Image' as const,
            type: 'image' as const,
            id: `${item.id}:thumbnail`,
            url: `https://i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg`,
          },
        } as Video
      }
      return {
        __typename: 'Image' as const,
        type: 'image' as const,
        id: item.id,
        url: item.url,
      } as Image
    })
    .filter(isNotNull)
    .sort((a, b) => {
      const bValue = b.type === 'video' ? 0 : 1
      const aValue = a.type === 'video' ? 0 : 1
      return bValue - aValue
    }) as Media[]
}
