import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
  DefaultOptions,
} from '@apollo/client'
import urljoin from 'url-join'

import { isSSR } from 'src/utils/isSSR'

const cache = new InMemoryCache()

const apiUrl =
  process.env.NEXT_PUBLIC_DATOCMS_API_URL || 'https://graphql.datocms.com/'

const apiToken = process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN || ''

const commonConf = {
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
}

const serverOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const link = createHttpLink({
  uri: apiUrl,
  ...commonConf,
})

export const client = new ApolloClient({
  cache,
  link,
})

const previewLink = createHttpLink({
  uri: urljoin(apiUrl, 'preview'),
  ...commonConf,
})

export const previewClient = new ApolloClient({
  cache,
  link: previewLink,
  defaultOptions: isSSR ? serverOptions : undefined,
})

export function datocms(
  preview?: boolean,
): ApolloClient<NormalizedCacheObject> {
  return preview ? previewClient : client
}
