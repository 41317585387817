import {Product} from 'src/api/saleor/generated'
import {isNotNull} from 'src/utils/isNotNull'
import {generatePath, paths} from 'src/utils/paths'
import {Breadcrumb} from '../generated/hooks'
import {ProductContext} from '../utils'
import capitalize from 'lodash/capitalize'

const catTitle = (categoryName: string): string => capitalize(categoryName)

const catLinks = (
  slug: string,
  category: Product['category'],
): {id: string; title: string; href: string}[] => {
  if (!category) {
    return []
  }
  return [
    ...catLinks(slug, category.parent),
    {
      __typename: 'Breadcrumb',
      id: `${slug}:${category.slug}`,
      title: catTitle(category.name),
      href: generatePath(paths.categories, category.slug),
    },
  ].filter(Boolean)
}

export const resolveBreadcrumbs = ({product}: ProductContext): Breadcrumb[] => {
  const first = {
    __typename: 'Breadcrumb',
    id: `${product.slug}:breadcrumb:home`,
    title: 'Forsíða',
    href: paths.home,
  }
  const last = {
    __typename: 'Breadcrumb',
    id: `${product.slug}:breadcrumb:main`,
    title: product.name,
    href: generatePath(paths.product, product.slug),
  }
  const menu = [
    first,
    ...catLinks(product.slug, product.category),
    last,
  ].filter(isNotNull)
  return menu
}
