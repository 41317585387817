export const ProductTypeBox = 'KS' as const
export type ProductTypeBox = typeof ProductTypeBox
export const ProductTypePiece = 'STK' as const
export type ProductTypePiece = typeof ProductTypePiece
export const ProductTypeBottle = 'BR' as const
export type ProductTypeBottle = typeof ProductTypeBottle

export const ProductType = [ProductTypePiece, ProductTypeBox, ProductTypeBottle]
export type ProductType = ProductTypeBox | ProductTypePiece | ProductTypeBottle
export type ProductApiType = 'STK' | 'KS' | 'BR' | string
export interface AvailableProductType {
  key: ProductType
  price: {
    gross: number
    net: number
    originalPrice?: {
      net: number
      gross: number
      diffNet: number
      diffGross: number
    } | null
  }
  quantityPerUnit: number
  minQuantity: number
  name: {
    singular: string
    plural: string
  }
}
export type ProductTypeWithType = AvailableProductTypes & {type: ProductType}
export type AvailableProductTypes = Partial<
  Record<ProductType, AvailableProductType>
>
export const UNIT_TYPES_KEY = 'unit_types'
export const DEFAULT_UNIT_TYPE_KEY = 'base_unit_type'
export interface UnitType {
  id: string | null
  price: {
    net: string
    gross: string
    discount?: {
      net: string
      gross: string
    }
  }
  qty_per_unit: string
  min_qty: string
  name_singular: string
  name_plural: string
}
export type UnitTypes = Record<ProductApiType, UnitType>
