/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable max-classes-per-file */
import type {
  AddressInput,
  Address,
  CheckoutFragment,
} from 'src/api/saleor/generated'
import {CountryCode} from 'src/api/saleor/generated'
import type {Dictionary} from 'lodash'
import {fromPairs} from 'lodash'
import type {Company} from 'src/api/rest'
import {ensureList} from './typing'
import {toGlobalId} from './graphql'
import type {Maybe} from './Maybe'
import {isNotNull, isNotNullKey} from './isNotNull'

type Tuple = [string, number]

export function getCartQuantityMap(
  cart?: Maybe<CheckoutFragment>,
  variantIds?: string[],
): Dictionary<number> {
  let pairs = ensureList(cart?.lines).map<Tuple>((line) => [
    line.variant.id,
    line.quantity,
  ])

  if (variantIds != null) {
    pairs = pairs.filter(([id]) => variantIds.includes(id))
  }

  return fromPairs(pairs)
}

// TODO: Test
export function toAddressInput(address: Address): AddressInput {
  return {
    firstName: address.firstName,
    lastName: address.lastName,
    companyName: address.companyName,
    streetAddress1: address.streetAddress1,
    streetAddress2: address.streetAddress2,
    city: address.city,
    cityArea: address.cityArea,
    postalCode: address.postalCode,
    country: CountryCode.Is as CountryCode,
    countryArea: address.countryArea,
    phone: address.phone,
  }
}

// TODO: Test
export function mapCompanyAddresses(company: Company): Address[] {
  return (company?.addresses || [])
    .filter(isNotNull)
    .filter(isNotNullKey('id'))
    .map((address) => ({
      id: toGlobalId('Address', address.id),
      firstName: address.name ?? '',
      lastName: company.ssn,
      companyName: address.companyName ?? '',
      streetAddress1: address.streetAddress1 ?? '',
      streetAddress2: address.streetAddress2 ?? '',
      city: address.city ?? '',
      cityArea: address.cityArea ?? '',
      postalCode: address.postalCode ?? '',
      country: {code: CountryCode.Is, country: 'Iceland'},
      countryArea: '',
      phone: address.phone,
    }))
}

// TODO: Test
// TODO: Is there a better method for this?
export function isSameAddress(
  savedAddress?: Maybe<Address>,
  address?: Maybe<Address>,
): boolean {
  if (!savedAddress) {
    return false
  }
  return (
    savedAddress?.lastName === address?.lastName &&
    savedAddress?.companyName === address?.companyName &&
    savedAddress?.streetAddress1 === address?.streetAddress1 &&
    savedAddress?.phone === address?.phone
  )
}

// TODO: Test
export function AddressExists(
  addresses?: Address[] | undefined | null,
  savedAddress?: Maybe<Address>,
): Address | null {
  if (!savedAddress) {
    return null
  }
  return addresses?.find((a) => isSameAddress(a, savedAddress)) ?? null
}

class BoxAllocationError extends Error {}
class BoxSizesEmpty extends BoxAllocationError {}

export function allocateItemsToBoxes(
  total_items: number,
  box_sizes: [string, number][],
): Record<string, number> {
  if (total_items === 0) {
    return {}
  }

  const next_box_size = box_sizes[0]
  if (next_box_size == null) {
    throw new BoxSizesEmpty()
  }
  const [box_size_id, box_size] = next_box_size
  const max_boxes = Math.floor(total_items / box_size)
  const box_sizes_rem = box_sizes.slice(1)
  for (let boxes = max_boxes; boxes > 0; --boxes) {
    const total_items_rem = total_items - boxes * box_size
    let solution: Record<string, number>
    try {
      solution = allocateItemsToBoxes(total_items_rem, box_sizes_rem)
    } catch (e) {
      if (e instanceof BoxAllocationError) {
        continue
      }
      throw e
    }

    solution[box_size_id] = boxes
    return solution
  }
  if (box_sizes.length > 0) {
    return allocateItemsToBoxes(total_items, box_sizes_rem)
  }
  throw new BoxAllocationError()
}
