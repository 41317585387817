const gridTheme = {
  gridColumns: 12,
  maxSize: 1870,
  breakpoints: {
    xxl: 1921,
    xl: 1920,
    lg: 1440,
    md: 1024,
    sm: 600,
    xs: 599,
  },
  row: {
    padding: 10,
  },
  col: {
    padding: 10,
  },
  container: {
    padding: 20,
    maxWidth: {
      xxl: 1660,
      xl: 1660,
      lg: 1440,
      md: 1440,
      sm: 1440,
      xs: 600,
    },
  },
}

export default gridTheme
