import {ProductContext} from '../utils'

const ATTRIBUTE_SLUG = 'hide-price'

export const resolveHidePrice = ({
  product,
}: ProductContext): boolean | null => {

  const priceAttribute = product.attributes.find(
    ({attribute}) => attribute.slug === 'hide-price',
  )

  if (
    priceAttribute &&
    priceAttribute.values.length &&
    priceAttribute.values[0]?.slug
  ) {
    return priceAttribute.values[0]?.slug.includes('true')
  }
  return false
}
