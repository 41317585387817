import {filter, isArray} from 'lodash'

/**
 * Always returns a list with `null` and `undefined` values removed.
 *
 * ```typescript
 * ensureList() // => []
 * ensureList(null) // => []
 * ensureList([null, undefined, true, false, '', 0]) // => [true, false, '', 0]
 * ```
 */
export function ensureList<T>(arg?: T | Array<T> | null): NonNullable<T>[] {
  const list = isArray(arg) ? arg : [arg]
  return filter(list, (x) => x != null) as NonNullable<T>[]
}
