/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Category,
  Checkout,
  CloneShoppingListRequest,
  Company,
  CompanyAddress,
  CompanyAddressRequest,
  CompanyRequest,
  CompanyUser,
  CompanyUserRequest,
  CompanyUserRequestRequest,
  EmailChangeRequest,
  Login,
  LoginRequest,
  Order,
  PaginatedCheckoutList,
  PaginatedCompanyList,
  PaginatedCompanyUserList,
  PaginatedCompanyUserRequestList,
  PaginatedOrderList,
  PaginatedProductListList,
  PatchedCompanyAddressRequest,
  PatchedCompanyRequest,
  PatchedCompanyUserRequest,
  PatchedCompanyUserRequestRequest,
  PatchedShoppingListItemRequest,
  PatchedShoppingListRequest,
  PatchedUserRequest,
  PrepareOrder,
  PrepareOrderRequest,
  ProductDetail,
  ProductType,
  RequestEmailChangeRequest,
  ShoppingList,
  ShoppingListItem,
  ShoppingListItemWrite,
  ShoppingListItemWriteRequest,
  ShoppingListRequest,
  TokenCreate,
  TokenCreateRequest,
  User,
  UserRequest,
  VirtualCard,
  VirtualCardRequest,
  Warehouse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags attribute-names
   * @name AttributeNamesRetrieve
   * @request GET:/api/attribute-names/
   * @secure
   */
  attributeNamesRetrieve = (query?: { slugs?: string }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/attribute-names/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesList
   * @request GET:/api/categories/
   * @secure
   */
  categoriesList = (params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/categories/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesRetrieve
   * @request GET:/api/categories/{id}/
   * @secure
   */
  categoriesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Category, any>({
      path: `/api/categories/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsList
   * @request GET:/api/checkouts/
   * @secure
   */
  checkoutsList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedCheckoutList, any>({
      path: `/api/checkouts/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsRetrieve
   * @request GET:/api/checkouts/{token}/
   * @secure
   */
  checkoutsRetrieve = (token: string, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsUpdate
   * @request PUT:/api/checkouts/{token}/
   * @secure
   */
  checkoutsUpdate = (token: string, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsPartialUpdate
   * @request PATCH:/api/checkouts/{token}/
   * @secure
   */
  checkoutsPartialUpdate = (token: string, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "PATCH",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsPrepareOrderCreate
   * @request POST:/api/checkouts/{token}/prepare-order/
   * @secure
   */
  checkoutsPrepareOrderCreate = (token: string, data: PrepareOrderRequest, params: RequestParams = {}) =>
    this.request<PrepareOrder, any>({
      path: `/api/checkouts/${token}/prepare-order/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsPrepareDroppOrderCreate
   * @request POST:/api/checkouts/{token}/prepare-order/
   * @secure
   */
  checkoutPrepareDroppOrderCreate = (token: string, data: PrepareOrderRequest, params: RequestParams = {}) =>
    this.request<PrepareOrder, any>({
      path: `/api/checkouts/${token}/prepare-dropp-order/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesList
   * @request GET:/api/companies/
   * @secure
   */
  companiesList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedCompanyList, any>({
      path: `/api/companies/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesCreate
   * @request POST:/api/companies/
   * @secure
   */
  companiesCreate = (data: CompanyRequest, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/companies/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesRetrieve
   * @request GET:/api/companies/{id}/
   * @secure
   */
  companiesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/companies/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesUpdate
   * @request PUT:/api/companies/{id}/
   * @secure
   */
  companiesUpdate = (id: number, data: CompanyRequest, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/companies/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesPartialUpdate
   * @request PATCH:/api/companies/{id}/
   * @secure
   */
  companiesPartialUpdate = (id: number, data: PatchedCompanyRequest, params: RequestParams = {}) =>
    this.request<Company, any>({
      path: `/api/companies/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags companies
   * @name CompaniesDestroy
   * @request DELETE:/api/companies/{id}/
   * @secure
   */
  companiesDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/companies/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags company-addresses
   * @name CompanyAddressesList
   * @request GET:/api/company-addresses/
   * @secure
   */
  companyAddressesList = (params: RequestParams = {}) =>
    this.request<CompanyAddress[], any>({
      path: `/api/company-addresses/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-addresses
   * @name CompanyAddressesCreate
   * @request POST:/api/company-addresses/
   * @secure
   */
  companyAddressesCreate = (data: CompanyAddressRequest, params: RequestParams = {}) =>
    this.request<CompanyAddress, any>({
      path: `/api/company-addresses/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-addresses
   * @name CompanyAddressesRetrieve
   * @request GET:/api/company-addresses/{id}/
   * @secure
   */
  companyAddressesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CompanyAddress, any>({
      path: `/api/company-addresses/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Clone the address and disassociate the original address from the company
   *
   * @tags company-addresses
   * @name CompanyAddressesUpdate
   * @request PUT:/api/company-addresses/{id}/
   * @secure
   */
  companyAddressesUpdate = (id: number, data: CompanyAddressRequest, params: RequestParams = {}) =>
    this.request<CompanyAddress, any>({
      path: `/api/company-addresses/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-addresses
   * @name CompanyAddressesPartialUpdate
   * @request PATCH:/api/company-addresses/{id}/
   * @secure
   */
  companyAddressesPartialUpdate = (id: number, data: PatchedCompanyAddressRequest, params: RequestParams = {}) =>
    this.request<CompanyAddress, any>({
      path: `/api/company-addresses/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Disassociate address from any company, but keep in database
   *
   * @tags company-addresses
   * @name CompanyAddressesDestroy
   * @request DELETE:/api/company-addresses/{id}/
   * @secure
   */
  companyAddressesDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/company-addresses/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsList
   * @request GET:/api/company-user-requests/
   * @secure
   */
  companyUserRequestsList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedCompanyUserRequestList, any>({
      path: `/api/company-user-requests/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsCreate
   * @request POST:/api/company-user-requests/
   * @secure
   */
  companyUserRequestsCreate = (data: CompanyUserRequestRequest, params: RequestParams = {}) =>
    this.request<CompanyUserRequest, any>({
      path: `/api/company-user-requests/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsRetrieve
   * @request GET:/api/company-user-requests/{id}/
   * @secure
   */
  companyUserRequestsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CompanyUserRequest, any>({
      path: `/api/company-user-requests/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsUpdate
   * @request PUT:/api/company-user-requests/{id}/
   * @secure
   */
  companyUserRequestsUpdate = (id: number, data: CompanyUserRequestRequest, params: RequestParams = {}) =>
    this.request<CompanyUserRequest, any>({
      path: `/api/company-user-requests/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsPartialUpdate
   * @request PATCH:/api/company-user-requests/{id}/
   * @secure
   */
  companyUserRequestsPartialUpdate = (id: number, data: PatchedCompanyUserRequestRequest, params: RequestParams = {}) =>
    this.request<CompanyUserRequest, any>({
      path: `/api/company-user-requests/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-user-requests
   * @name CompanyUserRequestsDestroy
   * @request DELETE:/api/company-user-requests/{id}/
   * @secure
   */
  companyUserRequestsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/company-user-requests/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersList
   * @request GET:/api/company-users/
   * @secure
   */
  companyUsersList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedCompanyUserList, any>({
      path: `/api/company-users/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersCreate
   * @request POST:/api/company-users/
   * @secure
   */
  companyUsersCreate = (data: CompanyUserRequest, params: RequestParams = {}) =>
    this.request<CompanyUser, any>({
      path: `/api/company-users/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersRetrieve
   * @request GET:/api/company-users/{id}/
   * @secure
   */
  companyUsersRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CompanyUser, any>({
      path: `/api/company-users/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersUpdate
   * @request PUT:/api/company-users/{id}/
   * @secure
   */
  companyUsersUpdate = (id: number, data: CompanyUserRequest, params: RequestParams = {}) =>
    this.request<CompanyUser, any>({
      path: `/api/company-users/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersPartialUpdate
   * @request PATCH:/api/company-users/{id}/
   * @secure
   */
  companyUsersPartialUpdate = (id: number, data: PatchedCompanyUserRequest, params: RequestParams = {}) =>
    this.request<CompanyUser, any>({
      path: `/api/company-users/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags company-users
   * @name CompanyUsersDestroy
   * @request DELETE:/api/company-users/{id}/
   * @secure
   */
  companyUsersDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/company-users/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags orders
   * @name OrdersList
   * @request GET:/api/orders/
   * @secure
   */
  ordersList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedOrderList, any>({
      path: `/api/orders/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags orders
   * @name OrdersRetrieve
   * @request GET:/api/orders/{token}/
   * @secure
   */
  ordersRetrieve = (token: string, params: RequestParams = {}) =>
    this.request<Order, any>({
      path: `/api/orders/${token}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsList
   * @request GET:/api/payments/valitor_pay/cards/
   * @secure
   */
  paymentsValitorPayCardsList = (params: RequestParams = {}) =>
    this.request<VirtualCard[], any>({
      path: `/api/payments/valitor_pay/cards/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsCreate
   * @request POST:/api/payments/valitor_pay/cards/
   * @secure
   */
  paymentsValitorPayCardsCreate = (data: VirtualCardRequest, params: RequestParams = {}) =>
    this.request<VirtualCard, any>({
      path: `/api/payments/valitor_pay/cards/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCardsDestroy
   * @request DELETE:/api/payments/valitor_pay/cards/{id}/
   * @secure
   */
  paymentsValitorPayCardsDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/cards/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCcCallbackRetrieve
   * @request GET:/api/payments/valitor_pay/cc_callback/
   * @secure
   */
  paymentsValitorPayCcCallbackRetrieve = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/cc_callback/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayCcCallbackCreate
   * @request POST:/api/payments/valitor_pay/cc_callback/
   * @secure
   */
  paymentsValitorPayCcCallbackCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/cc_callback/`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayTokensCreate
   * @request POST:/api/payments/valitor_pay/tokens/
   * @secure
   */
  paymentsValitorPayTokensCreate = (data: TokenCreateRequest, params: RequestParams = {}) =>
    this.request<TokenCreate, any>({
      path: `/api/payments/valitor_pay/tokens/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayVccCallbackRetrieve
   * @request GET:/api/payments/valitor_pay/vcc_callback/
   * @secure
   */
  paymentsValitorPayVccCallbackRetrieve = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/vcc_callback/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payments
   * @name PaymentsValitorPayVccCallbackCreate
   * @request POST:/api/payments/valitor_pay/vcc_callback/
   * @secure
   */
  paymentsValitorPayVccCallbackCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/valitor_pay/vcc_callback/`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-types
   * @name ProductTypesList
   * @request GET:/api/product-types/
   * @secure
   */
  productTypesList = (params: RequestParams = {}) =>
    this.request<ProductType[], any>({
      path: `/api/product-types/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-types
   * @name ProductTypesRetrieve
   * @request GET:/api/product-types/{slug}/
   * @secure
   */
  productTypesRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductType, any>({
      path: `/api/product-types/${slug}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsList
   * @request GET:/api/products/
   * @secure
   */
  productsList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      id?: string[];
      ordering?: string;
      page?: number;
      page_size?: number;
      sku?: string[];
      slug?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedProductListList, any>({
      path: `/api/products/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsRetrieve
   * @request GET:/api/products/{slug}/
   * @secure
   */
  productsRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/${slug}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsPricesRetrieve
   * @request GET:/api/products/{slug}/prices/
   * @secure
   */
  productsPricesRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/products/${slug}/prices/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsPricesList
   * @request GET:/api/products/prices/
   * @secure
   */
  productsPricesList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      id?: string[];
      ordering?: string;
      page?: number;
      page_size?: number;
      sku?: string[];
      slug?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<
      { count?: number; next?: string | null; previous?: string | null; results?: Record<string, any>[] },
      any
    >({
      path: `/api/products/prices/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsList
   * @request GET:/api/receipts/
   * @secure
   */
  receiptsList = (query: { ssn: string, nav_id?: string, page_size?: Number, cursor?: string, page?: number, limit?: number, sortby?: string, start_date?: string, end_date?: string }, params: RequestParams = {}) =>
    this.request<object[], any>({
      path: `/api/receipts/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  salesHistoryList = (query: { ssn: string, nav_id?: string, page_size?: Number, cursor?: string, page?: number, limit?: number, sortby?: string, start_date?: string, end_date?: string }, params: RequestParams = {}) =>
    this.request<object[], any>({
      path: `/api/sales-history/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  transactionsList = (query: { ssn: string, nav_id?: string, page_size?: Number, cursor?: string, page?: number, limit?: number, sortby?: string, start_date?: string, end_date?: string, }, params: RequestParams = {}) =>
    this.request<object[], any>({
      path: `/api/transactions-history/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsRetrieve
   * @request GET:/api/receipts/{id}/
   * @secure
   */
  receiptsRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/receipts/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags receipts
   * @name ReceiptsPdfRetrieve
   * @request GET:/api/receipts/{id}/pdf/
   * @secure
   */
  receiptsPdfRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/receipts/${id}/pdf/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   *
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/api/schema/
   * @secure
   */
  schemaRetrieve = (query?: { format?: "json" | "yaml"; lang?: "en" | "is" }, params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/schema/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags shopping-list-items
   * @name ShoppingListItemsCreate
   * @request POST:/api/shopping-list-items/
   * @secure
   */
  shoppingListItemsCreate = (data: ShoppingListItemWriteRequest, params: RequestParams = {}) =>
    this.request<ShoppingListItemWrite, any>({
      path: `/api/shopping-list-items/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags shopping-list-items
   * @name ShoppingListItemsRetrieve
   * @request GET:/api/shopping-list-items/{id}/
   * @secure
   */
  shoppingListItemsRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<ShoppingListItem, any>({
      path: `/api/shopping-list-items/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags shopping-list-items
   * @name ShoppingListItemsUpdate
   * @request PUT:/api/shopping-list-items/{id}/
   * @secure
   */
  shoppingListItemsUpdate = (id: string, data: ShoppingListItemWriteRequest, params: RequestParams = {}) =>
    this.request<ShoppingListItemWrite, any>({
      path: `/api/shopping-list-items/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags shopping-list-items
   * @name ShoppingListItemsPartialUpdate
   * @request PATCH:/api/shopping-list-items/{id}/
   * @secure
   */
  shoppingListItemsPartialUpdate = (id: string, data: PatchedShoppingListItemRequest, params: RequestParams = {}) =>
    this.request<ShoppingListItem, any>({
      path: `/api/shopping-list-items/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags shopping-list-items
   * @name ShoppingListItemsDestroy
   * @request DELETE:/api/shopping-list-items/{id}/
   * @secure
   */
  shoppingListItemsDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/shopping-list-items/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsList
   * @request GET:/api/shopping-lists/
   * @secure
   */
  shoppingListsList = (
    query?: {
      company?: number;
      company__isnull?: boolean;
      dt_created__gte?: string;
      dt_created__lte?: string;
      dt_last_bought__gte?: string;
      dt_last_bought__lte?: string;
      dt_modified__gte?: string;
      dt_modified__lte?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ShoppingList[], any>({
      path: `/api/shopping-lists/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsCreate
   * @request POST:/api/shopping-lists/
   * @secure
   */
  shoppingListsCreate = (data: ShoppingListRequest, params: RequestParams = {}) =>
    this.request<ShoppingList, any>({
      path: `/api/shopping-lists/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsRetrieve
   * @request GET:/api/shopping-lists/{id}/
   * @secure
   */
  shoppingListsRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<ShoppingList, any>({
      path: `/api/shopping-lists/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsUpdate
   * @request PUT:/api/shopping-lists/{id}/
   * @secure
   */
  shoppingListsUpdate = (id: string, data: ShoppingListRequest, params: RequestParams = {}) =>
    this.request<ShoppingList, any>({
      path: `/api/shopping-lists/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsPartialUpdate
   * @request PATCH:/api/shopping-lists/{id}/
   * @secure
   */
  shoppingListsPartialUpdate = (id: string, data: PatchedShoppingListRequest, params: RequestParams = {}) =>
    this.request<ShoppingList, any>({
      path: `/api/shopping-lists/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsDestroy
   * @request DELETE:/api/shopping-lists/{id}/
   * @secure
   */
  shoppingListsDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/shopping-lists/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Handle ProductList items
   *
   * @tags shopping-lists
   * @name ShoppingListsCloneCreate
   * @request POST:/api/shopping-lists/{id}/clone/
   * @secure
   */
  shoppingListsCloneCreate = (id: string, data: CloneShoppingListRequest, params: RequestParams = {}) =>
    this.request<ShoppingList, any>({
      path: `/api/shopping-lists/${id}/clone/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersList
   * @request GET:/api/users/
   * @secure
   */
  usersList = (params: RequestParams = {}) =>
    this.request<User[], any>({
      path: `/api/users/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/users/{id}/
   * @secure
   */
  usersRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersUpdate
   * @request PUT:/api/users/{id}/
   * @secure
   */
  usersUpdate = (id: number, data: UserRequest, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/api/users/{id}/
   * @secure
   */
  usersPartialUpdate = (id: number, data: PatchedUserRequest, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersChangeEmailCreate
   * @request POST:/api/users/{id}/change-email/
   * @secure
   */
  usersChangeEmailCreate = (id: number, data: EmailChangeRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/${id}/change-email/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersRequestEmailChangeCreate
   * @request POST:/api/users/{id}/request-email-change/
   * @secure
   */
  usersRequestEmailChangeCreate = (id: number, data: RequestEmailChangeRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/users/${id}/request-email-change/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsRetrieve
   * @request GET:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsCreate
   * @request POST:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsCreate = (id: number, data: Record<string, any>, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsPartialUpdate
   * @request PATCH:/api/users/{id}/settings/
   * @secure
   */
  usersSettingsPartialUpdate = (id: number, data: Record<string, any>, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersLoginCreate
   * @request POST:/api/users/login/
   */
  usersLoginCreate = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/login/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersMeRetrieve
   * @request GET:/api/users/me/
   * @secure
   */
  usersMeRetrieve = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/me/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesList
   * @request GET:/api/warehouses/
   * @secure
   */
  warehousesList = (params: RequestParams = {}) =>
    this.request<Warehouse[], any>({
      path: `/api/warehouses/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesRetrieve
   * @request GET:/api/warehouses/{id}/
   * @secure
   */
  warehousesRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<Warehouse, any>({
      path: `/api/warehouses/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });

  
  /**
   * @description Locations by postal code
   *
   * @tags locations
   * @name DroppLocations
   * @request GET:/rest/dropp-locations/
   * @secure
   */
  fetchDroppLocations = (postCode: string, params: RequestParams = {}) =>
    this.request<any[], any>({
      path: `/rest/dropp-locations/?post_code=${postCode}`,
      method: "GET",
      secure: false,
      format: "json",
      ...params,
    });

  fetchDroppDeliveryLocations = (postCode: string, params: RequestParams = {}) =>
    this.request<any[], any>({
      path: `/rest/dropp-delivery-locations/?post_code=${postCode}`,
      method: "GET",
      secure: false,
      format: "json",
      ...params,
    });

  PostDroppOrder = (postCode: string, payload: any, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/rest/post-dropp-order/?post_code=${postCode}`,
      method: "POST",
      body: payload,
      secure: false,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
