import type {AppProps} from 'next/app'
import React, {useEffect, useMemo} from 'react'
import {ApolloProvider} from '@apollo/client'
import {ThemeProvider} from 'styled-components'
import {GridThemeProvider} from 'styled-bootstrap-grid'
import {ToastContainer, Slide} from 'react-toastify'
import 'cleave.js/dist/addons/cleave-phone.is'

import {useApollo} from 'src/api/saleor'
import {CheckoutProvider} from 'src/context/CheckoutContext'
import {OverlayProvider} from 'src/context/OverlayContext'
import {UserProvider} from 'src/context/UserContext'
import {ShoppingListProvider} from 'src/context/ShoppingListContext'
import theme from 'src/themes/theme'
import gridTheme from 'src/themes/gridTheme'
import {GlobalStyle, ResetStyle} from 'src/styles/globalStyles'
import 'src/styles/fontStyles.css'
import 'react-toastify/dist/ReactToastify.css'
import {RecoilRoot} from 'recoil'
import {PageID, useGetPageID} from 'src/hooks/useGetPageID'
import {AnimatePresence} from 'framer-motion'
import 'src/components/loader-for-pages/loader-for-pages.css'
import TagManager from 'react-gtm-module'
import {TagManagerWatcher} from 'src/components/tagmanager-watcher'
import type {QueryListenerOptions} from 'react-datocms'
import type {Webpage} from 'src/api/datocms/types/Webpage'
import {isSSR} from 'src/utils/isSSR'
import {DataContextProvider} from 'src/context/DataContext'
import {DATA_OBJECT_KEY} from 'src/utils/serverRender/serverPropsRender'
import {PageProvider} from 'src/context/PageContext'

const pageKey = 'pageSubscription'
type PageType = QueryListenerOptions<Webpage, never>

export const ChildComponent = ({
  Component,
  pageProps,
}: AppProps): JSX.Element => (
  <AnimatePresence>
    <Component {...pageProps} />
  </AnimatePresence>
)

export default function App({
  Component,
  pageProps,
  ...props
}: AppProps): JSX.Element {
  const client = useApollo(pageProps)
  const {pageID} = useGetPageID()

  const pageSubscription: PageType | null = useMemo(() => {
    if (pageProps && pageKey in pageProps) {
      if (!isSSR) {
        window.pageSubscription = pageProps[pageKey]
      }
      return pageProps[pageKey] as PageType
    }
    if (!isSSR) {
      return window.pageSubscription ?? null
    }
    return null
  }, [pageProps])

  const googleid =
    process.env.NODE_ENV !== 'development'
      ? process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER ||
        process.env.NEXT_PUBLIC_GTAG_ID
      : null

  useEffect(() => {
    if (typeof window !== 'undefined' && googleid) {
      TagManager.initialize({
        gtmId: googleid,
      })
    }
  }, [googleid])

  const data = (pageProps ? pageProps[DATA_OBJECT_KEY] : {}) as Record<
    string,
    unknown
  >
  if (!pageSubscription) {
    return <div>Error</div>
  }
  return (
    <RecoilRoot>
      <DataContextProvider value={data}>
        <>
          <PageID pageID={pageID} />
          <ApolloProvider client={client}>
            <OverlayProvider>
              <TagManagerWatcher />
              <ThemeProvider theme={theme}>
                <GridThemeProvider gridTheme={gridTheme}>
                  <UserProvider>
                    <PageProvider subscription={pageSubscription}>
                      <ToastContainer position="top-right" transition={Slide} />
                      <CheckoutProvider>
                        <ShoppingListProvider>
                          <>
                            <ResetStyle />
                            <GlobalStyle />
                            <ChildComponent
                              Component={Component}
                              pageProps={pageProps}
                              {...props}
                            />
                          </>
                        </ShoppingListProvider>
                      </CheckoutProvider>
                    </PageProvider>
                  </UserProvider>
                </GridThemeProvider>
              </ThemeProvider>
            </OverlayProvider>
          </ApolloProvider>
        </>
      </DataContextProvider>
    </RecoilRoot>
  )
}
