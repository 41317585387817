export function fromGlobalId(
  val: string,
): {
  model: string
  id: string
} {
  const [model, id] = atob(val).split(':')
  return {model, id}
}

export function intFromGlobalId(
  val: string,
): {
  model: string
  id: number
} {
  const {model, id} = fromGlobalId(val)
  return {model, id: parseInt(id, 10)}
}

export function toGlobalId(model: string, id: number | string): string {
  const label = `${model}:${id}`
  if (typeof window === 'undefined' || typeof btoa === 'undefined') {
    return Buffer.from(label).toString('base64')
  }

  return btoa(label)
}
