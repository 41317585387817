import React, {useMemo} from 'react'
import _uniqueId from 'lodash/uniqueId'

/**
 * This creates an unique ID for React component.
 * React 18 and above already has a built-in solution for this.
 */
export const useId = (): string => {
  if ('useId' in React) {
    return ((React as unknown) as {useId: () => string}).useId() as string
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => _uniqueId('component'), [])
}
