import React from 'react'
import styled from 'styled-components'
import {ifProp, prop} from 'styled-tools'
import ShoppingCartLineIcon from 'remixicon-react/ShoppingCartLineIcon'

import Loader from '../common/Loader'

import {buttonTextStyles, cartButtonStyles} from './buttonStyles'
import {theme} from '../../themes'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  withGutter?: boolean
  color?: 'primary' | 'secondary' | 'black' | 'white' | 'red' | 'black40'
}

interface ComponentProps extends Props {
  fill?: boolean | number
  showIcon?: boolean
}

interface BtnProps extends Props {
  fill: 'true' | 'false'
}

interface TextProps {
  withIcon?: boolean
}

interface BtnWrapperProps {
  withGutter: boolean
}

const Btn = styled.button<BtnProps>`
  ${(p) => cartButtonStyles({color: p.color})};
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

export const BtnWrapper = styled.div<BtnWrapperProps>`
  padding-bottom: ${ifProp({withGutter: true}, '24px', '0px')};
`

interface IconProps {
  color: string
}

const IconWrapper = styled.div<IconProps>`
  position: relative;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${prop('color')};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -5px;
    width: 3px;
    background-color: ${prop('color')};
  }
`

export default function CartButton({
  children,
  loading = false,
  showIcon = true,
  disabled,
  withGutter = false,
  fill = false,
  color = 'red',
  ...props
}: ComponentProps): JSX.Element {
  return (
    <BtnWrapper withGutter={withGutter}>
      <Btn
        disabled={disabled}
        loading={loading ? true : undefined}
        fill={fill ? 'true' : 'false'}
        color={color}
        {...props}
      >
        {showIcon && (
          <IconWrapper color={theme.palette[color]}>
            {loading ? <Loader /> : <ShoppingCartLineIcon />}
          </IconWrapper>
        )}
        <BtnText withIcon={false}>
          {loading ? 'Augnablik ...' : children}
        </BtnText>
      </Btn>
    </BtnWrapper>
  )
}
