import {AttributeValue} from 'src/api/saleor/generated'
import {isNotNull} from 'src/utils/isNotNull'
import {EcoCert} from '..'
import {ProductContext} from '../utils'

const ATTRIBUTE_SLUG = 'certifications-eco'

const IMAGES: Record<string, string> = {
  'begreen-pilot': '/assets/BegreeNPilot.jpg',
  'blái-engillinn': '/assets/blueangel.png',
  bioware: '/assets/Bioware.png',
  'bra-miljöval': '/assets/BraMiljoval.jpg',
  compostable: '/assets/Compostable.gif',
  'eco-easy': '/assets/EcoEasy.jpg',
  ecologo: '/assets/Ecologo.jpg',
  fsc: '/assets/FSC.jpg',
  evrópublómið: '/assets/eco-label.svg',
  pefc: '/assets/PEFC.jpg',
  seedling: '/assets/seedling.jpg',
  svanurinn: '/assets/svansmerki.svg',
  'nf-environment': '/assets/NFEnviroment.jpg',
  'verndum-regnskóga': '/assets/verndumregnskoga.png',
}

export const resolveEcoCerts = (context: ProductContext): EcoCert[] => {
  const {attributes} = context.product
  if (!attributes || attributes.length === 0) {
    return []
  }
  if (!attributes || attributes.length === 0) {
    return []
  }
  return attributes
    .filter((item) => item.attribute.slug === ATTRIBUTE_SLUG)
    .map((item) =>
      item.values
        .filter((value): value is AttributeValue => value != null)
        .map(({name, slug}) => ({
          id: `${context.product.slug}:ecoCert:${slug}`,
          name: name ?? '',
          slug: slug ?? '',
          thumbnail:
            slug && IMAGES[slug]
              ? {
                  id: `${context.product.slug}:ecoCert:thumbnail:${slug}`,
                  url: IMAGES[slug],
                }
              : null,
        }))
        .filter(
          (value): value is EcoCert => value.name != null && value.slug != null,
        ),
    )
    .filter(isNotNull)
    .flat()
    .sort(
      (a, b) => (a.thumbnail == null ? 1 : 0) - (b.thumbnail == null ? 1 : 0),
    )
}
