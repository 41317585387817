import type {SetterOrUpdater} from 'recoil'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import type {CheckoutFragment} from 'src/api/saleor/generated'
import type {Maybe} from 'src/utils/Maybe'
import {Store} from './atom'

export const useCheckoutValue = (): Maybe<CheckoutFragment> =>
  useRecoilValue(Store.checkout)

export const useSetCheckoutValue = (): SetterOrUpdater<
  Maybe<CheckoutFragment>
> => useSetRecoilState(Store.checkout)
