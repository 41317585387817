import React from 'react'
import styled from 'styled-components'
import {tint} from 'polished'
import {ifProp, palette} from 'styled-tools'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'

import {gridTheme, theme} from '../../themes'

export enum AlertEnum {
  'error',
  'warning',
  'success',
}

interface AlertProps {
  open: boolean
  type?: 'error' | 'warning' | 'success'
}

const Wrapper = styled.div<AlertProps>`
  display: ${ifProp({open: true}, 'block', 'none')};
  color: ${(p) => palette(p.type as string) ?? 'inherit'};
  border: 1px solid ${(p) => palette(p.type as string) ?? 'inherit'};
  background-color: ${(p) =>
    p.type && theme.palette[p.type]
      ? tint(0.9, theme.palette[p.type])
      : 'inherit'};
  position: relative;
`

const AlertContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 25px;
  @media (max-width: ${gridTheme.breakpoints.sm}px) {
    padding: 5px 10px;
  }
`

const AlertContent = styled.div`
  display: flex;
  align-items: center;
`

const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
  width: 30px;
  height: 30px;
`

interface Props extends AlertProps {
  children: React.ReactNode
  setOpen?: (value: boolean) => void
}

export default function Alert({
  open,
  type = 'success',
  children,
  setOpen,
}: Props): JSX.Element {
  return (
    <Wrapper open={open} type={type}>
      <AlertContainer>
        <AlertContent>{children}</AlertContent>

        <CloseButton onClick={() => !!setOpen && setOpen(false)}>
          <CloseLineIcon />
        </CloseButton>
      </AlertContainer>
    </Wrapper>
  )
}
